import { Box, Button, Typography } from '@mui/material';
import styled from 'styled-components';
import { AlignCenterBox } from '../../components/shared/FlexBox';
import { LightOrange, Maroon, MainRed, WhiteSmoke } from '../../Theme/colorsVariables';
import { breakPointSmall } from '../../utils';

export const StyledBox = styled(AlignCenterBox)`
  flex-direction: column;
  background: ${WhiteSmoke};
  padding: 35px 60px;
  max-width: 500px;
  width: 100%;
  position: absolute;
  right: 150px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  box-shadow: 0px 0px 20px #00000029;
  box-sizing: border-box;
  ${breakPointSmall(`
    position: relative;
    margin: 0 auto;
    top: 0;
    right: 0;
    transform:none;
    `)}
`;

export const ButtonS = styled(Button)`
  color: ${LightOrange};
  font-size: 18px;
  text-transform: capitalize;
  font-weight: bold;
`;

export const StyledRegisterButton = styled(Button)`
  width: 100%;
  background-color: ${LightOrange};
  background: ${LightOrange};
  margin-top: 20px;
  font-size: 24px;
  font-family: Gotham-Book;
  text-transform: capitalize;
  border-radius: 12px;
`;

export const SignUpText = styled(Box)`
  font-size: 36px;
  color: ${MainRed};
  font-family: Gotham-Bold;
`;

export const ErrorMessageText = styled.p`
  color: ${Maroon};
  font-size: 0.7rem;
  margin: 0 !important;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const TextFieldContainer = styled.div`
  margin-top: 24px;
  display: grid;
`;

export const StyledText = styled(Typography)`
  font-family: Gotham;
  font-size: 20px;
  margin-top: 18px;
`;
