import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalMessageText } from '../CancelModal/styles';
import { ModalButtonsBox } from '../Modal/styles';

export default function DeleteEntityModal({
  entityType,
  entityName,
  onConfirm,
  open,
  setOpen,
  userId,
  isContactAssociatedWithAgreements,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  entityName: string;
  entityType: string;
  onConfirm: () => void;
  userId?: number;
  isContactAssociatedWithAgreements?: boolean;
}) {
  const { t } = useTranslation();

  const modalMessage = useMemo(() => {
    if (entityType === 'Agent Contact' && (isContactAssociatedWithAgreements || !!userId)) {
      return t('agent.contact.delete.message', { entityName, userId });
    }
    return t('security.users.userList.msgDelete', { entityName, entityType });
  }, [entityName, entityType, isContactAssociatedWithAgreements, t, userId]);

  return (
    <CustomModal
      open={open}
      containerWidth="400px"
      modalTitle={t('action.delete') + ` ${entityType}`}
    >
      <>
        <ModalMessageText
          dangerouslySetInnerHTML={{
            __html: modalMessage,
          }}
        />
        <ModalButtonsBox>
          <AppActionButton style={{ marginRight: '15px' }} variant="outlined" onClick={onConfirm}>
            {t('action.yes')}
          </AppActionButton>
          <AppActionButton variant="outlined" onClick={() => setOpen(false)}>
            {t('action.no')}
          </AppActionButton>
        </ModalButtonsBox>
      </>
    </CustomModal>
  );
}
