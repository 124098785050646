import { Box } from '@mui/material';
import styled from 'styled-components';
import { FlexBox } from '../../shared/FlexBox';
import { breakPointMedium } from '../../../utils';

export const ProductLineText = styled('p')`
  margin: 8px 10px 8px 0;
  font-family: Gotham-MonoSpace-Numbers, sans-serif;
  font-size: 16px;
  ${breakPointMedium('font-size: 12px;')}
`;

export const ProductLinesAbsoluteBox = styled(Box)({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});

export const ProductLinesScrollableBox = styled(Box)({
  background: 'white',
  height: '100%',
  overflow: 'auto',
  width: '100%',
  padding: '0 10px',
});

export const AssociatedProductLinesContainer = styled(Box)({
  flexGrow: 1,
  height: '100%',
  maxHeight: '50%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const AgreementsAbsoluteContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
});

export const FullHeightContainer = styled(FlexBox)({
  flexFlow: 'column',
  height: '100%',
  maxHeight: '100%',
  flexGrow: '1',
});
