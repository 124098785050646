import styled from 'styled-components';
import { List, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import {
  LightSlateGray,
  MainColor,
  MainTextColor,
  Platinum,
  MainRed,
  SecondaryTextColor,
  White,
} from '../../Theme/colorsVariables';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import { breakPointMedium } from '../../utils';

export const Items = styled(ListItemButton)<{ active: string; level: number }>`
  font-family: 'Gotham-Medium';
  position: relative;

  background: ${({ active, level }) => (active && level === 0 ? `${MainColor}` : '')};
  color: ${SecondaryTextColor};

  div svg {
    fill: ${LightSlateGray};
  }
  ::after {
    posistion: absolute;
    width: 100%;
    height: 4px;
    background: red;
  }
`;

export const Icon = styled(ListItemIcon)`
  color: ${LightSlateGray};
  fill: ${LightSlateGray};
  & svg {
    color: ${LightSlateGray};
    fill: ${LightSlateGray};
    width: 32px !important;
    height: 32px !important;
  }
`;

export const Text = styled(Typography)`
  font-family: Gotham-Medium;
  font-size: 18px;
`;

export const NavItems = styled(ListItemButton)<{
  active: string;
  section: string;
  current: string;
}>`
  background: ${(props) => (props.active === props.current ? Platinum : 'none')};
  font-family: 'Gotham-Medium';

  color: ${MainTextColor};

  div svg {
    fill: ${LightSlateGray};
  }

  overflow: hidden;

  .MenuItemText {
    position: relative;
    font-size: 18px;
    ${breakPointMedium(` font-size: 12px;`)}
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    bottom: 0px;
    left: 0px;
    width: 4px;
    z-index: 1;
    background: ${MainRed};
    display: ${(props) => (props.active === props.current ? 'block' : 'none')};
  }
`;

export const SideItemsList = styled(List)`
  background: #f1f1f1;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const ExpandIcon = styled(UnfoldMoreOutlinedIcon)({
  fill: MainRed,
  borderRadius: '50%',
  transform: 'rotate(90deg)',
  background: White,
});
