import axios from 'axios';
import { useQuery } from 'react-query';
import { AccountOwner, ApiError } from '../types';

export const AccountOwnerService = {
  useAll: () => {
    const req = async () => {
      const res = await axios.get('/account-owner');
      return res.data;
    };
    return useQuery<AccountOwner[], ApiError>('allAccountOwners', req, { staleTime: Infinity });
  },
};
