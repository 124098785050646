import { Box } from '@mui/material';
import styled from 'styled-components';
import { AlignCenterBox } from '../../../shared/FlexBox';
import { SeperatorLine } from '../../../../Theme/colorsVariables';
import { AppActionButton } from '../../../shared/AppActionButton';

export const ProductGrid = styled(Box)`
  border-right: 2px solid ${SeperatorLine};
`;

export const Seperator = styled(Box)`
  margin: 0 8px;
  height: 20px;
  display: inline;
  border-right: 2px solid ${SeperatorLine};
`;

export const AddContactBtn = styled(AppActionButton)`
  height: 44px;
`;

export const ButtonWrapper = styled(AlignCenterBox)`
  margin-top: 5px;
`;

export const ProductLinesBoxScrollable = styled(Box)({
  // position: 'absolute',
  // top: 0,
  // left: 0,
  height: '100%',
  width: '100%',
  overflowY: 'auto',
});
