import { useQuery } from '../../hooks/useQuery';
import CustomModal from '../../components/modal/Modal';
import { ModalMessageText } from '../../components/modal/CancelModal/styles';
import { ModalBox } from '../../components/modal/Modal/styles';
import { useDocuSignEvents } from '../../hooks/useDocuSignEvents';
import { AppActionButton } from '../../components/shared/AppActionButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import AgreementTemplateService from '../../services/documentService';

const DocusignRedirectPage = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const type = useQuery('type');

  const agreementType = useQuery('agreementType');
  const providerName = useQuery('providerName');
  const lookupId = useQuery('lookupId');
  const templateId = useQuery('templateId');
  const productId = useQuery('productId');
  const agreementId = useQuery('agreementId');

  const { eventDescription, eventName } = useDocuSignEvents();
  const { mutate: updateTemplateDetails } = AgreementTemplateService.useUpdateTemplateDetails();

  useEffect(() => {
    if (productId === 'true' && templateId) {
      updateTemplateDetails(templateId);
    }
  }, [productId, templateId, updateTemplateDetails]);

  return (
    <>
      <ModalBox>
        <CustomModal open={isOpen} containerWidth="600px" modalTitle={'Info'}>
          <>
            <ModalMessageText
              dangerouslySetInnerHTML={{
                __html:
                  eventName === 'signing_complete' && type === 'signer'
                    ? `Thank you for signing the <a href="${window.location.origin}/public/document-preview?lookup=${lookupId}&agreementId=${agreementId}&agreementType=${agreementType}&providerName=${providerName}">${agreementType}</a>. The agreement has been forwarded to an officer of ${providerName} to be signed. Once the agreement is fully executed, we will forward you a final, executed copy. We have also sent you an email confirmation.`
                    : `${eventDescription}${
                        type !== 'signer'
                          ? '</br> This tab will be closed automatically in few seconds.'
                          : ''
                      }`,
              }}
            />

            <Box mt={1}>
              <AppActionButton
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('action.ok')}
              </AppActionButton>
            </Box>
          </>
        </CustomModal>
      </ModalBox>
    </>
  );
};

export default DocusignRedirectPage;
