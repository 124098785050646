import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { FlexBox } from '../../components/shared/FlexBox';
import { User, UserPayload } from '../../types';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { UserForm } from '../../components/Forms/UserForm';
import { StyledTypography } from '../../components/Forms/RoleForm/noPermissionsText';
import { Grid2 } from '@mui/material';
import UserService from '../../services/userService';
import { useToast } from '../../hooks/useToast';
import { useLocation, useNavigate } from 'react-router-dom';
import { StickyContainer } from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { useDataloss } from '../../hooks/useDataloss';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';
import { useQueryClient } from 'react-query';

export const AddUser = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const location = useLocation();
  const toast = useToast();
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const defaultValues = useMemo(() => {
    return location.state ? (location.state as { payload: User; returnUrl: string }) : undefined;
  }, [location.state]);

  const navigate = useNavigate();

  const isCreateProviderUserPermission = usePermissions(PermissionKeys.CreateProviderUser);
  const isCreateAgentUserPermission = usePermissions(PermissionKeys.CreateAgentUser);

  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [cancelPopup, setCancelPopUp] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const { setQuickModal } = useContext(QuickConfirmationModalContext);
  const { mutate: addUser } = UserService.useUserAddQuery({
    onSuccess: ({ message, data }) => {
      queryClient.removeQueries({ queryKey: ['users'] });
      toast.success(message);
      if (!navigateAfterDatalossDecision()) {
        if (defaultValues?.returnUrl) {
          navigate(defaultValues.returnUrl);
          return;
        }
        navigate(`/dashboard/User/${data.userId}`);
      }
    },
    onError(error) {
      if (error.response.data.code === 40014) {
        setQuickModal({
          message: error.response.data.message,
          modalTitle: 'Duplicates!',
          isOpen: true,
        });
      } else {
        toast.error(error.response.data.message);
      }
    },
  });

  const handleFormSubmit = (data: UserPayload) => {
    addUser(data);
  };

  const onDiscardChanges = useCallback(() => {
    setCancelPopUp(false);

    if (defaultValues?.returnUrl) {
      navigate(defaultValues.returnUrl);
      return;
    }
    navigate('/dashboard/User');
  }, [defaultValues, navigate]);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, []);

  useDataloss(isUnsavedData, onDiscardChanges, onSaveChanges, onExit);

  if (!isCreateProviderUserPermission && !isCreateAgentUserPermission) {
    return (
      <Grid2 container>
        <Grid2 size={{ xs: 12 }}>
          <StyledTypography mt={10}>{t('restrictions.noPermissions')}</StyledTypography>
        </Grid2>
      </Grid2>
    );
  }

  return (
    <>
      <StickyContainer>
        <PageHeaderContainer>
          <PageHeaderContentHost
            name={
              defaultValues
                ? `Create User for Contact for ${defaultValues.payload.legalName}`
                : 'Add User'
            }
            showRequiredLabel={true}
          />

          <FlexBox>
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setCancelPopUp(true);
                } else {
                  if (defaultValues?.returnUrl) {
                    navigate(defaultValues.returnUrl);
                    return;
                  }
                  navigate('/dashboard/User');
                }
              }}
            />
            <AppResetButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('reset', { cancelable: true, bubbles: true }),
                );
              }}
            />
            <AppSaveButton
              disabled={isSaveEnabled}
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }}
            />
          </FlexBox>
        </PageHeaderContainer>
      </StickyContainer>
      <UserForm
        isEdit={true}
        setIsEdit={() => {}}
        onSubmit={handleFormSubmit}
        formRef={formRef}
        isSaveEnabled={isSaveEnabled}
        setIsSaveEnabled={setIsSaveEnabled}
        isUnsavedData={isUnsavedData}
        setIsUnsavedData={setIsUnsavedData}
        userStatus={true}
        setUserStatus={() => {}}
        cancelPopup={cancelPopup}
        setCancelPopup={setCancelPopUp}
        defaultValues={defaultValues?.payload}
      />
    </>
  );
};
