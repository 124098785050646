import { useNavigate } from 'react-router-dom';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { useEffect } from 'react';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { ConfigurationLink } from './styles';

const configurationPages = [
  { name: 'Average Total Inventory', url: '/dashboard/Configuration/AverageTotalInventory' },
  { name: 'Dealer Group Types and Asset Makes', url: '/dashboard/Configuration/DealerGroupType' },
  { name: 'Dealer Management Systems', url: '/dashboard/Configuration/DMSProvider' },
  { name: 'F&I Menu', url: '/dashboard/Configuration/FIMenu' },
  { name: 'Legal Organization', url: '/dashboard/Configuration/LegalOrganization' },
  { name: 'Role Type Role', url: '/dashboard/Configuration/RoleTypeRole' },
  { name: 'Terms Of Use', url: '/dashboard/Configuration/TermsOfUse' },
  { name: 'Tenant Setup', url: '/dashboard/Configuration/TenantSetup' },
];

export default function ConfigurationPages() {
  const { t } = useTranslation();
  const isEnabled = usePermissions(PermissionKeys.ReadConfiguration);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.noPermissions') } });
    }
  }, [isEnabled, navigate, t]);
  return (
    <>
      <PageHeaderContentHost name="Configuration Pages" />
      {configurationPages.map((page) => (
        <p key={page.url}>
          <ConfigurationLink to={page.url}>{page.name}</ConfigurationLink>
        </p>
      ))}
    </>
  );
}
