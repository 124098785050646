import { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import { CreateOrganizationForm } from '../../Forms/CreateOrganizationForm';
import { OrganizationFormType } from '../../Forms/CreateOrganizationForm/types';
import { CancelModal } from '../../modal/CancelModal';
import { AppCancelButton } from '../../shared/AppCancelButton';
import { AppEditButton } from '../../shared/AppEditButton';
import { AppResetButton } from '../../shared/AppResetButton';
import { AppSaveButton } from '../../shared/AppSaveButton';
import { AppWarningParagraph } from '../../shared/AppWarningParagraph';
import { FlexBox, JustifyContentRightBox, SpaceBetweenBox } from '../../shared/FlexBox';
import { useDataloss } from '../../../hooks/useDataloss';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { useToast } from '../../../hooks/useToast';
import { useCheckOrgHasInProgressAgreements } from '../../../services/organization';
import { UpdateSellerGroupQuery } from '../../../services/sellerGroupServices';
import { OrganizationType } from '../../../types/query-params';
import {
  UpdateSellerGroupPayload,
  UpdateSellerGroupRequestPayload,
  CommonOrganization,
} from '../../../types/sellerGroup';
import { getPhonePayload } from '../../shared/AppPhoneField';
import { useNavigateAfterDatalossDecision } from '../../../hooks/useNavigateAfterDatalossDecision';
import { patchFormatter } from '../../../utils/organizationPatchFormatter';
import { Box } from '@mui/material';
import LabelRequired from '../../shared/LabelRequired';
import { useQueryClient } from 'react-query';

export const SellerGroupInfoTab = () => {
  const {
    sellerGroupInformation: sellerGroupData,
    refetchSellerGroupData: refetchSellerGroup,
    isCompletedAgreement,
  } = useOutletContext<{
    sellerGroupInformation?: CommonOrganization;
    refetchSellerGroupData: () => void;
    isCompletedAgreement: boolean;
  }>();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();
  const isUpdateOrganizationPermission = usePermissions(PermissionKeys.UpdateOrganization);

  const { data: isAgreementInProcess } = useCheckOrgHasInProgressAgreements(Number(id));

  const formRef = useRef<HTMLFormElement>(null);

  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [customFieldError, setCustomFieldError] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const [cancelPopUp, setCancelPopUp] = useState(false);
  const { mutateAsync: updateSellerGroup } = UpdateSellerGroupQuery({
    setCustomFieldError,
    mutationOptions: {
      onSuccess(data, variables) {
        queryClient.removeQueries({ queryKey: ['sellerGroups'] });

        toast.success(t('sellerGroup.update.success', { name: variables.payload.legalName }));
        if (!navigateAfterDatalossDecision()) {
          setIsEdit(false);
        }
        refetchSellerGroup();
      },
    },
  });

  const onFormSubmit = async (formValues: OrganizationFormType) => {
    const originalData: Partial<UpdateSellerGroupPayload> = {
      legalName: sellerGroupData?.legalName,
      legalId: sellerGroupData?.fein?.replaceAll('-', ''),
      legalEntityTypeId: sellerGroupData?.legalEntityTypeId,
      organizationType: 4,
      doingBusinessAs: sellerGroupData?.doingBusinessAs,
      addresses: [
        {
          addressId: sellerGroupData?.addresses[0].addressId,
          addressType: sellerGroupData?.addresses[0].addressTypeId,
          streetAddressLine1: sellerGroupData?.addresses[0].streetAddressLine1,
          streetAddressLine2: sellerGroupData?.addresses[0].streetAddressLine2,
          city: sellerGroupData?.addresses[0].city,
          stateId: sellerGroupData?.addresses[0].stateId,
          zipCode: sellerGroupData?.addresses[0].zipCode,
          sameAsAddressTypeId: null,
        },
        {
          addressId: sellerGroupData?.addresses[1].addressId,
          addressType: sellerGroupData?.addresses[1].addressTypeId,
          streetAddressLine1: sellerGroupData?.addresses[1].streetAddressLine1,
          streetAddressLine2: sellerGroupData?.addresses[1].streetAddressLine2,
          city: sellerGroupData?.addresses[1].city,
          stateId: sellerGroupData?.addresses[1].stateId,
          zipCode: sellerGroupData?.addresses[1].zipCode,
          sameAsAddressTypeId: sellerGroupData?.addresses[1].sameAsAddressTypeId,
        },
      ],
      phoneNumber: sellerGroupData?.addresses[0]?.phoneNumber || null,
      phoneNumberExt: sellerGroupData?.addresses[0]?.phoneNumberExt || null,
      phoneNumberCountryCode: sellerGroupData?.addresses[0]?.phoneNumberCountryCode || null,
      websiteURL: sellerGroupData?.addresses[0].website,
      fax: sellerGroupData?.addresses[0].mainFax?.replaceAll('-', ''),
    };

    const updateSellerGroupPayload: Partial<UpdateSellerGroupPayload> = {
      legalName: formValues.legalName,
      legalId: formValues.fein?.replaceAll('-', ''),
      legalEntityTypeId: formValues.legalOrganization?.value,
      organizationType: 4,
      doingBusinessAs: formValues.doingBusinessAs || null,
      addresses: [
        {
          addressId: formValues.physicalLocation.addressId,
          addressType: 1,
          streetAddressLine1: formValues.physicalLocation.address1,
          streetAddressLine2: formValues.physicalLocation.address2 || null,
          city: formValues.physicalLocation.city,
          country: 'US',
          stateId: formValues.physicalLocation.state?.value?.addressStateId,
          zipCode: formValues.physicalLocation.zip,
          sameAsAddressTypeId: null,
        },
        {
          addressId: formValues.mailingLocation.addressId,
          addressType: 2,
          streetAddressLine1: formValues.mailingLocation.address1,
          streetAddressLine2: formValues.mailingLocation.address2 || null,
          city: formValues.mailingLocation.city,
          country: 'US',
          stateId: formValues.mailingLocation.state?.value?.addressStateId,
          zipCode: formValues.mailingLocation.zip,
          sameAsAddressTypeId: formValues.isLocationsTheSame ? 1 : 0,
        },
      ],
      ...getPhonePayload(formValues.phone),
      websiteURL: formValues.website || null,
      fax: formValues.fax?.replaceAll('-', ''),
    };

    const updatePayload: UpdateSellerGroupRequestPayload = {
      id: sellerGroupData?.sellerGroupId || 0,
      payload: patchFormatter(
        originalData,
        updateSellerGroupPayload,
      ) as Partial<UpdateSellerGroupPayload>,
    };

    updateSellerGroup(updatePayload);
  };

  const onDiscardChanges = useCallback(() => {
    formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
    setIsEdit(false);
    setCancelPopUp(false);
  }, []);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, [setCancelPopUp]);

  useDataloss(isUnsavedData && isEdit, onDiscardChanges, onSaveChanges, onExit);

  return (
    <>
      {!isEdit && (isAgreementInProcess || isCompletedAgreement) && (
        <JustifyContentRightBox mb={1}>
          {isAgreementInProcess && (
            <FlexBox>
              <AppWarningParagraph $align={'left'}>
                <i>{t('productLine.inProgress.warning')}</i>
              </AppWarningParagraph>
            </FlexBox>
          )}
          <AppEditButton
            disabled={!isUpdateOrganizationPermission || isAgreementInProcess}
            onClick={() => {
              setIsEdit(true);
            }}
          />
        </JustifyContentRightBox>
      )}

      {isEdit && (
        <SpaceBetweenBox>
          <Box>
            <LabelRequired />
          </Box>
          <JustifyContentRightBox mb={1}>
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setCancelPopUp(true);
                } else {
                  setIsEdit(false);
                }
              }}
            />
            <AppResetButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('reset', { cancelable: true, bubbles: true }),
                );
              }}
            />
            <AppSaveButton
              disabled={isFormValid ? !isUnsavedData === isFormValid : true}
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }}
            />
          </JustifyContentRightBox>
        </SpaceBetweenBox>
      )}

      <CreateOrganizationForm
        editForm={isEdit}
        orgData={sellerGroupData}
        formRef={formRef}
        onSubmit={onFormSubmit}
        setIsUnsavedChanges={setIsUnsavedData}
        setIsFormValid={setIsFormValid}
        customFieldError={customFieldError}
        setCustomFieldError={setCustomFieldError}
        orgType={OrganizationType.SellerGroup}
      />
      <CancelModal
        isOpened={cancelPopUp}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardChanges}
        onSaveChanges={onSaveChanges}
        onExit={onExit}
      />
    </>
  );
};
