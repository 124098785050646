import React, { useContext } from 'react';
import { AppBar, Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  StyledToolBar,
  AgentInfoContainer,
  AgentInfoContainerSmall,
  StyledToolBarSmall,
} from './styles';
import LogoIcon from '../../components/assets/LogoIcon';
import HelpMenu from '../../components/helpMenu';
import UserMenus from '../Sidebar/UserMenu';
import AppSearch from '../../components/AppSearch';
import { AlignCenterBox, CenterCenterBox } from '../../components/shared/FlexBox';
import QuickMenu from '../../components/quickMenu';
import HomeIcon from '../../components/assets/Home';
import { UnsavedDataContext } from '../../context/UnsavedData';
import { UserContext } from '../../context/User';
import AccountOwner from '../../components/AccountOwner';

export default function TopBar() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const user = useContext(UserContext);
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);

  const handleLogoClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (unsavedData.isUnsavedChanges) {
      event.preventDefault();
      setUnsavedData({ ...unsavedData, isPopupOpen: true, returnUrl: '/dashboard' });
    }
  };

  return (
    <AppBar position="static" sx={{ boxShadow: 'none' }}>
      {!isSmall ? (
        <StyledToolBar>
          <Box flexGrow={1} maxWidth={'220px'}>
            <Link
              style={{ textDecoration: 'none' }}
              to="/dashboard"
              onClick={(e) => handleLogoClick(e)}
            >
              <AlignCenterBox>
                <AlignCenterBox style={{ marginRight: '10px' }}>
                  <HomeIcon />
                </AlignCenterBox>
                <AlignCenterBox>
                  <LogoIcon />
                </AlignCenterBox>
              </AlignCenterBox>
            </Link>
          </Box>
          {user?.userType === 'Agency' && (
            <AgentInfoContainer flexGrow={3}>
              <Box>
                <b>Agent:</b> {user?.legalName || '-'}
              </Box>
            </AgentInfoContainer>
          )}
          <CenterCenterBox sx={{ width: 'unset !important', flexGrow: 3 }}>
            <AppSearch />
            <QuickMenu />
          </CenterCenterBox>
          {user?.userType === 'Agency' && <AccountOwner />}
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            flexGrow={1}
            maxWidth={'100px'}
          >
            <HelpMenu />
            <UserMenus />
          </Stack>
        </StyledToolBar>
      ) : (
        <StyledToolBarSmall>
          <CenterCenterBox>
            <Link
              style={{ textDecoration: 'none' }}
              to="/dashboard"
              onClick={(e) => handleLogoClick(e)}
            >
              <AlignCenterBox>
                <AlignCenterBox style={{ marginRight: '10px' }}>
                  <HomeIcon />
                </AlignCenterBox>
                <AlignCenterBox>
                  <LogoIcon />
                </AlignCenterBox>
              </AlignCenterBox>
            </Link>
            {user?.userType === 'Agency' && (
              <AgentInfoContainerSmall>
                <Box>
                  <b>Agent:</b> {user?.legalName || '-'}
                </Box>
              </AgentInfoContainerSmall>
            )}

            {user?.userType === 'Agency' && <AccountOwner />}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" maxWidth={'100px'}>
              <HelpMenu />
              <UserMenus />
            </Stack>
          </CenterCenterBox>
          <CenterCenterBox>
            <AppSearch />
            <QuickMenu />
          </CenterCenterBox>
        </StyledToolBarSmall>
      )}
    </AppBar>
  );
}
