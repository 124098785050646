import React, { useCallback } from 'react';
import { AutocompleteChangeReason } from '@mui/material';
import { StyledList, TypographySpan, StyledAdvanced } from './styles';
import { MultiSelect } from './interface';
import { useTranslation } from 'react-i18next';
import { Platinum, White } from '../../../Theme/colorsVariables';
import { AppTextField } from '../AppTextField';
import { AppAutocomplete } from '../AppAutocomplete';
import AppListItem from '../AppListItem';

const StatesMultiSelect = <T extends { label: string; value: string }>({
  disabled,
  originalData,
  selectedData,
  handleChangeSelected,
  checked,
  label,
  required,
  errorMessage,
}: MultiSelect<T>) => {
  const { t } = useTranslation();
  label = label === undefined ? t('formField.oems') : label;
  const handleAvailableChange = useCallback(
    (data: T[], reason: AutocompleteChangeReason) => {
      if (reason === 'selectOption' || reason === 'removeOption') {
        handleChangeSelected(data);
      } else if (reason === 'clear') {
        handleChangeSelected([]);
      }
    },
    [handleChangeSelected],
  );
  return (
    <>
      {originalData && (
        <AppAutocomplete
          data-cy={`select-${label}`}
          ListboxComponent={checked ? StyledAdvanced : StyledList}
          disabled={disabled}
          multiple
          disablePortal
          options={originalData}
          value={selectedData}
          disableCloseOnSelect={false}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderTags={(data) => (
            <TypographySpan style={{ background: !disabled ? `${White}` : `${Platinum}` }}>
              {t('multiple.select.label', {
                selectedCount: data.length,
                allCount: originalData.length,
              })}
            </TypographySpan>
          )}
          renderInput={(params) => (
            <AppTextField
              {...params}
              sx={{ background: !disabled ? `${White}` : `${Platinum}`, borderRadius: '14px' }}
              variant="outlined"
              label={label}
              InputLabelProps={{ required: required }}
              errorMessage={selectedData.length === 0 ? errorMessage : ''}
            />
          )}
          renderOption={(props, option) => {
            if (option.label) {
              return (
                <AppListItem
                  {...props}
                  key={JSON.stringify(option)}
                  data-cy={`select-option-${option.label}`}
                  data-cy-option={'select-option'}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: option.label,
                    }}
                  ></span>
                </AppListItem>
              );
            }
          }}
          onChange={(e: any, data, reason) => {
            if (reason === 'removeOption' && e.code === 'Backspace') return;
            handleAvailableChange(data, reason);
          }}
        />
      )}
    </>
  );
};

export default StatesMultiSelect;
