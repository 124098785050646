import { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import { CreateOrganizationForm } from '../../Forms/CreateOrganizationForm';
import { OrganizationFormType } from '../../Forms/CreateOrganizationForm/types';
import { CancelModal } from '../../modal/CancelModal';
import { AppCancelButton } from '../../shared/AppCancelButton';
import { AppEditButton } from '../../shared/AppEditButton';
import { AppResetButton } from '../../shared/AppResetButton';
import { AppSaveButton } from '../../shared/AppSaveButton';
import { AppWarningParagraph } from '../../shared/AppWarningParagraph';
import { FlexBox, JustifyContentRightBox, SpaceBetweenBox } from '../../shared/FlexBox';
import { useDataloss } from '../../../hooks/useDataloss';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { useToast } from '../../../hooks/useToast';
import { useCheckOrgHasInProgressAgreements } from '../../../services/organization';
import { OrganizationType } from '../../../types/query-params';
import { useUpdateSellerBusinessInfo } from '../../../services/sellerServices';
import { UpdateSellerPayload, UpdateSellerRequestPayload } from '../../../types';
import { CommonOrganization } from '../../../types/sellerGroup';
import { FeinSource } from '../../../types/organization';
import { getPhonePayload } from '../../shared/AppPhoneField';
import { useNavigateAfterDatalossDecision } from '../../../hooks/useNavigateAfterDatalossDecision';
import { patchFormatter } from '../../../utils/organizationPatchFormatter';
import { Box } from '@mui/material';
import LabelRequired from '../../shared/LabelRequired';
import { useQueryClient } from 'react-query';

export const SellerBusinessInfo = () => {
  const { sellerInformation, refetchSellerData, isCompletedAgreement } = useOutletContext<{
    sellerInformation?: CommonOrganization;
    refetchSellerData: () => void;
    isCompletedAgreement: boolean;
  }>();
  const queryClient = useQueryClient();

  const { id } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();
  const isUpdateSellerPermission = usePermissions(PermissionKeys.ModifySellers);
  const isSendForSignaturePermission = usePermissions(PermissionKeys.SendForSignatures);

  const { data: isAgreementInProcess } = useCheckOrgHasInProgressAgreements(Number(id));

  const formRef = useRef<HTMLFormElement>(null);

  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [customFieldError, setCustomFieldError] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [cancelPopUp, setCancelPopUp] = useState(false);
  const { mutate: updateSeller } = useUpdateSellerBusinessInfo(setCustomFieldError, {
    onSuccess(data, variables) {
      queryClient.removeQueries({ queryKey: ['sellers'] });

      toast.success(t('seller.update.success', { name: variables.payload.legalName }));
      if (!navigateAfterDatalossDecision()) {
        setIsEdit(false);
      }
      refetchSellerData();
    },
  });

  const onFormSubmit = async (data: OrganizationFormType) => {
    const originalData: Partial<UpdateSellerPayload> = {
      legalName: sellerInformation?.legalName,
      legalId: sellerInformation?.fein?.replaceAll('-', '') || '',
      legalEntityTypeId: sellerInformation?.legalEntityTypeId,
      organizationType: 4,
      doingBusinessAs: sellerInformation?.doingBusinessAs,
      addresses: [
        {
          addressId: sellerInformation?.addresses[0].addressId,
          addressType: sellerInformation?.addresses[0].addressTypeId,
          streetAddressLine1: sellerInformation?.addresses[0].streetAddressLine1,
          streetAddressLine2: sellerInformation?.addresses[0].streetAddressLine2,
          city: sellerInformation?.addresses[0].city,
          stateId: sellerInformation?.addresses[0].stateId,
          zipCode: sellerInformation?.addresses[0].zipCode,
          sameAsAddressTypeId: null,
        },
        {
          addressId: sellerInformation?.addresses[1].addressId,
          addressType: sellerInformation?.addresses[1].addressTypeId,
          streetAddressLine1: sellerInformation?.addresses[1].streetAddressLine1,
          streetAddressLine2: sellerInformation?.addresses[1].streetAddressLine2,
          city: sellerInformation?.addresses[1].city,
          stateId: sellerInformation?.addresses[1].stateId,
          zipCode: sellerInformation?.addresses[1].zipCode,
          sameAsAddressTypeId: sellerInformation?.addresses[1].sameAsAddressTypeId,
        },
      ],
      phoneNumber: sellerInformation?.addresses[0]?.phoneNumber || null,
      phoneNumberCountryCode: sellerInformation?.addresses[0]?.phoneNumberCountryCode || null,
      phoneNumberExt: sellerInformation?.addresses[0]?.phoneNumberExt || null,
      websiteURL: sellerInformation?.addresses[0].website,
      fax: sellerInformation?.addresses[0].mainFax?.replaceAll('-', ''),
      feinSource: sellerInformation?.feinSource,
      sellerGroupId: sellerInformation?.sellerGroupId,
    };

    const updateSellerPayload: Partial<UpdateSellerPayload> = {
      legalName: data.legalName,
      legalId: data.fein?.replaceAll('-', '') || '',
      legalEntityTypeId: data.legalOrganization?.value,
      organizationType: 4,
      doingBusinessAs: data.doingBusinessAs || null,
      addresses: [
        {
          addressId: data.physicalLocation.addressId,
          addressType: 1,
          streetAddressLine1: data.physicalLocation.address1,
          streetAddressLine2: data.physicalLocation.address2 || null,
          city: data.physicalLocation.city,
          country: 'US',
          stateId: data.physicalLocation.state?.value?.addressStateId,
          zipCode: data.physicalLocation.zip,
          sameAsAddressTypeId: null,
        },
        {
          addressId: data.mailingLocation.addressId,
          addressType: 2,
          streetAddressLine1: data.mailingLocation.address1,
          streetAddressLine2: data.mailingLocation.address2 || null,
          city: data.mailingLocation.city,
          country: 'US',
          stateId: data.mailingLocation.state?.value?.addressStateId,
          zipCode: data.mailingLocation.zip,
          sameAsAddressTypeId: data.isLocationsTheSame ? 1 : 0,
        },
      ],
      ...getPhonePayload(data.phone),
      websiteURL: data.website || null,
      fax: data.fax?.replaceAll('-', ''),
      feinSource: data.isSellerGroupFein ? FeinSource.SellerGroup : FeinSource.Seller,
      sellerGroupId: data.sellerGroup?.value,
    };

    const updatePayload: UpdateSellerRequestPayload = {
      id: sellerInformation?.sellerId || 0,
      payload: patchFormatter(originalData, updateSellerPayload),
    };

    updateSeller(updatePayload);
  };

  const onDiscardChanges = useCallback(() => {
    formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
    setIsEdit(false);
    setCancelPopUp(false);
  }, []);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, [setCancelPopUp]);

  useDataloss(isUnsavedData && isEdit, onDiscardChanges, onSaveChanges, onExit);

  return (
    <>
      {!isEdit && (isAgreementInProcess || isCompletedAgreement) && (
        <JustifyContentRightBox mb={2}>
          {(isSendForSignaturePermission || isUpdateSellerPermission) && isAgreementInProcess && (
            <FlexBox>
              <AppWarningParagraph $align={'left'}>
                <i>{t('productLine.inProgress.warning')}</i>
              </AppWarningParagraph>
            </FlexBox>
          )}
          <AppEditButton
            disabled={!isUpdateSellerPermission || isAgreementInProcess}
            onClick={() => {
              setIsEdit(true);
            }}
          />
        </JustifyContentRightBox>
      )}
      {isEdit && (
        <SpaceBetweenBox>
          <Box>
            <LabelRequired />
          </Box>
          <JustifyContentRightBox mb={1}>
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setCancelPopUp(true);
                } else {
                  setIsEdit(false);
                }
              }}
            />
            <AppResetButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('reset', { cancelable: true, bubbles: true }),
                );
              }}
            />
            <AppSaveButton
              disabled={isFormValid ? !isUnsavedData === isFormValid : true}
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }}
            />
          </JustifyContentRightBox>
        </SpaceBetweenBox>
      )}

      <CreateOrganizationForm
        editForm={isEdit}
        orgData={sellerInformation}
        formRef={formRef}
        onSubmit={onFormSubmit}
        setIsUnsavedChanges={setIsUnsavedData}
        setIsFormValid={setIsFormValid}
        customFieldError={customFieldError}
        setCustomFieldError={setCustomFieldError}
        orgType={OrganizationType.Seller}
      />
      <CancelModal
        isOpened={cancelPopUp}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardChanges}
        onSaveChanges={onSaveChanges}
        onExit={onExit}
      />
    </>
  );
};
