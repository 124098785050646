import { Box } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationService } from '../../../services/configurationService';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';

export default function DealerTypeGroups() {
  const apiRef = useGridApiRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ReadConfiguration);
  const { data: dealerTypeGroups, isFetching } = ConfigurationService.useDealerTypeGroups({
    enabled: isEnabled,
  });

  const rows = useMemo(() => {
    return (
      dealerTypeGroups?.map((item) => ({
        ...item,
        dealerTypeGroupName: item.dealerTypeGroupName,
        dealerTypeGroupDisplayName: item.dealerTypeGroupDisplayName,
        dealerTypeName: item.dealerTypeName,
        id: item.dealerTypeGroupId,
      })) || []
    );
  }, [dealerTypeGroups]);

  const columns: GridColDef[] = [
    {
      field: 'dealerTypeGroupName',
      headerName: 'Dealer Type Group',
      renderCell(params) {
        return (
          <Link
            to={`/dashboard/Configuration/DealerGroupType/${params.row.dealerTypeGroupId}/AssetMake?dealerTypeGroupName=${params.row.dealerTypeGroupDisplayName}&dealerType=${params.row.dealerTypeName}`}
          >
            {params.row.dealerTypeGroupName}
          </Link>
        );
      },
    },
    { field: 'dealerTypeGroupDisplayName', headerName: 'Dealer Type Group Display Name' },
    {
      field: 'dealerTypeName',
      headerName: 'Dealer Type',
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      apiRef.current?.autosizeColumns({
        includeHeaders: true,
        columns: ['dealerTypeGroupName', 'dealerTypeGroupDisplayName', 'dealerTypeName'],
        expand: true,
      });
    }, 500);
  }, [apiRef, rows, dealerTypeGroups]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.noPermissions') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Dealer Type Groups" />
      </Box>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        loading={isFetching}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
      />
    </>
  );
}
