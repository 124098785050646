import { Box, FormControl, OutlinedInput } from '@mui/material';
import styled from 'styled-components';
import { MainTextColor, White } from '../../Theme/colorsVariables';
import { breakPointMedium } from '../../utils/MediaBreakPoint';
import { FlexBox } from '../../components/shared/FlexBox';

export const boxInputMedia = `
text-align: center;
padding: 16px 0px 6px;
`;

export const buttonMedia = `
width: 220px;
height: 50px;
`;

export const StyledBox = styled(Box)`
  text-align: center;
  width: 80%;
`;

export const FilterLabel = styled(Box)`
  font-size: 16px;
  color: ${MainTextColor};
  font-family: Gotham-Medium;
  width: 300px;
  text-align: end;
  padding: 25px 0px;
  display: inline-block;
  ${breakPointMedium(boxInputMedia)}
`;

export const CancelBox = styled(FlexBox)`
  justify-content: center;
`;

export const PasswordHeading = styled(Box)`
  font-size: 24px;
  font-weight: bold;
  font-family: Gotham-Book;
  color: ${MainTextColor};
`;

export const FormControlS = styled(FormControl)`
  width: 400px;
  margin: 8px 24px;
`;

export const OutlinedInputS = styled(OutlinedInput)`
  background: ${White};
  border-radius: 0;
  fieldset {
    border: none;
  }
  ::placeholder {
    color: ${MainTextColor};
    opacity: 1;
  }
`;
