export function downloadBase64ToPdf(pdf: string, fileName = 'unknown') {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileNameFull = `${fileName}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileNameFull;
  downloadLink.click();
}
export function downloadBlobToPdf(pdf: Blob, fileName = 'unknown') {
  // Create an object URL for the Blob
  const url = window.URL.createObjectURL(pdf);

  // Create a link and trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  // Clean up the object URL
  window.URL.revokeObjectURL(url);
}
