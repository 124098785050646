import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { ApiError } from '../types';

export const AccountService = {
  useValidateSignUpUser: ({
    email,
    options,
  }: {
    email: string;
    options?: Omit<UseQueryOptions<unknown, ApiError>, 'queryKey' | 'queryFn'>;
  }) => {
    const validateUser = async () => {
      const res = await axios.get(`/Account/ValidateSignupUser`, { params: { email } });
      return res.data;
    };
    return useQuery<unknown, ApiError>(['ValidateSignupUser', email], validateUser, options);
  },
};
