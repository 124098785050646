import { AppActionButton, AppActionButtonProps } from '../AppActionButton';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useTranslation } from 'react-i18next';

export const AppResetButton: React.FC<AppActionButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <AppActionButton {...props} variant="outlined" startIcon={<RefreshOutlinedIcon />}>
      {t('action.reset')}
    </AppActionButton>
  );
};
