import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTablePaginationProps } from '../../../types/customTable';
import { AppActionButton } from '../AppActionButton';
import { CustomBoxPagination, CustomPaginationPageNumberBox } from '../CustomTable/styles';
import { AlignCenterBox, SpaceBetweenBox } from '../FlexBox';
import {
  BoxContainer,
  StyledBox,
  StyledPagination,
  TablePaginationS,
  StyledBoxPagination,
} from './styles';

export default function CustomTablePagination({
  page,
  handlePageChange,
  handleRowsPerPageChange,
  rowsPerPage,
  rowsPerPageOptions,
  goError,
  total,
  valueRef,
  setPage,
  pageTotal,
}: CustomTablePaginationProps) {
  const { t } = useTranslation();
  const [pageInputValue, setPageInputValue] = useState<string>('');
  const handleGoclick = (newPage: number): void => {
    if (total) {
      const totalPages = Math.ceil(total / rowsPerPage);
      if (newPage > totalPages) {
        setPage(totalPages - 1);
      } else if (newPage <= 0) {
        setPage(0);
      } else {
        setPage(newPage - 1);
      }
    }
  };

  useEffect(() => {
    setPageInputValue(`${page + 1}`);
  }, [page]);

  return (
    <SpaceBetweenBox my={1} flexWrap={'wrap-reverse'}>
      <CustomBoxPagination>
        {`${t('shared.tablePagination.showing')} ${
          total === 0 ? 0 : rowsPerPage * page + 1
        } - ${pageTotal} ${t('shared.tablePagination.of')} ${total} ${t(
          'shared.tablePagination.records',
        )}`}
      </CustomBoxPagination>
      <StyledBox>
        <TablePaginationS
          count={Math.ceil(total / rowsPerPage)}
          page={page}
          onPageChange={() => undefined}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
          labelRowsPerPage={t('shared.customtablepagination')}
        />
        <AlignCenterBox>
          <StyledPagination
            onChange={handlePageChange}
            page={page + 1}
            count={Math.ceil(total / rowsPerPage)}
            showFirstButton
            showLastButton
            boundaryCount={1}
            size={'small'}
          />
          <AlignCenterBox my={1}>
            <BoxContainer ml={3} mr={1}>
              Go to page:
            </BoxContainer>
            <StyledBoxPagination>
              <CustomPaginationPageNumberBox
                placeholder="1"
                hiddenLabel
                disabled={1 > Math.floor(total / rowsPerPage)}
                id="filled-hidden-label-small"
                variant="outlined"
                value={pageInputValue}
                size="small"
                onChange={(e) => setPageInputValue(e.target.value)}
                inputRef={valueRef}
                error={goError}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleGoclick(+pageInputValue);
                }}
              />
            </StyledBoxPagination>
            <AppActionButton
              sx={{ minWidth: '30px !important' }}
              onClick={() => handleGoclick(+pageInputValue)}
              disabled={
                isNaN(parseInt(pageInputValue)) ||
                parseInt(pageInputValue) < 1 ||
                parseInt(pageInputValue) > Math.ceil(total / rowsPerPage) ||
                1 > Math.floor(total / rowsPerPage)
              }
            >
              Go
            </AppActionButton>
          </AlignCenterBox>
        </AlignCenterBox>
      </StyledBox>
    </SpaceBetweenBox>
  );
}
