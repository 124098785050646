import { t } from 'i18next';
import { FC, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { linkColor, MainRed, BrightGreen } from '../../../Theme/colorsVariables';
import EnvelopeAuditLogTable from '../../../components/EnvelopeAuditLogTable';
import AgreementStatusInfoTooltip from '../../../components/InfoTooltip';
import {
  AssociatedProductLinesContainer,
  AgreementsAbsoluteContainer,
} from '../../../components/SellerGroupTabs/ProductLinesTab/styles';
import { StatusText } from '../../../components/SellerTabs/Agreements/styles';
import { AssociatedProducts } from '../../../components/modal/AssociatedProducts';
import CustomModal from '../../../components/modal/Modal';
import OfacModal from '../../../components/modal/OfacModal';
import CustomTable from '../../../components/shared/CustomTable';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import {
  EnvelopeAuditLog,
  GetEnvelopeAuditLogDetails,
  EnvelopeId,
} from '../../../services/DocuSignService';
import { AgreementsService } from '../../../services/agreementsService';
import { Cell, CellType } from '../../../types/customTable';
import { Order } from '../../../types/query-params';
import { AgreementProductLine } from '../../../types/sellerGroup';
import { formatUtcToLocalTime } from '../../../utils/formatUtcToLocalTime';
import { useMediaQuery, useTheme } from '@mui/material';
import { FontSizeLowestMin, FontSizeMed } from '../../../Theme/screensVariables';
import { Heading } from '../../../components/shared/AppPageHeaderContentHost/styles';
import { AppLink } from '../../../components/shared/CustomTableAddButton/styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExistingRelationshipTableProps {}

const ExistingRelationshipTable: FC<ExistingRelationshipTableProps> = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const isReadContactPermission = usePermissions(PermissionKeys.ReadContact);
  const isReadProductLinePermission = usePermissions(PermissionKeys.ViewProducts);
  const isReadAgentPermission = usePermissions(PermissionKeys.ReadAgent);

  const [selectedAssociatedProductLines, setSelectedAssociatedProductLines] = useState<
    AgreementProductLine[] | null
  >(null);
  const [ofacModalState, setOfacModalState] = useState<{
    isOpen: boolean;
    agreementId: number | null;
  }>({ isOpen: false, agreementId: null });
  const [order, setOrder] = useState<Order>(Order.ASC);
  const [orderBy, setOrderBy] = useState<string>('sendOn');
  const [isAuditLogOpened, setIsAuditLogOpened] = useState(false);
  const [envelopeAuditLogDetails, setEnvelopeAuditLogDetails] = useState<EnvelopeAuditLog[]>([]);

  const { data: existingRelations, isFetching } = AgreementsService.useExistingAgreements({
    orgId: id || '',
    payload: {
      pageNumber: 0,
      pageSize: 100,
      shouldMatchAllCriteria: true,
      sortColumn: orderBy,
      sortDirection: order,
    },
    options: {
      enabled: !!id,
    },
  });

  const tableHeadingsArray = useMemo(() => {
    return [
      { 'Agreement Type': '' },
      { 'Product Lines': '' },
      { Agent: 'agent' },
      { 'Agent Relationship Owner': 'agentContact' },
      { 'Effective Date': 'effectiveDate' },
      { Signer: 'signer' },
      { 'Sent By': 'sendBy' },
      { 'Sent On': 'sendOn' },
      { 'OFAC Status': '' },
      { 'DS Status': 'status' },
    ] as Record<string, string>[];
  }, []);

  const { mutateAsync: getEnvelopeAuditLogDetails } = GetEnvelopeAuditLogDetails();

  const openAuditLogModal = useCallback(
    async ({ envelopeId }: EnvelopeId) => {
      const envelopeAuditLog = await getEnvelopeAuditLogDetails({ envelopeId });
      if (envelopeAuditLog && envelopeAuditLog.length && typeof envelopeAuditLog !== 'string') {
        setIsAuditLogOpened(true);
        setEnvelopeAuditLogDetails(envelopeAuditLog);
      }
    },
    [getEnvelopeAuditLogDetails],
  );

  const existingRelationsRows = useMemo(() => {
    const truncateProducts = (words: string[], maxlength: number, openMore: () => void) => {
      if (!words.length) {
        return <span>Unavailable</span>;
      }
      if (words.length === 1) {
        return words[0];
      }
      const productLines = `${words.slice(0, maxlength).join(', ')}, ${
        words.length - maxlength
      } more....`;
      return (
        <u style={{ color: linkColor, cursor: 'pointer' }}>
          <span onClick={openMore}>{productLines}</span>
        </u>
      );
    };

    return (
      existingRelations?.data.map<Cell[]>((agreement) => {
        return [
          {
            data: agreement.agreementType,
            type: CellType.Info,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: (
              <>
                {agreement.productLines.length === 1 ? (
                  <>
                    {isReadProductLinePermission && (
                      <AppLink
                        to={`/dashboard/ProductLine/${agreement.productLines[0].productId}`}
                        target={'_blank'}
                      >
                        {agreement.productLines[0].productName}
                      </AppLink>
                    )}
                    {!isReadProductLinePermission && <>{agreement.productLines[0].productName}</>}
                  </>
                ) : (
                  truncateProducts(
                    agreement.productLines.map((pr) => pr.productName),
                    1,
                    () => {
                      setSelectedAssociatedProductLines(agreement.productLines);
                    },
                  )
                )}
              </>
            ),
            type: CellType.Action,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: (
              <>
                {isReadAgentPermission && agreement.agent ? (
                  <AppLink to={`/dashboard/Agent/${agreement.agent?.id}`} target={'_blank'}>
                    {agreement.agent?.name || '-'}
                  </AppLink>
                ) : (
                  <>{agreement.agent?.name || '-'}</>
                )}
              </>
            ),
            type: CellType.Action,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: (
              <>
                {isReadAgentPermission &&
                isReadContactPermission &&
                agreement.agent &&
                agreement.agentContact ? (
                  <AppLink
                    to={`/dashboard/Agent/${agreement.agent?.id}/Contact/${agreement.agentContact?.id}`}
                    target={'_blank'}
                  >
                    {agreement.agentContact?.name || '-'}
                  </AppLink>
                ) : (
                  <>{agreement.agentContact?.name || '-'}</>
                )}
              </>
            ),
            type: CellType.Action,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: agreement.envelopeId
              ? formatUtcToLocalTime(`${agreement.effectiveDate}`, false)
              : '-',
            type: CellType.Info,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: (
              <>
                {isReadContactPermission && !!agreement.envelopeId && !!agreement.signer?.id && (
                  <AppLink
                    to={`/dashboard/SellerGroup/${id}/Contact/${agreement.signer?.id}`}
                    target={'_blank'}
                  >
                    {agreement.signer?.name || '-'}
                  </AppLink>
                )}
                {(!isReadContactPermission || !agreement.envelopeId) && (
                  <>{agreement.signer?.name || '-'}</>
                )}
              </>
            ),
            type: CellType.Action,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: agreement.sendBy?.name || '-',
            type: CellType.Info,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: agreement.envelopeId ? formatUtcToLocalTime(`${agreement.sendOn}`) : '-',
            type: CellType.Info,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: (
              <>
                {agreement.ofacStatus ? (
                  <div
                    style={{ color: MainRed, textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      setOfacModalState({ isOpen: true, agreementId: agreement.agreementId });
                    }}
                  >
                    {'Matches found'}
                  </div>
                ) : (
                  <div style={{ color: BrightGreen }}>{'No matches found'}</div>
                )}
              </>
            ),
            type: CellType.Action,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
          {
            data: (
              // DocuSign statuses https://support.docusign.com/s/document-item?language=en_US&rsc_301&bundleId=oeq1643226594604&topicId=wdm1578456348227.html&_LANG=enus
              <>
                <StatusText
                  active={`${!!agreement.envelopeId}`}
                  onClick={() => {
                    if (!!agreement.envelopeId) {
                      openAuditLogModal({ envelopeId: agreement.envelopeId });
                    }
                  }}
                >
                  {agreement.status}
                </StatusText>
                {(!!agreement.declinedBy ||
                  !!agreement.deliveryFailureEmail ||
                  !!agreement.voidedBy ||
                  !!agreement.reviewedBy) && (
                  <AgreementStatusInfoTooltip
                    agreementStatusReason={agreement.agreementStatusReason}
                    declinedBy={agreement.declinedBy}
                    deliveryFailureEmail={agreement.deliveryFailureEmail}
                    reviewedBy={agreement.reviewedBy}
                    reviewedOn={agreement.reviewedOn}
                    statusReasonMessage={agreement.statusReasonMessage}
                    voidedBy={agreement.voidedBy}
                  />
                )}
              </>
            ),
            type: CellType.Action,
            tableCellProps: {
              style: {
                width: '10%',
              },
            },
          },
        ];
      }) || []
    );
  }, [
    existingRelations?.data,
    id,
    isReadAgentPermission,
    isReadContactPermission,
    isReadProductLinePermission,
    openAuditLogModal,
  ]);
  return (
    <AssociatedProductLinesContainer maxHeight={'100% !important'}>
      <AgreementsAbsoluteContainer>
        <Heading flexGrow={0} fontSize={isSmall ? FontSizeLowestMin : FontSizeMed}>
          {'Existing Relationship(s)'}
        </Heading>
        <CustomTable
          displayOptions={{ paginationTop: false, paginationBottom: false, tableOnly: true }}
          unsortableColumns={['Actions', 'Agreement Type']}
          page={0}
          rowsPerPage={0}
          data={existingRelationsRows}
          valueRef={undefined}
          goError={false}
          handlePageChange={() => {}}
          handleRowsPerPageChange={() => {}}
          tableHeadingsArray={tableHeadingsArray}
          advancedSearchVisibility={true}
          setAdvancedSearchVisibility={() => {}}
          setPagination={() => {}}
          onSubmitAdvancedSearch={() => {}}
          onResetAdvancedSearch={() => {}}
          searchValue={''}
          handleSearchValue={() => {}}
          setPage={() => {}}
          tableTitle={''}
          placeHolder={t('action.search')}
          addNewLink={{ link: '', state: undefined }}
          order={order}
          orderBy={orderBy}
          handleRequestSort={(newOrder, newOrderBy) => {
            setOrder(newOrder);
            setOrderBy(newOrderBy);
          }}
          InputsComponent={() => <></>}
          isDataLoading={isFetching}
          total={0}
          openAdvancedSearch={() => {}}
          isAdvanceSearch={false}
        />
      </AgreementsAbsoluteContainer>
      <CustomModal
        modalTitle={'Audit Log'}
        open={isAuditLogOpened}
        containerWidth={'1000px'}
        modalProps={{
          onClose: () => {
            setIsAuditLogOpened(false);
            setEnvelopeAuditLogDetails([]);
          },
        }}
      >
        {envelopeAuditLogDetails.length && (
          <EnvelopeAuditLogTable envelopeAuditLogDetails={envelopeAuditLogDetails} />
        )}
      </CustomModal>
      <AssociatedProducts
        isOpened={!!selectedAssociatedProductLines}
        productLines={selectedAssociatedProductLines}
        setIsOpen={() => {
          setSelectedAssociatedProductLines(null);
        }}
      />
      <OfacModal
        isOpen={ofacModalState.isOpen}
        agreementId={ofacModalState.agreementId}
        onClose={() => {
          setOfacModalState({ isOpen: false, agreementId: null });
        }}
      />
    </AssociatedProductLinesContainer>
  );
};

export default ExistingRelationshipTable;
