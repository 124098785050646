import React, { useEffect, useState, useRef, ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../components/shared/CustomTable';
import { RolesData } from '../../types';
import { AddNewLink, Cell, CellType, NewLinkState } from '../../types/customTable';
import { EntityType, Order, Status } from '../../types/query-params';
import { RoleService } from '../../services/roleService';
import {
  QuickTableFilter,
  StatusSelectData,
} from '../../components/shared/CustomTableFilters/interface';
import { menuItems } from './role-list-data';
import useDebounce from '../../hooks/useDebounce';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import useTrim from '../../hooks/useTrim';
import { getSearchString } from '../../utils';
import { useSaveSearchCriteria } from '../../hooks/useSaveSearchCriteria';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';
import useDebouncedQuery from '../../hooks/useDebouncedQuery';

const SEARCH_KEY = 'roleList.search';

function RoleList() {
  const isCreateRoleAgenciesPermission = usePermissions(PermissionKeys.CreateAgentRoles);
  const isCreateRoleProviderPermission = usePermissions(PermissionKeys.CreateProviderRoles);

  const {
    searchTerm,
    page,
    rowsPerPage,
    order,
    orderBy,
    status,
    assosiatedWith,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setStatus,
    setAssosiatedWith,
  } = useSaveSearchCriteria({
    searchKey: SEARCH_KEY,
  });

  const [roleRows, setRoleRows] = useState<Cell[][]>([]);
  const debounceSearch = useDebounce(searchTerm, 300);
  const trimValue = useTrim(searchTerm);

  const {
    data: roles,
    status: rolesStatus,
    refetch,
  } = RoleService.useAll({
    name: getSearchString(debounceSearch),
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    sortColumn: orderBy,
    sortDirection: order,
    associatedWith:
      assosiatedWith?.label === EntityType.Any ? '' : `${assosiatedWith?.value || ''}`,
    status: status?.label === Status.Any ? Status.Default : (status?.label as Status),
  });
  useDebouncedQuery(refetch, true);

  const valueRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const tableHeadingsArray = useMemo(() => {
    return [
      { Role: 'name' },
      { 'Associated With': 'associatedWith' },
      { '# Permissions': 'permissionCount' },
      { '# Users': 'userCount' },
      { Status: 'status' },
    ] as Record<string, string>[];
  }, []);
  /** This is to identify which method to use in pagination */
  const [pagination, setPagination] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState(0);
  const [addNewLink, setAddNewLink] = useState<AddNewLink>({
    link: '',
    state: NewLinkState.active || NewLinkState.disabled,
  });

  const menuItemsUser: StatusSelectData[] = [
    { value: EntityType.Any, text: t('status.any') },
    { value: EntityType.Agency, text: t('status.agency') },
    { value: EntityType.Provider, text: t('status.provider') },
  ];

  const roleRowsMapper = useCallback(
    (data: RolesData[]): void => {
      const mappedCells = data?.map((item) => [
        {
          data: item.roleName,
          type: CellType.Link,
          linkTo: `/dashboard/Role/${item.roleId}`,
        },
        {
          data: item.assosiatedWith
            ? item.assosiatedWith === 'Agency'
              ? 'Agent'
              : item.assosiatedWith
            : '-',
          type: CellType.Info,
        },
        {
          data: `${item.permissionCount} ${t('formField.permissions')}`,
          type: CellType.Info,
        },
        {
          data: `${item.userCount} ${t('security.users.usertable.users')}`,
          type: CellType.Link,
          linkTo: `/dashboard/User?roleId=${item.roleId}`,
        },
        {
          data: item.status,
          type: CellType.StatusFilter,
          defaultStatus: 'Active',
        },
      ]);
      setRoleRows(mappedCells);
    },
    [t],
  );

  const handleSearchValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setSearchTerm, setPage],
  );

  const handleEntityChange = (value: { label: string; value: string }) => {
    setAssosiatedWith(value);
    setPage(0);
  };

  const handleFilterStatus = useCallback(
    (value: { label: Status; value: Status }) => {
      setStatus(value);
      setPage(0);
    },
    [setPage, setStatus],
  );

  const selectArray: QuickTableFilter[] = [
    {
      label: t('formField.associatedWith'),
      value: assosiatedWith,
      handleFilter: handleEntityChange,
      filterOptions: menuItemsUser,
      type: 'single',
    },
    {
      label: 'Status',
      value: status,
      handleFilter: handleFilterStatus,
      filterOptions: menuItems,
      type: 'single',
    },
  ];

  useEffect(() => {
    if (!roles) {
      return;
    } else {
      setTotalCount(roles.totalCount);
      roleRowsMapper(roles.data);
    }
  }, [roles, roleRowsMapper]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage - 1);
    },
    [setPage],
  );

  const handleRowsPerPageChange = useCallback(
    (newLimit: number): void => {
      setRowsPerPage(newLimit);
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      if (!roles) return;
      setOrder(newOrder);

      setOrderBy(newOrderBy);
    },
    [roles, setOrder, setOrderBy],
  );

  const handleClearInputField = useCallback(() => {
    roleRowsMapper([]);
  }, [roleRowsMapper]);

  useEffect(() => {
    setAddNewLink({
      link:
        isCreateRoleAgenciesPermission || isCreateRoleProviderPermission
          ? '/dashboard/Role/Add'
          : '/dashboard/Role',
      state:
        isCreateRoleAgenciesPermission || isCreateRoleProviderPermission
          ? NewLinkState.active
          : NewLinkState.disabled,
    });
  }, [isCreateRoleAgenciesPermission, isCreateRoleProviderPermission]);

  return (
    <>
      <CustomTable
        isDataLoading={rolesStatus === 'loading'}
        order={order}
        orderBy={orderBy}
        setPagination={setPagination}
        handleRequestSort={onHandleSetOrder}
        goError={false}
        page={page}
        rowsPerPage={rowsPerPage}
        data={roleRows}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        valueRef={valueRef}
        setPage={setPage}
        tableHeadingsArray={tableHeadingsArray}
        openAdvancedSearch={roleRowsMapper}
        searchValue={trimValue}
        handleSearchValue={handleSearchValue}
        advancedSearchVisibility={false}
        setAdvancedSearchVisibility={() => {}}
        onSubmitAdvancedSearch={() => {}}
        onResetAdvancedSearch={handleClearInputField}
        isDataPagination={pagination}
        isAdvanceSearch={false}
        addNewLink={addNewLink}
        tableTitle="Roles"
        placeHolder={t('action.search')}
        selectArray={selectArray}
        total={totalCount}
        err={false}
        InputsComponent={() => <></>}
      />
    </>
  );
}

export const RoleListProtected = () => {
  const { t } = useTranslation();
  const isReadAgentRolesPermission = usePermissions(PermissionKeys.ReadAgentRoles);
  const isReadProviderRolesPermission = usePermissions(PermissionKeys.ReadProviderRoles);

  if (!isReadAgentRolesPermission && !isReadProviderRolesPermission) {
    return <NoPermissionMessage message={t('relationships.organisation.view.permission')} />;
  }
  return <RoleList />;
};
