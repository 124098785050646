import { Paper, TableRow } from '@mui/material';
import styled from 'styled-components';

export const AuditTableContainer = styled(Paper)`
  height: 70vh;
  width: 100%;
  overflow-y: auto;
`;

export const AuditTableRow = styled(TableRow)`
  td {
    font-size: 14px !important;
  }
  &:last-child td,
  &:last-child th {
    border: 0 !important;
  }
`;
