import { Grid2, useMediaQuery, useTheme } from '@mui/material';
import { AppTextField } from '../shared/AppTextField';
import { formatUtcToLocalTime } from '../../utils/formatUtcToLocalTime';
import { SectionHeadings } from '../Forms/CreateOrganizationForm/styles';

export interface IModificationLog {
  createdOn: string | null | undefined;
  createdBy: { id: number; name: string } | null | undefined;
  updatedOn: string | null | undefined;
  updatedBy: { id: number; name: string } | null | undefined;
}

export const ModificationLog = ({
  createdOn,
  createdBy,
  updatedBy,
  updatedOn,
}: IModificationLog) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  return (
    <Grid2 container spacing={isSmall ? 1 : 2} my={1}>
      <Grid2 size={{ xs: 12 }}>
        <SectionHeadings>Modification Log</SectionHeadings>
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <AppTextField value={createdBy?.name || ''} label={'Created By'} disabled />
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <AppTextField value={formatUtcToLocalTime(createdOn || '')} label={'Created On'} disabled />
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <AppTextField value={updatedBy?.name || ''} label={'Last Updated By'} disabled />
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <AppTextField
          value={formatUtcToLocalTime(updatedOn || '')}
          label={'Last Updated On'}
          disabled
        />
      </Grid2>
    </Grid2>
  );
};
