import { ConfigurationService } from '../../../services/configurationService';
import { Box } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';

export default function DMSProviderPage() {
  const apiRef = useGridApiRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ReadConfiguration);
  const { data: dms, isFetching } = ConfigurationService.useDMS({
    enabled: isEnabled,
  });

  const rows = useMemo(() => {
    return (
      dms?.map((item) => ({
        ...item,
        id: item.dmsId,
      })) || []
    );
  }, [dms]);

  const columns: GridColDef[] = [
    { field: 'dmsName', headerName: 'DMS Name' },
    { field: 'dmsDisplayName', headerName: 'DMS Display Name' },
  ];

  useEffect(() => {
    setTimeout(() => {
      apiRef.current?.autosizeColumns({
        includeHeaders: true,
        columns: ['dmsName', 'dmsDisplayName'],
        expand: true,
      });
    }, 500);
  }, [apiRef, rows, dms]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.noPermissions') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Dealer Management Systems" />
      </Box>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        loading={isFetching}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
      />
    </>
  );
}
