import React, { useContext } from 'react';
import { Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Item, HomeHeading } from './styles';
import RecentActivity from './recent-activity/recentActivity';
import InMyCourt from './InMyCourt';
import { UserContext } from '../../context/User';
import AgreementActivityForAgent from './AgreementActivity';

export default function Dashboard() {
  const user = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <Grid2 container spacing={2} mt={1}>
      {/* InMyCourt section which could be used in future */}
      {user?.userType === 'Provider' && (
        <Grid2 size={{ xs: 7 }}>
          <Item>
            <HomeHeading mb={1}>{t('dashboard.header.court')}</HomeHeading>
            <InMyCourt />
          </Item>
        </Grid2>
      )}
      {user?.userType === 'Agency' && (
        <Grid2 size={{ xs: 7 }}>
          <Item>
            <HomeHeading mb={1}>Agreements History for {user.legalName}</HomeHeading>
            <AgreementActivityForAgent />
          </Item>
        </Grid2>
      )}
      <Grid2 size={{ xs: 5 }}>
        <Item>
          <HomeHeading mb={1}>{t('dashboard.header.activity')}</HomeHeading>
          <RecentActivity />
        </Item>
      </Grid2>
    </Grid2>
  );
}
