import axios from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { ApiError } from '../types';
import {
  AssetMake,
  AverageAssetInventoryRange,
  DealerType,
  DmsItem,
  FIMenu,
  LegalEntityType,
  TenantSetupItem,
  TenantSetupUpdatePayload,
} from '../types/configurations';

export const ConfigurationService = {
  useAverageAssetInventoryRange: (
    options?:
      | Omit<UseQueryOptions<AverageAssetInventoryRange[], ApiError>, 'queryKey' | 'queryFn'>
      | undefined,
  ) => {
    const req = async () => {
      const res = await axios.get(`/configuration/average-asset-inventory-range`);
      return res.data;
    };

    return useQuery<AverageAssetInventoryRange[], ApiError>('averageAssetInventoryRange', req, {
      staleTime: Infinity,
      ...options,
    });
  },
  useDealerTypeGroups: (
    options?: Omit<UseQueryOptions<DealerType[], ApiError>, 'queryKey' | 'queryFn'> | undefined,
  ) => {
    const req = async () => {
      const res = await axios.get(`/configuration/dealer-type-group`);
      return res.data;
    };

    return useQuery<DealerType[], ApiError>('useDealerTypeGroups', req, {
      staleTime: Infinity,
      ...options,
    });
  },
  useFIMenus: (
    options?: Omit<UseQueryOptions<FIMenu[], ApiError>, 'queryKey' | 'queryFn'> | undefined,
  ) => {
    const req = async () => {
      const res = await axios.get(`/configuration/f-i-menu`);
      return res.data;
    };

    return useQuery<FIMenu[], ApiError>('useFIMenus', req, {
      staleTime: Infinity,
      ...options,
    });
  },
  useDMS: (
    options?: Omit<UseQueryOptions<DmsItem[], ApiError>, 'queryKey' | 'queryFn'> | undefined,
  ) => {
    const req = async () => {
      const res = await axios.get(`/configuration/dealer-management-system`);
      return res.data;
    };

    return useQuery<DmsItem[], ApiError>('useDMS', req, {
      staleTime: Infinity,
      ...options,
    });
  },
  useAssetMakes: (
    dealerTypeGroupId?: number,
    options?: Omit<UseQueryOptions<AssetMake[], ApiError>, 'queryKey' | 'queryFn'> | undefined,
  ) => {
    const req = async () => {
      const res = await axios.get(`/configuration/asset-make`, { params: { dealerTypeGroupId } });
      return res.data;
    };

    return useQuery<AssetMake[], ApiError>(['useAssetMakes', dealerTypeGroupId], req, {
      staleTime: Infinity,
      ...options,
    });
  },
  useLegalEntityTypes: (
    options?:
      | Omit<UseQueryOptions<LegalEntityType[], ApiError>, 'queryKey' | 'queryFn'>
      | undefined,
  ) => {
    const req = async () => {
      const res = await axios.get(`/configuration/legal-entity-type`);
      return res.data;
    };

    return useQuery<LegalEntityType[], ApiError>('useLegalEntityTypes', req, {
      ...options,
      staleTime: Infinity,
    });
  },
  useSupportInfo: (
    options?: Omit<
      UseQueryOptions<{ supportEmail: string; supportPhoneNumber: string }, ApiError>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    const req = async () => {
      const res = await axios.get(`/configuration/support-information`);
      return res.data;
    };

    return useQuery<{ supportEmail: string; supportPhoneNumber: string }, ApiError>(
      'useSupportInfo',
      req,
      {
        staleTime: Infinity,
        ...options,
      },
    );
  },
  useTenantConfiguration: (
    options?: Omit<UseQueryOptions<Array<TenantSetupItem>, ApiError>, 'queryKey' | 'queryFn'>,
  ) => {
    const req = async () => {
      const res = await axios.get(`/configuration/tenant-configuration`);
      return res.data;
    };

    return useQuery<Array<TenantSetupItem>, ApiError>('useTenantSetup', req, options);
  },
  useUpdateTenantConfiguration: (
    options?: Omit<
      UseMutationOptions<TenantSetupUpdatePayload, ApiError, TenantSetupUpdatePayload>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    const req = async (payload: TenantSetupUpdatePayload) => {
      const res = await axios.patch(`/configuration/tenant-configuration`, payload);
      return res.data;
    };

    return useMutation<TenantSetupUpdatePayload, ApiError, TenantSetupUpdatePayload>(
      'useTenantSetup',
      req,
      options,
    );
  },
};
