import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useToast } from '../hooks/useToast';
import {
  AdvanceSearchParams,
  AgencyData,
  ApiError,
  PaginationResponse,
  PayloadAdvanceSearch,
} from '../types';
import { AgentInfo, SmallAgent } from '../types/agent';
import { OrganizationContact } from '../types/sellerGroupContacts';
import usePermissions, { PermissionKeys } from '../hooks/usePermissions';
import { normalizePhone } from '../utils';

export const useAllAgents = (payload: PayloadAdvanceSearch<AdvanceSearchParams>) => {
  const isViewAgentsPermission = usePermissions(PermissionKeys.ReadAgent);
  const {
    pageNumber,
    name,
    agentId,
    shouldMatchAllCriteria,
    pageSize,
    sortColumn,
    sortDirection,
    advancedSearch,
    accountOwnerId,
  } = payload;

  const filterParams = {
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    name: advancedSearch?.name || name,
    shouldMatchAllCriteria: shouldMatchAllCriteria,
    agentId: agentId,
    phone: normalizePhone(advancedSearch?.phone || ''),
    physicalAddress: advancedSearch?.physicalAddress,
    physicalCity: advancedSearch?.physicalCity,
    physicalStateId: advancedSearch?.physicalStateId,
    physicalZipCode: advancedSearch?.physicalZipCode,
    mailingAddress: advancedSearch?.mailingAddress,
    mailingCity: advancedSearch?.mailingCity,
    mailingStateId: advancedSearch?.mailingStateId,
    mailingZipCode: advancedSearch?.mailingZipCode,
    legalEntityTypeId: advancedSearch?.legalEntityTypeId,
    accountOwnerId: accountOwnerId,
  };

  const fetchAgents = async () => {
    const fetch = await axios.post<PaginationResponse<AgencyData>>(`/Agent/page`, filterParams);
    return fetch.data;
  };

  const state = useQuery<PaginationResponse<AgencyData>, Error>(
    ['useAllAgents', payload],
    fetchAgents,
    { enabled: isViewAgentsPermission },
  );

  return state;
};

export const useAgentById = (id: number) => {
  const toast = useToast();
  const isViewAgentPermission = usePermissions(PermissionKeys.ReadAgent);
  const fetchAgent = async () => {
    const fetch = await axios.get(`/Agent/${id}`);
    return fetch?.data;
  };

  const state = useQuery<AgentInfo, ApiError>(['useAgentById', id], fetchAgent, {
    onError(err) {
      toast.error(err.response.data.message + ' ' + err.response.data.errorUserTitle);
    },
    enabled: isViewAgentPermission && !!id,
  });

  return state;
};

export const useAgentContactsAll = ({
  agentId,
  options,
}: {
  agentId: string | number;
  options?:
    | Omit<UseQueryOptions<OrganizationContact[], ApiError>, 'queryKey' | 'queryFn'>
    | undefined;
}) => {
  const fetchContacts = async () => {
    const res = await axios.get<OrganizationContact[]>(`/Agent/${agentId}/contacts`);
    return res.data;
  };
  return useQuery<OrganizationContact[], ApiError>(
    ['fetchAllAgentContacts', agentId],
    fetchContacts,
    options,
  );
};

export const useAgentListSmall = (
  options?: Omit<UseQueryOptions<SmallAgent[], ApiError>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const fetchAgents = async () => {
    const res = await axios.get<SmallAgent[]>(`/Agent/list`);
    return res.data;
  };
  return useQuery<SmallAgent[], ApiError>('fetchAllAgents', fetchAgents, {
    staleTime: Infinity,
    ...options,
  });
};
