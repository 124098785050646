import { Checkbox, FormGroup, Grid2, useMediaQuery, useTheme } from '@mui/material';
import { Control, Controller, FieldValues, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import AppAutocompleteSingleSelect from '../../shared/AppAutocompleteSingleSelect';
import { AppTextField } from '../../shared/AppTextField';
import { CenterCenterBox } from '../../shared/FlexBox';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { OrgType, SelectItem, UserFormProps, UserFormType, UserPayload } from '../../../types';
import { PermissionSets, TempPermissionSetNamesWithExtraFunctionality } from '../../PermissionSets';
import { usePermissionSets } from '../../../services/roleService';
import { yupResolver } from '@hookform/resolvers/yup';
import { FeinBox } from '../../common/styles/feinBox';
import { PermissionSet } from '../../../types/permission';
import { CancelModal } from '../../modal/CancelModal';
import { useLocation, useNavigate } from 'react-router-dom';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { AppPhoneField, getPhonePayload } from '../../shared/AppPhoneField';
import {
  useAppRolesByType,
  ProviderFetchingQuery,
  useAllAgents,
} from '../../../services/sellerServices';
import RoleType from '../../../types/businessRoles';
import { userValidationSchema } from '../../../Resolvers/userValidationSchema';
import { useAvailableRoles } from '../../../services/roleService';
import { isEqual } from 'lodash';
import { useContext } from 'react';
import { AsterikSpan, BlockTitle } from './styles';
import { TypographySpan } from '../../shared/StateMultiSelect/styles';
import { UserContext } from '../../../context/User';
import { AppAutocomplete } from '../../shared/AppAutocomplete';
import AppListItem from '../../shared/AppListItem';
import { CheckboxMain } from '../../PermissionSets/styles';

export const UserForm: FC<UserFormProps> = ({
  isEdit,
  defaultValues,
  onSubmit,
  formRef,
  setIsUnsavedData,
  setIsSaveEnabled,
  userStatus,
  setUserStatus,
  cancelPopup,
  setCancelPopup,
  setIsEdit,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { t } = useTranslation();
  const location = useLocation();
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const { data: providerInfo } = ProviderFetchingQuery();

  const isCreateProviderUserPermission = usePermissions(PermissionKeys.CreateProviderUser);
  const isCreateAgentUserPermission = usePermissions(PermissionKeys.CreateAgentUser);
  const providerValue = useMemo(() => {
    return providerInfo?.legalName || '';
  }, [providerInfo?.legalName]);

  const isAddNewUser = useMemo(() => {
    return location.pathname.includes('/Add');
  }, [location.pathname]);

  const isAddNewUserFromAgentContact = useMemo(() => {
    return location.pathname.includes('/Add') && !!location.state;
  }, [location.pathname, location.state]);

  const [assosiatedWithDuplicateValue, setAssosiatedWithDuplicateValue] =
    useState<SelectItem<string> | null>(null);

  const { data: permissionSets } = usePermissionSets({
    orgType: assosiatedWithDuplicateValue?.label === 'Provider' ? OrgType.Provider : OrgType.Agent,
  });

  const { data: agencyContactClassifications } = useAppRolesByType(RoleType.agencyUser);

  const { data: providerContactClassifications } = useAppRolesByType(RoleType.businessWithOther);

  const { data: agencies } = useAllAgents({});
  const { data: availableRoles } = useAvailableRoles({
    userId: defaultValues?.userId,
    assosiatedWith: assosiatedWithDuplicateValue,
  });

  const approveAgentSellerRelationshipPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) =>
          item.name === TempPermissionSetNamesWithExtraFunctionality.ApproveAgentSellerRelationship,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const attachAgreementPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) => item.name === TempPermissionSetNamesWithExtraFunctionality.AttachAgreement,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const deleteAgreementAttachmentPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) =>
          item.name === TempPermissionSetNamesWithExtraFunctionality.DeleteAgreementAttachment,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const signerPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) => item.name === TempPermissionSetNamesWithExtraFunctionality.Signer,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const defaultValuesForForm = useMemo<UserFormType>(() => {
    const associatedWithValue = {
      label: defaultValues
        ? defaultValues?.orgTypeName === 'Agency'
          ? 'Agent'
          : defaultValues?.orgTypeName
        : isCreateProviderUserPermission
        ? 'Provider'
        : isCreateAgentUserPermission
        ? 'Agent'
        : 'Provider',
      value: defaultValues
        ? defaultValues?.orgTypeName === 'Agency'
          ? 'Agent'
          : defaultValues?.orgTypeName
        : isCreateProviderUserPermission
        ? 'Provider'
        : isCreateAgentUserPermission
        ? 'Agent'
        : 'Provider',
    };
    const agentByOrgId = agencies?.find((agent) => agent.agencyId === defaultValues?.orgId);
    const orgNameValue =
      associatedWithValue.label === 'Provider'
        ? { label: providerValue, value: providerValue }
        : { label: agentByOrgId?.agencyName || '', value: agentByOrgId?.agencyName || '' };

    const data: UserFormType = {
      firstName: defaultValues?.firstName || '',
      lastName: defaultValues?.lastName || '',
      assosiatedWith: associatedWithValue,
      permissionsSearchTerm: '',
      permissionSetIds: defaultValues?.permissionSetIds || [],
      fakePermissionArrayForPermissionsValidation: [],
      userStatus: defaultValues ? defaultValues?.status === 1 : true,
      email: defaultValues?.email || '',
      phone: {
        number:
          defaultValues?.phoneNumber && defaultValues?.phoneNumberCountryCode
            ? defaultValues?.phoneNumberCountryCode + defaultValues?.phoneNumber
            : '',
        countryCode: defaultValues?.phoneNumberCountryCode || '',
        ext: defaultValues?.phoneNumberExt || '',
        isValid: true,
      },
      title: defaultValues?.title || '',
      applicationRole: defaultValues ? defaultValues.applicationRoleIds || [] : [],
      contactClassification: [
        ...(agencyContactClassifications || []),
        ...(providerContactClassifications || []),
      ]
        .filter((item) => defaultValues?.contactClassification?.includes(item.roleId))
        .map((item) => ({ label: item.roleName, value: item.roleId })),
      onWiseSystemRole: [],
      orgName: orgNameValue,
      restrictAccessToOwnAgents: !!defaultValues?.restrictAccessToOwnAgents,
      isRestrictAccessToOwnAgentsFromRole: false,
    };

    return data;
  }, [
    defaultValues,
    agencies,
    isCreateProviderUserPermission,
    isCreateAgentUserPermission,
    agencyContactClassifications,
    providerContactClassifications,
    providerValue,
  ]);

  const formMethods = useForm<UserFormType>({
    mode: 'onChange',
    defaultValues: defaultValuesForForm,
    resolver: yupResolver(userValidationSchema),
  });

  const { control, handleSubmit, watch, reset, formState, setValue } = useMemo(
    () => formMethods,
    [formMethods],
  );

  useEffect(() => {
    reset(defaultValuesForForm);
  }, [defaultValuesForForm, reset]);

  const { errors, isValid, defaultValues: defaultValuesFromForm } = formState;

  const {
    firstName,
    lastName,
    email,
    orgName,
    phone: {
      countryCode: currentCountryCode,
      ext,
      isValid: isPhoneValid,
      number: currentPhoneNumber,
    },
    title,
    contactClassification,
    onWiseSystemRole,
    assosiatedWith,
    permissionSetIds,
    permissionsSearchTerm,
    userStatus: isUserActive,
    applicationRole,
    restrictAccessToOwnAgents,
    isRestrictAccessToOwnAgentsFromRole,
  } = watch();

  const handleReset = useCallback(() => {
    reset();
    setUserStatus(defaultValues?.status === 1);
    window.scrollTo(0, 0);
  }, [reset, setUserStatus, defaultValues?.status]);

  const contactClassificationOptions = useMemo(() => {
    if (assosiatedWith?.value === 'Agent') {
      return agencyContactClassifications?.map((item) => ({
        label: item.roleName,
        value: item.roleId,
      }));
    }
    return providerContactClassifications?.map((item) => ({
      label: item.roleName,
      value: item.roleId,
    }));
  }, [agencyContactClassifications, assosiatedWith?.value, providerContactClassifications]);

  const applicationRoleOptions = useMemo(() => {
    const currentAssosiatedWith = assosiatedWith?.label === 'Agent' ? 'Agency' : 'Provider';
    const filteredRoles = availableRoles?.filter((role) => {
      return role.associatedWith === currentAssosiatedWith;
    });
    return filteredRoles?.map((role) => role.roleId) || null;
  }, [assosiatedWith?.label, availableRoles]);

  const providerAgentNameOptions = useMemo(() => {
    if (assosiatedWith?.value === 'Agent') {
      return agencies?.map((agent) => ({ label: agent.agencyName, value: agent }));
    }
    if (assosiatedWith?.value === 'Provider') {
      return [
        { label: providerValue, value: providerValue },
        { label: '', value: '' },
      ];
    }
  }, [agencies, assosiatedWith?.value, providerValue]);

  const filteredPermissionSetsBySearchTerm = useMemo(() => {
    const permissionBlocks: PermissionSet[] = [];

    permissionSets?.forEach((permissionBlock) => {
      if (permissionBlock.name.toLowerCase().indexOf(permissionsSearchTerm.toLowerCase()) !== -1) {
        permissionBlocks.push({ ...permissionBlock });
      }
    });

    return permissionBlocks;
  }, [permissionSets, permissionsSearchTerm]);

  const selectedPermissionsFromApplicationRole = useMemo(() => {
    const allPermissionSetIds: number[] = [];

    const selectedRoles =
      availableRoles?.filter((role) => applicationRole?.includes(role.roleId)) || [];

    selectedRoles?.forEach((role) => {
      allPermissionSetIds.push(...role.permissionSetIds);
    });

    return {
      permissionSets: [...new Set(allPermissionSetIds)],
    };
  }, [applicationRole, availableRoles]);

  const applicationRoleHandler = (option?: number) => {
    const currentRole = availableRoles?.find((role) => role.roleId === option);

    setValue(
      'permissionSetIds',
      permissionSetIds.filter((permission) => !currentRole?.permissionSetIds.includes(permission)),
    );
  };

  const onSubmitWrapper = (form: UserFormType) => {
    const formattedPayload: UserPayload = {
      applicationRoleIds: form.applicationRole || [],
      permissionSetIds: form.permissionSetIds,
      ...getPhonePayload(form.phone),
      email: form.email,
      lastName: form.lastName,
      firstName: form.firstName,
      title: form.title || '',
      contactClassification: form.contactClassification.map((item) => item.value) || [],
      orgTypeName: form.assosiatedWith?.value === 'Agent' ? 'Agency' : 'Provider',
      legalName: form.orgName?.label,
      orgId:
        form.assosiatedWith?.value === 'Agent'
          ? agencies?.find((agent) => agent.agencyName === form.orgName?.label)?.agencyId
          : user?.orgId,
      userId: defaultValues?.userId,
      status: form.userStatus ? 1 : 0,
      restrictAccessToOwnAgents:
        form.assosiatedWith?.value === 'Provider' ? form.restrictAccessToOwnAgents : false,
    };

    onSubmit(formattedPayload);
  };

  useEffect(() => {
    const selectedAppRoles = applicationRole?.map((item) => {
      return availableRoles?.find((role) => role.roleId === item);
    });
    let isRestrictedAccessToOwnAgentsFromSelectedRole = false;
    selectedAppRoles?.forEach((item) => {
      if (item && item.restrictAccessToOwnAgents) {
        isRestrictedAccessToOwnAgentsFromSelectedRole = true;
      }
    });
    if (isRestrictedAccessToOwnAgentsFromSelectedRole) {
      setValue(
        'isRestrictAccessToOwnAgentsFromRole',
        isRestrictedAccessToOwnAgentsFromSelectedRole,
      );
      setValue('restrictAccessToOwnAgents', isRestrictedAccessToOwnAgentsFromSelectedRole);
    }
  }, [applicationRole, availableRoles, setValue]);

  // merging two arrays into one to handle validation
  useEffect(() => {
    setValue(
      'fakePermissionArrayForPermissionsValidation',
      [
        ...(permissionSetIds || []),
        ...(selectedPermissionsFromApplicationRole.permissionSets || []),
      ],
      { shouldValidate: true },
    );
  }, [permissionSetIds, selectedPermissionsFromApplicationRole.permissionSets, setValue]);

  // INFO: checking for unsaved changes to disable action buttons
  useEffect(() => {
    if (defaultValuesFromForm && isEdit) {
      const initialValues: Omit<
        UserFormType,
        | 'fakePermissionArrayForPermissionsValidation'
        | 'isPhoneValid'
        | 'permissionsSearchTerm'
        | 'isRestrictAccessToOwnAgentsFromRole'
      > = {
        firstName: defaultValuesFromForm.firstName || '',
        lastName: defaultValuesFromForm.lastName || '',
        assosiatedWith: defaultValuesFromForm.assosiatedWith as SelectItem<string>,
        permissionSetIds: (defaultValuesFromForm.permissionSetIds as number[])?.sort(
          (a, b) => a - b,
        ),
        userStatus: !!defaultValuesFromForm.userStatus,
        email: defaultValuesFromForm.email || '',
        phone: {
          number: defaultValuesFromForm.phone?.number || '',
          countryCode: defaultValuesFromForm.phone?.countryCode || '',
          ext: defaultValuesFromForm.phone?.ext || '',
          isValid: !!defaultValuesFromForm.phone?.isValid,
        },
        title: defaultValuesFromForm.title || '',
        applicationRole: (defaultValuesFromForm.applicationRole as number[])?.sort((a, b) => a - b),
        contactClassification: (
          defaultValuesFromForm.contactClassification as SelectItem<number>[]
        )?.sort((a, b) => a?.value - b?.value),
        onWiseSystemRole: (defaultValuesFromForm.onWiseSystemRole as SelectItem[])?.sort((a, b) =>
          a.label.localeCompare(b.label),
        ),
        orgName: defaultValuesFromForm.orgName as SelectItem<string>,
        restrictAccessToOwnAgents: !!defaultValuesFromForm.restrictAccessToOwnAgents,
      };
      const currentValues: Omit<
        UserFormType,
        | 'fakePermissionArrayForPermissionsValidation'
        | 'permissionsSearchTerm'
        | 'isPhoneValid'
        | 'isRestrictAccessToOwnAgentsFromRole'
      > = {
        firstName: firstName,
        lastName: lastName,
        assosiatedWith: assosiatedWith,
        permissionSetIds: permissionSetIds?.sort((a, b) => a - b),
        userStatus: isUserActive,
        email: email,
        phone: {
          countryCode: currentCountryCode,
          ext,
          isValid: isPhoneValid,
          number: currentPhoneNumber,
        },
        title: title,
        applicationRole: applicationRole?.sort((a, b) => a - b),
        contactClassification: contactClassification?.sort((a, b) => a.value - b.value),
        onWiseSystemRole: onWiseSystemRole?.sort((a, b) => a.label.localeCompare(b.label)),
        orgName: orgName,
        restrictAccessToOwnAgents: restrictAccessToOwnAgents,
      };

      const isValuesEqual = isEqual(initialValues, currentValues);

      setIsUnsavedData(!isValuesEqual);
      setIsSaveEnabled(isValid ? isValuesEqual === isValid : true);
    }
  }, [
    applicationRole,
    assosiatedWith,
    contactClassification,
    currentCountryCode,
    defaultValuesFromForm,
    email,
    ext,
    firstName,
    isEdit,
    isPhoneValid,
    isUserActive,
    isValid,
    lastName,
    currentPhoneNumber,
    onWiseSystemRole,
    orgName,
    permissionSetIds,
    restrictAccessToOwnAgents,
    setIsSaveEnabled,
    setIsUnsavedData,
    title,
    userStatus,
  ]);

  useEffect(() => {
    setValue('userStatus', userStatus, { shouldValidate: true });
  }, [userStatus, setValue]);
  useEffect(() => {
    const agentByOrgId = agencies?.find((agent) => agent.agencyId === defaultValues?.orgId);
    const orgNameValue =
      assosiatedWith?.label === 'Provider'
        ? { label: providerValue, value: providerValue }
        : { label: agentByOrgId?.agencyName || '', value: agentByOrgId?.agencyName || '' };
    setValue('orgName', orgNameValue, { shouldValidate: true });
  }, [agencies, assosiatedWith, defaultValues?.orgId, providerValue, setValue]);

  // storing duplicate value from form to refetch applicationRole dropdown options
  useEffect(() => {
    setAssosiatedWithDuplicateValue(assosiatedWith);
  }, [assosiatedWith]);

  const onDiscardChanges = useCallback(() => {
    if (isAddNewUser) {
      setCancelPopup(false);
      if (isAddNewUserFromAgentContact) {
        navigate(location.state?.returnUrl);
        return;
      }
      navigate({
        pathname: `/dashboard/User`,
      });
    } else {
      setIsEdit(false);
      setCancelPopup(false);
      formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
    }
  }, [
    formRef,
    isAddNewUser,
    isAddNewUserFromAgentContact,
    location.state?.returnUrl,
    navigate,
    setCancelPopup,
    setIsEdit,
  ]);

  const onSaveChanges = useCallback(() => {
    setCancelPopup(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [formRef, setCancelPopup]);

  const onExit = useCallback(() => {
    setCancelPopup(false);
  }, [setCancelPopup]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitWrapper)} onReset={handleReset} ref={formRef}>
        <Grid2 container spacing={isSmall ? 1 : 2} mt={1}>
          <Grid2 size={{ xs: 4 }}>
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('formField.firstName')}
                  disabled={!isEdit || isAddNewUserFromAgentContact}
                  required={isEdit}
                  error={!!errors.firstName?.message}
                  errorMessage={errors.firstName?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('formField.lastName')}
                  disabled={!isEdit || isAddNewUserFromAgentContact}
                  required={isEdit}
                  error={!!errors.lastName?.message}
                  errorMessage={errors.lastName?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('formField.email')}
                  // disabled on view/edit, enabled on ADD only
                  disabled={!location.pathname.includes('/Add') || isAddNewUserFromAgentContact}
                  required={isEdit || isAddNewUserFromAgentContact}
                  error={!!errors.email?.message}
                  errorMessage={errors.email?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <AppAutocompleteSingleSelect
              control={control as unknown as Control<FieldValues>}
              name={'assosiatedWith'}
              label={t('formField.associatedWith')}
              options={[
                { label: 'Agent', value: 'Agent' },
                { label: 'Provider', value: 'Provider' },
              ]}
              // disabled on view/edit, enabled on ADD only
              disabled={
                !location.pathname.includes('/Add') ||
                !isCreateAgentUserPermission ||
                !isCreateProviderUserPermission ||
                isAddNewUserFromAgentContact
              }
              disableClearable={true}
              rules={{ required: isEdit }}
              errorMessage={errors.assosiatedWith?.message}
            />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <AppAutocompleteSingleSelect
              control={control as unknown as Control<FieldValues>}
              name={'orgName'}
              label={
                assosiatedWith?.value === 'Agent'
                  ? t('formField.agentName')
                  : t('formField.providerName')
              }
              options={providerAgentNameOptions || []}
              // disabled on view/edit, enabled on ADD only
              disabled={
                !location.pathname.includes('/Add') ||
                assosiatedWith?.label === 'Provider' ||
                isAddNewUserFromAgentContact
              }
              rules={{
                required: assosiatedWith?.value === 'Agent' && location.pathname.includes('/Add'),
              }}
              errorMessage={errors.orgName?.message}
            />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            {assosiatedWith?.value === 'Provider' && (
              <FormGroup>
                <Controller
                  name="restrictAccessToOwnAgents"
                  control={control}
                  render={({ field }) => (
                    <CheckboxMain
                      label={'Restrict Access to Own Agents'}
                      disabled={!isEdit || isRestrictAccessToOwnAgentsFromRole}
                      control={
                        <Checkbox
                          color="success"
                          value={restrictAccessToOwnAgents}
                          checked={!!restrictAccessToOwnAgents}
                          onChange={(event, checked) => {
                            field.onChange(checked);
                          }}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>
            )}
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <AppPhoneField required={false} disabled={!isEdit || isAddNewUserFromAgentContact} />
          </Grid2>
          <Grid2 size={{ xs: 8 }}></Grid2>
          <Grid2 size={{ xs: 4 }}>
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={'Title'}
                  disabled={!isEdit || isAddNewUserFromAgentContact}
                  error={!!errors.title?.message}
                  errorMessage={errors.title?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <Controller
              control={control}
              name={'contactClassification'}
              render={({ field }) => (
                <AppAutocomplete
                  {...field}
                  data-cy={`select-contactClassification`}
                  disablePortal
                  value={field.value || []}
                  options={contactClassificationOptions || []}
                  disabled={!isEdit || isAddNewUserFromAgentContact}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <AppTextField {...params} label={t('formField.contactClassification')} />
                  )}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  multiple
                  renderOption={(optionProps, option, { selected }) => {
                    return (
                      <AppListItem
                        {...optionProps}
                        key={option.value}
                        data-cy-option={'select-option'}
                        data-cy={`select-option-${option.label}`}
                      >
                        <Checkbox style={{ marginRight: 8 }} color="success" checked={selected} />
                        <span dangerouslySetInnerHTML={{ __html: option?.label || '-' }}></span>
                      </AppListItem>
                    );
                  }}
                  renderTags={(data) => (
                    <TypographySpan>
                      {t('multiple.select.label', {
                        selectedCount: data.length,
                        allCount: contactClassificationOptions?.length || 0,
                      })}
                    </TypographySpan>
                  )}
                />
              )}
            />
          </Grid2>

          <Grid2 size={{ xs: 4 }}>
            {/* OnWiseSystem Role Field temporary removed from UI */}
            {/* <Controller
            control={control}
            name={'onWiseSystemRole'}
            render={({ field }) => (
              <AppAutocompleteMultiSelect
                {...field}
                options={onWiseSystemRolesOptions || []}
                disabled
                isOptionEqualToValue={(option, value) =>
                  option.value?.roleId === value.value.roleId
                }
                renderInput={(params) => (
                  <AppTextField
                    {...params}
                    label={t('formField.onwiseSystemRole')}
                  />
                )}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
              />
            )}
          /> */}
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <Controller
              control={control}
              name={'applicationRole'}
              render={({ field }) => (
                <AppAutocomplete
                  {...field}
                  data-cy={`select-applicationRole`}
                  disablePortal
                  value={field.value || []}
                  options={applicationRoleOptions || []}
                  disabled={!isEdit}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <AppTextField {...params} label={t('formField.applicationRole')} />
                  )}
                  onChange={(e, value, reason, details) => {
                    if (reason === 'removeOption' || reason === 'selectOption') {
                      applicationRoleHandler(details?.option);
                    }
                    field.onChange(value);
                  }}
                  multiple
                  disableCloseOnSelect
                  renderOption={(optionProps, option, { selected }) => {
                    const foundRole = availableRoles?.find((role) => role.roleId === option);
                    const htmlLayout = `${foundRole?.roleName}<span class="vl"></span>${
                      foundRole?.isActive ? t('status.active') : t('status.inactive')
                    }`;
                    return (
                      <AppListItem
                        {...optionProps}
                        key={option}
                        data-cy={`select-option-${foundRole?.roleName}`}
                      >
                        <Checkbox style={{ marginRight: 8 }} color="success" checked={selected} />
                        <span dangerouslySetInnerHTML={{ __html: htmlLayout }}></span>
                      </AppListItem>
                    );
                  }}
                  filterOptions={(_options, state) => {
                    const filtered = availableRoles
                      ?.filter((role) => _options.includes(role.roleId))
                      .filter((role) =>
                        role.roleName.toLowerCase().includes(state.inputValue.toLowerCase()),
                      )
                      .map((role) => role.roleId);
                    return filtered || [];
                  }}
                  renderTags={(data) => (
                    <TypographySpan>
                      {t('multiple.select.label', {
                        selectedCount: data.length,
                        allCount: applicationRoleOptions?.length || 0,
                      })}
                    </TypographySpan>
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <CenterCenterBox sx={{ maxWidth: '500px' }}>
              <BlockTitle>
                {t('formField.permissions')}
                {isEdit && <AsterikSpan>*</AsterikSpan>}
              </BlockTitle>
              <Controller
                control={control}
                name="permissionsSearchTerm"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    label={'Permission Name'}
                    InputProps={{ endAdornment: <SearchIcon /> }}
                  />
                )}
              />
            </CenterCenterBox>
          </Grid2>
          {isEdit && (
            <Grid2 size={{ xs: 12 }}>
              <FeinBox>{errors.fakePermissionArrayForPermissionsValidation?.message}</FeinBox>
            </Grid2>
          )}
          <Grid2 size={{ xs: 12 }}>
            <Grid2 container>
              <PermissionSets
                disabledPermissionSets={
                  permissionSetIds.includes(signerPermissionSetId)
                    ? [
                        ...selectedPermissionsFromApplicationRole.permissionSets,
                        approveAgentSellerRelationshipPermissionSetId,
                        attachAgreementPermissionSetId,
                        deleteAgreementAttachmentPermissionSetId,
                      ]
                    : [...selectedPermissionsFromApplicationRole.permissionSets]
                }
                disabled={!isEdit}
                permissionSets={filteredPermissionSetsBySearchTerm || []}
                control={control as unknown as Control<{ permissionSetIds: number[] }>}
              />
            </Grid2>
          </Grid2>
        </Grid2>
        <CancelModal
          isOpened={cancelPopup}
          modalTitle={t('security.roles.roleForm.role.warning')}
          onDiscardChanges={onDiscardChanges}
          onSaveChanges={onSaveChanges}
          onExit={onExit}
        />
      </form>
    </FormProvider>
  );
};
