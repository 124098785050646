import { Box, Grid2, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { AlignCenterBox } from '../../../components/shared/FlexBox';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TermsOfUseService } from '../../../services/TermsOfUse';
import { useToast } from '../../../hooks/useToast';
import { TermsOfUseHeading } from './styles';
import AppTooltip from '../../../components/shared/AppTooltip';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import AddDocumentIcon from '../../../components/assets/AddDocumentIcon';
import DownloadIcon from '../../../components/assets/DownloadIcon';
import RemoveDocumentIcon from '../../../components/assets/RemoveDocumentIcon';
import { ModificationLog } from '../../../components/ModificationLog';
import { MainGreen, MainRed, White } from '../../../Theme/colorsVariables';

export const TermsOfUse = () => {
  const { t } = useTranslation();
  const isReadPermission = usePermissions(PermissionKeys.ReadConfiguration);
  const isUpdatePermission = usePermissions(PermissionKeys.ModifyConfiguration);
  const isDeletePermission = usePermissions(PermissionKeys.DeleteConfiguration);
  const navigate = useNavigate();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const toast = useToast();

  const [displayProviderTerms, setDisplayProviderTerms] = useState(false);
  const [displayAgentTerms, setDisplayAgentTerms] = useState(false);

  const { data: termsOfUse, refetch } = TermsOfUseService.useAll({
    enabled: isReadPermission,
  });

  const { mutate: downloadTermsOfUseById } = TermsOfUseService.useDownload({
    onSuccess(data, { termsId }) {
      try {
        // Create a Blob object with the HTML content
        const blob = new Blob([data], { type: 'text/html' });

        // Create a temporary link element
        const link = document.createElement('a');

        // Set the href attribute to the URL of the Blob
        link.href = URL.createObjectURL(blob);

        // Set the download attribute to the desired filename
        link.download = `Terms of Use for ${termsId === 1 ? 'Provider' : 'Agent'}.html`;

        // Dispatch a click event on the link to trigger the download
        link.click();
        toast.success('File downloaded successfully.');
      } catch (err) {
        toast.error('An error occured while downloading.');
      }
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });
  const { mutate: updateTermsOfUseById } = TermsOfUseService.useUpdate({
    onSuccess: () => {
      toast.success('Terms of Use updated.');
      refetch();
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });
  const { mutate: uploadTermsOfUseById } = TermsOfUseService.useUpload({
    onSuccess: () => {
      toast.success('Terms of Use added.');
      refetch();
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });
  const { mutate: deleteTermsOfUseById } = TermsOfUseService.useDelete({
    onSuccess: () => {
      toast.success('Terms of Use deleted.');
      refetch();
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });

  const providerTermsObject = useMemo(() => {
    return termsOfUse?.find((item) => item.associatedWith.name === 'Provider');
  }, [termsOfUse]);
  const agentTermsObject = useMemo(() => {
    return termsOfUse?.find((item) => item.associatedWith.name === 'Agency');
  }, [termsOfUse]);

  const setInputRef = (index: number) => (element: HTMLInputElement | null) => {
    if (element) {
      inputRefs.current[index] = element;
    }
  };

  const handleUploadClick = (index: number) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].click();
    }
  };

  function handleFileChange(event: ChangeEvent<HTMLInputElement>, termsId: number) {
    if (event.target.files) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);
      uploadTermsOfUseById({ termsId, formData });
    }
  }

  useEffect(() => {
    setDisplayProviderTerms(!!providerTermsObject?.isDisplayed);
    setDisplayAgentTerms(!!agentTermsObject?.isDisplayed);
  }, [agentTermsObject?.isDisplayed, providerTermsObject?.isDisplayed]);

  useEffect(() => {
    if (!isReadPermission) {
      navigate('/NotFound', { state: { error: t('restrictions.noPermissions') } });
    }
  }, [isReadPermission, navigate, t]);

  return (
    <>
      <PageHeaderContentHost name="Terms Of Use" />
      <Grid2 container spacing={2} mt={2}>
        {termsOfUse?.map((item, index) => (
          <Grid2 size={{ xs: 12 }} key={item.id}>
            <Box>
              <TermsOfUseHeading mr={2}>
                {`Display Terms of Use for ${
                  item.associatedWith.name === 'Agency' ? 'Agent' : item.associatedWith.name
                } users on first login`}
              </TermsOfUseHeading>
              <AlignCenterBox>
                <ToggleButtonGroup
                  disabled={!isUpdatePermission || !item.contentUrl}
                  value={
                    item.associatedWith.name === 'Agency'
                      ? `${displayAgentTerms}`
                      : `${displayProviderTerms}`
                  }
                  exclusive
                  onChange={(e, value) => {
                    const checked = value === 'true';
                    if (item.associatedWith.name === 'Agency') {
                      setDisplayAgentTerms(checked);
                    } else {
                      setDisplayProviderTerms(checked);
                    }
                    updateTermsOfUseById({ termsId: item.id, isDisplaying: checked });
                  }}
                >
                  <ToggleButton
                    value="true"
                    sx={{
                      '&.Mui-selected': {
                        color: White,
                        backgroundColor: MainGreen,
                      },
                    }}
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    value="false"
                    sx={{
                      '&.Mui-selected': {
                        color: White,
                        backgroundColor: MainRed,
                      },
                    }}
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
                <AlignCenterBox>
                  {!item.contentUrl && isUpdatePermission && (
                    <Box ml={1}>
                      <AppTooltip title={'Add Terms Of Use'}>
                        <Box onClick={() => handleUploadClick(index)} sx={{ cursor: 'pointer' }}>
                          <AddDocumentIcon />
                        </Box>
                      </AppTooltip>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        onChange={(e) => handleFileChange(e, item.id)}
                        ref={setInputRef(index)}
                        accept="text/html"
                      />
                    </Box>
                  )}
                  {!!item.contentUrl && isReadPermission && (
                    <AppTooltip title={'Download Terms Of Use'}>
                      <Box
                        mx={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => downloadTermsOfUseById({ termsId: item.id })}
                      >
                        <DownloadIcon />
                      </Box>
                    </AppTooltip>
                  )}
                  {!!item.contentUrl && isDeletePermission && (
                    <AppTooltip title={'Delete Terms Of Use'}>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => deleteTermsOfUseById({ termsId: item.id })}
                      >
                        <RemoveDocumentIcon />
                      </Box>
                    </AppTooltip>
                  )}
                </AlignCenterBox>
              </AlignCenterBox>
              <ModificationLog
                {...{
                  createdOn: item.createdOn,
                  createdBy: item.createdBy,
                  updatedOn: item.updatedOn,
                  updatedBy: item.updatedBy,
                }}
              />
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};
