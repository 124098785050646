import React, { useState, useContext } from 'react';
import { Link, InputAdornment, IconButton } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm, SubmitHandler } from 'react-hook-form';
import AuthService from '../../services/authService';
import {
  PasswordHeading,
  FilterLabel,
  FormControlS,
  OutlinedInputS,
  StyledBox,
  CancelBox,
} from './styles';
import { FormValues, PasswordChangePayload, PasswordTypes } from './types';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../hooks/useToast';
import { AppActionButton } from '../../components/shared/AppActionButton';
import { FeinBox } from '../../components/common/styles/feinBox';
import { UserContext } from '../../context/User';
import { ApiError } from '../../types';
import { TabsComunicationChannel } from '../../providers';

export default function ChangePassword() {
  const toast = useToast();
  const { logout } = useAuth0();
  const user = useContext(UserContext);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const [showPassword, setShowPassword] = useState<PasswordTypes>({
    password: false,
    confirmPassword: false,
  });

  const { t } = useTranslation();

  const onPasswordChangeSuccess = () => {
    toast.success(t('toast.single-user.reset.success'));
    //TODO: investigate why this is needed???
    reset();
    setTimeout(() => {
      TabsComunicationChannel.postMessage('logout');
      logout();
    }, 1000);
  };

  //TODO: investigate if message valid
  const onPasswordChangeError = (err: ApiError) => {
    toast.error(err.response.data.message);
  };

  const { mutate: onChangePasswordCall } = AuthService.useChangePasswordQuery(
    onPasswordChangeSuccess,
    onPasswordChangeError,
  );

  const handleClickShowPassword = (type: string) => {
    if (type === 'new password') {
      setShowPassword({ ...showPassword, password: !showPassword.password });
    }
    if (type === 'confirm password') {
      setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword });
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const dataToPost: PasswordChangePayload = {
      password: data.password,
      confirmPassword: data.confirmPassword,
      userId: user?.userId || 0,
    };
    onChangePasswordCall(dataToPost);
  };

  return (
    <>
      <PasswordHeading mb={4}>{t('formField.changePassword')}</PasswordHeading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledBox>
          <FilterLabel>{t('formField.newPassword')}</FilterLabel>
          <FormControlS variant="outlined">
            <OutlinedInputS
              sx={!showPassword.password ? { '>input': { '-webkit-text-security': 'disc' } } : {}}
              type={'text'}
              label={''}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword('new password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              {...register('password', {
                required: 'Password is required.',
                minLength: {
                  value: 8,
                  message: 'Password must have atleast 8 characters.',
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                  message:
                    'Password must have atleast one uppercase character, one lower case character, one number and one special symbol.',
                },
              })}
              placeholder="New Password"
            />
            {errors?.password && (
              <FeinBox variant="body2" mt={1}>
                {errors.password?.message}
              </FeinBox>
            )}
          </FormControlS>
        </StyledBox>
        <StyledBox>
          <FilterLabel>{t('formField.confirmNewPassword')}</FilterLabel>
          <FormControlS variant="outlined">
            <OutlinedInputS
              sx={
                !showPassword.confirmPassword
                  ? { '>input': { '-webkit-text-security': 'disc' } }
                  : {}
              }
              type={'text'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword('confirm password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Confirm New Password"
              {...register('confirmPassword', {
                required: 'Confirm Password is required.',
                minLength: {
                  value: 8,
                  message: 'Confirm Password must have atleast 8 characters.',
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                  message:
                    'Confirm Password must have atleast one uppercase character, one lower case character, one number and one special symbol.',
                },
                validate: (value) =>
                  watch('password') === value ||
                  'New Password and Confirm new Password do no match',
              })}
            />
            {errors?.confirmPassword && (
              <FeinBox variant="body2" mt={1}>
                {errors.confirmPassword?.message}
              </FeinBox>
            )}
          </FormControlS>
        </StyledBox>

        <CancelBox>
          <Link href="/dashboard" underline="none">
            <AppActionButton variant="outlined" size="large">
              {t('action.cancel')}
            </AppActionButton>
          </Link>

          <AppActionButton variant="outlined" size="large" type="submit">
            {t('formField.changePassword')}
          </AppActionButton>
        </CancelBox>
      </form>
    </>
  );
}
