import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalButtonsBox } from '../Modal/styles';
import { QuickDecisionModalContext } from '../../../context/QuickDecisionModal';
import { ModalMessageText } from '../CancelModal/styles';

export default function QuickDecisionModal() {
  const { t } = useTranslation();
  const { quickDecisionModal } = useContext(QuickDecisionModalContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(quickDecisionModal.isOpen);
  }, [quickDecisionModal]);

  return (
    <CustomModal open={isOpen} containerWidth="600px" modalTitle={quickDecisionModal.modalTitle}>
      <>
        <ModalMessageText
          sx={{ mt: 2, textAlign: 'center' }}
          dangerouslySetInnerHTML={{ __html: quickDecisionModal.message }}
        />
        <ModalButtonsBox>
          <AppActionButton
            variant="outlined"
            onClick={() => {
              setIsOpen(false);
              if (quickDecisionModal.yesCallBack) quickDecisionModal.yesCallBack();
            }}
          >
            {t('action.yes')}
          </AppActionButton>
          <AppActionButton
            variant="outlined"
            onClick={() => {
              setIsOpen(false);
              if (quickDecisionModal.noCallBack) quickDecisionModal.noCallBack();
            }}
          >
            {t('action.no')}
          </AppActionButton>
        </ModalButtonsBox>
      </>
    </CustomModal>
  );
}
