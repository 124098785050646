import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppActionButton } from '../../components/shared/AppActionButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { FlexBox } from '../../components/shared/FlexBox';
import { StickyContainer } from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { BoxStepper, Label, MainStepper, MainSteps } from '../AgencyInfo/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { useAgentById } from '../../services/agentService';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';
import { UnsavedDataContext } from '../../context/UnsavedData';
import { useOrganizationContacts } from '../../services/sellerGroupContactsService';
import AppTooltip from '../../components/shared/AppTooltip';

enum AgentSteps {
  BusinessInfo = 0,
  Contacts = 1,
}

const ViewAgent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);
  const isAddSellerPermission = usePermissions(PermissionKeys.AddSellers);
  const isCreateContactPermission = usePermissions(PermissionKeys.CreateContact);

  const { data: agentInfo } = useAgentById(Number(id));

  const [contactsCount, setContactsCount] = useState(0);

  const { data: agentContacts } = useOrganizationContacts({
    pageNumber: 1,
    pageSize: 10,
    orgId: Number(id),
  });

  const steps = t('agent.steps', { returnObjects: true }) as string[];

  const [activeStep, setActiveStep] = useState(AgentSteps.BusinessInfo);

  const navigateSwitcher: Record<AgentSteps, () => void> = {
    0: () => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/Agent/${id}`,
        });
      } else {
        setActiveStep(AgentSteps.BusinessInfo);
        navigate(`/dashboard/Agent/${id}`);
      }
    },
    1: () => {
      if (unsavedData.isUnsavedChanges) {
        setUnsavedData({
          ...unsavedData,
          isPopupOpen: true,
          returnUrl: `/dashboard/Agent/${id}/Contact`,
        });
      } else {
        setActiveStep(AgentSteps.Contacts);

        navigate(`/dashboard/Agent/${id}/Contact`);
      }
    },
  };

  useEffect(() => {
    if (location.pathname.includes('/Contact')) {
      setActiveStep(AgentSteps.Contacts);
    } else {
      setActiveStep(AgentSteps.BusinessInfo);
    }
  }, [location]);

  useEffect(() => {
    setContactsCount(agentContacts?.totalCount || 0);
  }, [agentContacts?.totalCount]);

  return (
    <>
      <StickyContainer mb={1}>
        <PageHeaderContainer>
          <PageHeaderContentHost title="Agent" name={agentInfo?.orgName} />
          <AppTooltip title={!isAddSellerPermission && t('restrictions.noPermissions')}>
            <span>
              <AppActionButton
                variant="outlined"
                startIcon={<AddCircleOutlineRoundedIcon />}
                disabled={!isAddSellerPermission}
                onClick={() => {
                  navigate(`/dashboard/Seller/Add?agencyId=${Number(id)}`);
                }}
              >
                {t('relationship.sellers.addsellers.linearstepper.add_seller')}
              </AppActionButton>
            </span>
          </AppTooltip>
        </PageHeaderContainer>
        <BoxStepper mt={1}>
          <MainStepper connector={null}>
            {steps.map((step, index) => (
              <MainSteps
                props={[activeStep, index]}
                onClick={() => {
                  navigateSwitcher[index as unknown as AgentSteps]();
                }}
                key={(Math.random() + 1).toString(36).substring(7)}
                id={`tab-${step}`}
              >
                <Label isactive={activeStep === index ? 'true' : ''}>
                  {step === 'Contacts' ? `Contacts (${contactsCount})` : step}
                </Label>
              </MainSteps>
            ))}
          </MainStepper>
        </BoxStepper>
      </StickyContainer>

      <FlexBox sx={{ justifyContent: 'end', width: '100%' }}>
        {activeStep === 1 && (
          <AppActionButton
            id={'add-contact'}
            variant="outlined"
            disabled={!isCreateContactPermission}
            sx={{ maxHeight: '34px', minWidth: '100px !important' }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              navigate(`/dashboard/Agent/${id}/AddContact`);
            }}
          >
            {t('action.add')}
          </AppActionButton>
        )}
      </FlexBox>
      <Outlet context={{ setContactsCount, contactsCount, agentName: agentInfo?.orgName }} />
    </>
  );
};

export const ProtectedAgentPage = () => {
  const { t } = useTranslation();
  const isViewAgentPermission = usePermissions(PermissionKeys.ReadAgent);

  if (!isViewAgentPermission) {
    return <NoPermissionMessage message={t('relationships.organisation.view.permission')} />;
  }
  return <ViewAgent />;
};
