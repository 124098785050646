import axios from 'axios';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { ApiError, ApplicationResponse, Pagination } from '../types';
import {
  OrgDuplicate,
  OrgDuplicatePayload,
  OrganizationPayloadExtended,
  Tenant,
} from '../types/organization';
import { Dispatch, SetStateAction } from 'react';
import { useHandleOrganizationErrors } from '../utils/handleOrganizationErrors';

export const OrganizationService = {
  useCreateSellerGroup: ({
    setCustomFieldError,
    onSuccess,
  }: {
    setCustomFieldError: Dispatch<SetStateAction<string>>;
    onSuccess?:
      | ((
          data: number,
          variables: OrganizationPayloadExtended,
          context: unknown,
        ) => void | Promise<unknown>)
      | undefined;
  }) => {
    const [handleOrgError] = useHandleOrganizationErrors();

    const createOrganization = async ({ payload }: OrganizationPayloadExtended) => {
      const res = await axios.post<number>('/SellerGroup', payload);
      return res.data;
    };
    return useMutation<number, ApiError, OrganizationPayloadExtended>(
      'createOrganization',
      createOrganization,
      {
        onSuccess,
        onError(error) {
          handleOrgError({
            error,
            setCustomFieldError,
          });
        },
      },
    );
  },
  useCreateSeller: ({
    onSuccess,
    setCustomFieldError,
  }: {
    onSuccess?:
      | ((
          data: number,
          variables: OrganizationPayloadExtended,
          context: unknown,
        ) => void | Promise<unknown>)
      | undefined;
    setCustomFieldError?: Dispatch<SetStateAction<string>>; // for error handling
  }) => {
    const [handleOrgError] = useHandleOrganizationErrors();
    const createSeller = async ({ payload }: OrganizationPayloadExtended) => {
      const res = await axios.post('/Sellers', payload);
      return res.data;
    };
    return useMutation<number, ApiError, OrganizationPayloadExtended>(
      'createSeller',
      createSeller,
      {
        onSuccess,
        onError(error) {
          handleOrgError({
            error,
            setCustomFieldError,
          });
        },
      },
    );
  },
  useNewRelationshipsForDuplicates: ({ orgId, payload, options }: OrgDuplicatePayload) => {
    const req = async () => {
      const res = await axios.post(`/organization/${orgId}/new-relationships`, payload);
      return res.data;
    };
    return useQuery<Pagination<OrgDuplicate & { agreementId?: number }>, ApiError>(
      ['new-relationships-duplicates', orgId],
      req,
      options,
    );
  },
  useExistingRelationshipsForDuplicates: ({ orgId, payload, options }: OrgDuplicatePayload) => {
    const req = async () => {
      const res = await axios.post(`/organization/${orgId}/existing-relationships`, payload);
      return res.data;
    };
    return useQuery<Pagination<OrgDuplicate>, ApiError>(
      ['existing-relationships-duplicates', orgId],
      req,
      options,
    );
  },
  useTenant: (options?: Omit<UseQueryOptions<Tenant, ApiError>, 'queryKey' | 'queryFn'>) => {
    const req = async () => {
      const res = await axios.get(`/organization/tenant`);
      return res.data;
    };
    return useQuery<Tenant, ApiError>('useOrgTenant', req, {
      staleTime: Infinity,
      ...options,
    });
  },
  useTenantSigners: () => {
    const req = async () => {
      const res = await axios.get('/Organization/tenant/signers');
      return res.data;
    };
    return useQuery<ApplicationResponse<Array<{ id: number; name: string }>>, ApiError>(
      ['tenantSigners'],
      req,
      {
        select(data) {
          return { ...data, data: data.data.sort((a, b) => a.name.localeCompare(b.name)) };
        },
      },
    );
  },
};
