import axios from 'axios';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { Pagination, ApiError, ApplicationResponse, DocumentType } from '../types';
import { Agreement } from '../types/sellerGroup';
import { DownloadAttachmentPayload, ReviewPossiblePayload } from '../types/agreements';
import { useToast } from '../hooks/useToast';
import { downloadBlobToPdf } from '../utils/downloadBase64ToPdf';
import { Organizations } from './sellerGroupServices';

export interface MAGAgreementsPayload {
  orgId: number | string;
  payload: {
    pageNumber: number;
    pageSize: number;
    sortColumn?: string;
    sortDirection?: string;
    shouldMatchAllCriteria: boolean;
  };
  options?: Omit<UseQueryOptions<Pagination<Agreement>, ApiError>, 'queryKey' | 'queryFn'>;
}

export const AgreementsService = {
  useExistingAgreements: ({ orgId, payload, options }: MAGAgreementsPayload) => {
    const getAgreements = async () => {
      const res = await axios.post(`Agreement/${orgId}/existing-relationships`, payload);
      return res.data;
    };
    return useQuery<Pagination<Agreement>, ApiError>(
      ['useExistingAgreements', payload, orgId],
      getAgreements,
      options,
    );
  },
  useNewRelationshipRequests: ({ orgId, payload, options }: MAGAgreementsPayload) => {
    const getAgreements = async () => {
      const res = await axios.post(`Agreement/${orgId}/new-relationships`, payload);
      return res.data;
    };
    return useQuery<Pagination<Agreement>, ApiError>(
      ['useNewRelationshipRequests', payload, orgId],
      getAgreements,
      options,
    );
  },
  useApprove: (
    options?: Omit<
      UseMutationOptions<
        string,
        ApiError,
        {
          agreementId: number;
        }
      >,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const approve = async ({ agreementId }: { agreementId: number }) => {
      const res = await axios.post(`Agreement/${agreementId}/approve`);
      return res.data;
    };
    return useMutation<string, ApiError, { agreementId: number }>('useApprove', approve, options);
  },
  useReject: (
    options?: Omit<
      UseMutationOptions<
        string,
        ApiError,
        {
          agreementId: number;
          rejectReason: string;
        }
      >,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const reject = async (payload: { agreementId: number; rejectReason: string }) => {
      const res = await axios.post(`Agreement/${payload.agreementId}/reject`, {
        reason: payload.rejectReason,
      });
      return res.data;
    };
    return useMutation<string, ApiError, { agreementId: number; rejectReason: string }>(
      'useReject',
      reject,
      options,
    );
  },
  useIsReviewPossible: (
    options?: Omit<
      UseMutationOptions<boolean, ApiError, ReviewPossiblePayload>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const req = async ({ orgId, reviewType }: ReviewPossiblePayload) => {
      const res = await axios.get(`Agreement/${orgId}/review-possible`, { params: { reviewType } });
      return res.data;
    };

    return useMutation<boolean, ApiError, ReviewPossiblePayload>('isReviewPossible', req, options);
  },
  useAttachAgreement: (
    options?: Omit<
      UseMutationOptions<ApplicationResponse, ApiError, FormData>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const req = async (payload: FormData) => {
      const res = await axios.post(`/Agreement/attach-agreement`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data;
    };

    return useMutation<ApplicationResponse, ApiError, FormData>(['attachAgreement'], req, options);
  },
  useDownloadAgreementAttachment: (
    options?: Omit<
      UseMutationOptions<Blob, ApiError, DownloadAttachmentPayload>,
      'mutationKey' | 'mutationFn'
    >,
  ) => {
    const toast = useToast();
    const downloadAgreement = async ({ agreementId }: DownloadAttachmentPayload) => {
      const res = await axios.get(`/Agreement/${agreementId}/download`, {
        responseType: 'blob', // Ensures response is handled as a Blob
        headers: {
          'Content-Type': 'application/pdf',
        },
      });
      return res.data;
    };
    return useMutation<Blob, ApiError, DownloadAttachmentPayload>(
      'useDownloadAgreementAttachment',
      downloadAgreement,
      {
        onSuccess(data, variables) {
          downloadBlobToPdf(data, variables.fileName);
        },
        onError(error) {
          toast.error(error.response.data.message);
        },
        ...options,
      },
    );
  },
  useDeleteAttachedAgreement: (organization: Organizations) => {
    const toast = useToast();
    const queryClient = useQueryClient();
    const req = async ({ agreementId }: { agreementId: number }) => {
      const res = await axios.delete<ApplicationResponse<undefined>>(`/Agreement/${agreementId}`);
      return res.data;
    };
    return useMutation<ApplicationResponse<undefined>, ApiError, { agreementId: number }>(
      ['useDeleteAttachedAgreement'],
      req,
      {
        onSuccess(data) {
          toast.success(data.message);
          if (organization === Organizations.Seller) {
            queryClient.removeQueries('useSellerAvailableProductLines');
            queryClient.removeQueries('useSellerUnavailableProductLines');
            queryClient.removeQueries('useSellerAssociatedProductLines');
            queryClient.removeQueries('useSellerAgreements');
          }
          if (organization === Organizations.SellerGroup) {
            queryClient.removeQueries('useSellerGroupAvailableProductLines');
            queryClient.removeQueries('useSellerGroupUnavailableProductLinesByOrgId');
            queryClient.removeQueries('useSellerGroupAssociatedProductLines');
            queryClient.removeQueries('useSGAgreements');
          }
        },
        onError(data) {
          toast.success(data.response.data.message);
        },
      },
    );
  },
  useAgreementTypeByAgentAndOrgId: ({ agentId, orgId }: { agentId: number; orgId: number }) => {
    const req = async () => {
      const res = await axios.get(`/Agreement/${orgId}/document-type/${agentId}`);
      return res.data;
    };
    return useQuery<ApplicationResponse<DocumentType>, ApiError>(
      ['useAgreementTypeByAgentId', agentId, orgId],
      req,
      { enabled: !!agentId },
    );
  },
};
