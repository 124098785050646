import React, { useMemo } from 'react';
import {
  createFilterOptions,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Autocomplete,
} from '@mui/material';
import { CheckBoxStyle } from '../CustomTableFilters/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DropdownItemStyled, StyledList, TypographySpan, StyledAdvanced } from './styles';
import { useTranslation } from 'react-i18next';
import { Platinum, White } from '../../../Theme/colorsVariables';
import { AppTextField } from '../AppTextField';
interface Props<
  T extends {
    stateName: string;
    stateCode: string;
  },
> {
  disabled: boolean;
  isFilter?: boolean;
  originalData: T[];
  selectedData: T[];
  handleChangeSelected: (data: T[]) => void;
  check?: boolean;
}

const StatesMultipleSelect = <T extends { stateName: string; stateCode: string }>({
  disabled,
  isFilter,
  originalData,
  selectedData,
  handleChangeSelected,
  check,
}: Props<T>) => {
  const { t } = useTranslation();
  const allSelected = useMemo(
    () => originalData.length === selectedData.length,
    [selectedData, originalData],
  );

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      handleChangeSelected(originalData);
    } else {
      handleChangeSelected([]);
    }
  };

  const handleAvailableChange = (
    data: T[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<T> | undefined,
  ) => {
    if (
      selectedData.find((option) => option.stateCode === 'all') &&
      details?.option.stateCode === 'all'
    ) {
      handleChangeSelected([]);
      return;
    }
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (data.find((option) => option.stateCode === 'all')) {
        handleSelectAll(!allSelected);
      } else if (data.filter((option) => option.stateCode === 'N/A').length > 1) {
        handleChangeSelected(data.filter((option) => option.stateCode !== 'N/A'));
      } else {
        handleChangeSelected(data);
      }
    } else if (reason === 'clear') {
      handleChangeSelected([]);
    }
  };

  const filterOptions = createFilterOptions<T>();

  return (
    <>
      {originalData && (
        <Autocomplete
          ListboxComponent={check ? StyledAdvanced : StyledList}
          multiple
          options={originalData}
          value={selectedData}
          disableCloseOnSelect
          getOptionLabel={(option) => option.stateName}
          isOptionEqualToValue={(option, value) => option?.stateName === value?.stateName}
          renderTags={(data) => (
            <TypographySpan style={{ background: !disabled ? `${White}` : `${Platinum}` }}>
              {t('multiple.select.label', {
                selectedCount: data.length,
                allCount: originalData.length,
              })}
            </TypographySpan>
          )}
          renderOption={(props, option) => {
            const selectAllProps =
              option.stateCode === 'all' // To control the state of 'select-all' checkbox
                ? { checked: allSelected }
                : {};

            const selectGrid =
              option.stateCode === 'all'
                ? {
                    gridArea: 'header',
                  }
                : {};
            return (
              <DropdownItemStyled disabled={disabled} sx={{ ...selectGrid }} {...props}>
                <CheckBoxStyle
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={
                    selectedData.filter((obj) => obj.stateCode === option.stateCode).length !== 0
                  }
                  {...selectAllProps}
                />
                {option.stateCode === 'N/A'
                  ? 'N/A'
                  : option.stateCode === 'all'
                  ? option.stateName
                  : `${option.stateCode}: ${option.stateName}`}
              </DropdownItemStyled>
            );
          }}
          renderInput={(params) => (
            <AppTextField
              variant="outlined"
              {...params}
              disabled={disabled}
              label={isFilter ? '' : t('formField.availableInStates')}
            />
          )}
          filterOptions={(options, params) => {
            const filtered = filterOptions(options, params);

            if (params.inputValue.length > 0 && params.inputValue.length <= 2) {
              return options.filter((item) =>
                item.stateCode.toLowerCase().includes(params.inputValue.toLowerCase()),
              );
            }
            if (params.inputValue.length > 2) {
              return options.filter((item) =>
                item.stateName.toLowerCase().includes(params.inputValue.toLowerCase()),
              );
            }
            if (isFilter) {
              return [
                { stateName: 'Available in Any State', stateCode: 'all' } as T,
                { stateName: 'Not available in any state', stateCode: 'N/A' } as T,
                ...filtered,
              ];
            }
            return [{ stateName: 'Available in Any State', stateCode: 'all' } as T, ...filtered];
          }}
          onChange={(e, data, reason, details) => {
            // if (reason === 'removeOption' && e.code === 'Backspace') return;
            handleAvailableChange(data, reason, details as AutocompleteChangeDetails<T>);
          }}
          className={selectedData && !disabled ? 'clear_indicator' : 'hide_indicator'}
        />
      )}
    </>
  );
};

export default StatesMultipleSelect;
