import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import QuickConfirmationModal from '../../components/modal/QuickConfirmationModal';
import QuickDecisionModal from '../../components/modal/QuickDecisionModal';
import { QuickDecisionModalContext } from '../../context/QuickDecisionModal';
import { IQuickModalProps } from '../../context/QuickModal';
import { IUnsavedData, UnsavedDataContext } from '../../context/UnsavedData';
import { UserContext } from '../../context/User';

import { PermissionKeys, permissionsDictionary } from '../../hooks/usePermissions';
import { UserInfo } from '../../types';
import useUserQuery from '../../useQuery/useUserQuery';
import AppContent from './AppContent';
import { BearerInterceptor } from '../../interceptors/BearerInterceptor';
import { UnauthorizedResponseInterceptor } from '../../interceptors/UnauthorizedResponseInterceptor';
import SessionExpireModal from '../../components/modal/SessionExpireModal';
import { AppLoader } from '../../components/shared/AppLoader';
import { IsDataLoadingContext } from '../../context/IsLoading';
import ModalTerms from '../../components/modal/ModalTerms';
import DataLossModal from '../../components/modal/DataLossModal';

const AppContentWrapper = ({
  showLoader,
  children,
}: {
  showLoader: boolean;
  children: ReactNode;
}) => (
  <div
    style={{
      pointerEvents: showLoader ? 'none' : 'auto', // Block interactions when loader is visible
    }}
  >
    {children}
  </div>
);

export default function MainLayout() {
  BearerInterceptor();
  UnauthorizedResponseInterceptor();
  const { data: userData, refetch } = useUserQuery();

  const [userContextData, setUserContextData] = useState<UserInfo>();
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    if (userData) {
      const dictionary: Record<PermissionKeys, boolean> = {
        [PermissionKeys.CreateConfiguration]: false,
        [PermissionKeys.DeleteConfiguration]: false,
        [PermissionKeys.ReadAgent]: false,
        [PermissionKeys.SignerSellerAgreements]: false,
        [PermissionKeys.ApproveAgentSellerRelationship]: false,
        [PermissionKeys.SendForSignatures]: false,
        [PermissionKeys.AddAgreement]: false,
        [PermissionKeys.ViewAgreement]: false,
        [PermissionKeys.ModifyAgreement]: false,
        [PermissionKeys.DeleteAgreement]: false,
        [PermissionKeys.ReadContact]: false,
        [PermissionKeys.CreateContact]: false,
        [PermissionKeys.UpdateContact]: false,
        [PermissionKeys.DeleteContact]: false,
        [PermissionKeys.AddProducts]: false,
        [PermissionKeys.ViewProducts]: false,
        [PermissionKeys.ModifyProducts]: false,
        [PermissionKeys.DeleteProducts]: false,
        [PermissionKeys.ReadAgentRoles]: false,
        [PermissionKeys.CreateAgentRoles]: false,
        [PermissionKeys.UpdateAgentRoles]: false,
        [PermissionKeys.DeleteAgentRoles]: false,
        [PermissionKeys.ReadProviderRoles]: false,
        [PermissionKeys.CreateProviderRoles]: false,
        [PermissionKeys.UpdateProviderRoles]: false,
        [PermissionKeys.DeleteProviderRoles]: false,
        [PermissionKeys.ReadAgentUser]: false,
        [PermissionKeys.CreateAgentUser]: false,
        [PermissionKeys.UpdateAgentUser]: false,
        [PermissionKeys.DeleteAgentUser]: false,
        [PermissionKeys.ReadProviderUser]: false,
        [PermissionKeys.CreateProviderUser]: false,
        [PermissionKeys.UpdateProviderUser]: false,
        [PermissionKeys.DeleteProviderUser]: false,
        [PermissionKeys.ReadOrganization]: false,
        [PermissionKeys.CreateOrganization]: false,
        [PermissionKeys.UpdateOrganization]: false,
        [PermissionKeys.DeleteOrganization]: false,
        [PermissionKeys.AddSellers]: false,
        [PermissionKeys.ViewSellers]: false,
        [PermissionKeys.ModifySellers]: false,
        [PermissionKeys.DeleteSellers]: false,
        [PermissionKeys.ReadConfiguration]: false,
        [PermissionKeys.ModifyConfiguration]: false,
        [PermissionKeys.SystemAdmin]: false,
        [PermissionKeys.AttachAgreements]: false,
        [PermissionKeys.DeleteAttachments]: false,
      };
      Object.keys(permissionsDictionary).forEach((el) => {
        const key: PermissionKeys = el as PermissionKeys;
        const permissionValue = permissionsDictionary[key] ?? [];

        if (userData?.permissions && typeof permissionValue !== 'boolean') {
          dictionary[key] = userData?.permissions?.some((permission) =>
            permissionValue.some((per2) => per2 === permission.userPolicy),
          );
        }
      });
      setUserContextData({ ...userData, allowedPermissions: dictionary, refetchUserData: refetch });
    }
  }, [refetch, userData]);
  const [unsavedData, setUnsavedData] = useState<IUnsavedData>({
    isPopupOpen: false,
    isUnsavedChanges: false,
    onDiscardChanges: () => {},
    onSaveChanges: () => {},
    onExit: () => {},
    returnUrl: '',
  });

  const [quickDecisionModal, setQuickDecisionModal] = useState<IQuickModalProps>({
    isOpen: false,
    message: '',
    modalTitle: '',
    yesCallBack: () => {},
    noCallBack: () => {},
  });

  const unsavedDataContext = useMemo(() => ({ unsavedData, setUnsavedData }), [unsavedData]);
  const quickDecisionModalProps = useMemo(
    () => ({ quickDecisionModal, setQuickDecisionModal }),
    [quickDecisionModal],
  );
  const isDataLoadingContextValue = useMemo(
    () => ({ isDataLoading, setIsDataLoading }),
    [isDataLoading],
  );

  if (!userContextData) return <></>;

  return (
    <UserContext.Provider value={userContextData}>
      <UnsavedDataContext.Provider value={unsavedDataContext}>
        <QuickDecisionModalContext.Provider value={quickDecisionModalProps}>
          <IsDataLoadingContext.Provider value={isDataLoadingContextValue}>
            <AppLoader />
            <AppContentWrapper showLoader={isDataLoading}>
              <AppContent />
            </AppContentWrapper>
            <SessionExpireModal />
            <QuickConfirmationModal />
            <QuickDecisionModal />
            <ModalTerms />
            <DataLossModal />
          </IsDataLoadingContext.Provider>
        </QuickDecisionModalContext.Provider>
      </UnsavedDataContext.Provider>
    </UserContext.Provider>
  );
}
