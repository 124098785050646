import { SellerList } from '../../../pages/SellerList';
import { AppActionButton } from '../../shared/AppActionButton';
import { FlexBox } from '../../shared/FlexBox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { CommonOrganization } from '../../../types/sellerGroup';
import { useTranslation } from 'react-i18next';

export const SellersTab = () => {
  const { sellerGroupInformation } = useOutletContext<{
    sellerGroupInformation?: CommonOrganization;
  }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <FlexBox sx={{ justifyContent: 'end', width: '100%' }}>
        <AppActionButton
          variant="outlined"
          sx={{ maxHeight: '34px', minWidth: '100px !important' }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            navigate(
              `/dashboard/Seller/Add?sellerGroupId=${sellerGroupInformation?.sellerGroupId}&sellerGroupName=${sellerGroupInformation?.legalName}&isReturnToView=1`,
            );
          }}
        >
          {t('action.add')}
        </AppActionButton>
      </FlexBox>
      <SellerList orgId={sellerGroupInformation?.sellerGroupId || 0} />
    </>
  );
};
