import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Grid2, useMediaQuery, useTheme } from '@mui/material';
import { isEqual } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { Control, Controller, useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { dealershipInfoValidationSchema } from '../../../Resolvers/dealerInfoValidationSchema';
import { ConfigurationService } from '../../../services/configurationService';
import { DealershipInfoFormType } from '../../../types';
import { FeinBox } from '../../common/styles/feinBox';
import AppAutocompleteSingleSelect from '../../shared/AppAutocompleteSingleSelect';
import { AppTextField } from '../../shared/AppTextField';
import { TypographySpan } from '../../shared/StateMultiSelect/styles';
import { DealershipInfoFormProps } from './types';
import { AppAutocomplete } from '../../shared/AppAutocomplete';
import AppListItem from '../../shared/AppListItem';
import { ModificationLog } from '../../ModificationLog';

const dealershipTypesWithAssetMakesIds = [1, 2, 3]; // Franchise Automobile/Powersports/RV ID`s

export const DealershipInfoForm: FC<Partial<DealershipInfoFormProps>> = ({
  isEdit,
  orgData,
  formRef,
  onSubmit,
  setIsUnsavedChanges,
  setIsFormValid,
}) => {
  const { t } = useTranslation();

  const [selectedDealershipType, setSelectedDealershipType] = useState<number | undefined>(
    undefined,
  );

  const { data: averageAssetInventoryRangeData } =
    ConfigurationService.useAverageAssetInventoryRange();
  const { data: dealerTypeGroupsData } = ConfigurationService.useDealerTypeGroups();
  const { data: fIMenuData } = ConfigurationService.useFIMenus();
  const { data: dmsData } = ConfigurationService.useDMS();
  const { data: assetMakesData } = ConfigurationService.useAssetMakes(selectedDealershipType, {
    enabled: selectedDealershipType
      ? dealershipTypesWithAssetMakesIds.includes(selectedDealershipType)
      : false,
  });

  const [otherDmsNameError, setOtherDmsNameError] = useState('');
  const [otherMenuNameError, setOtherMenuNameError] = useState('');

  const averageAssetInventoryRangeOptions = useMemo(() => {
    return (
      averageAssetInventoryRangeData?.map((item) => {
        return {
          label: item.averageAssetInventoryRangeValue,
          value: item,
        };
      }) || []
    );
  }, [averageAssetInventoryRangeData]);

  const oemsOptions = useMemo(() => {
    return assetMakesData?.map((item) => ({ label: item.assetMakeName, value: item })) || [];
  }, [assetMakesData]);

  const dealershipTypeOptions = useMemo(() => {
    return (
      dealerTypeGroupsData?.map((item) => ({
        label: item.dealerTypeGroupDisplayName,
        value: item,
      })) || []
    );
  }, [dealerTypeGroupsData]);

  const dmsProviderOptions = useMemo(() => {
    return (
      dmsData?.map((item) => ({
        label: item.dmsDisplayName,
        value: item,
      })) || []
    );
  }, [dmsData]);

  const fiMenuOptions = useMemo(() => {
    return (
      fIMenuData?.map((item) => ({
        label: item.fiMenuDisplayName,
        value: item,
      })) || []
    );
  }, [fIMenuData]);

  const formDefaultValues = useMemo<DealershipInfoFormType>(() => {
    const averageAssetRangeItem = averageAssetInventoryRangeData?.find(
      (item) => item.averageAssetInventoryRangeId === orgData?.averageAssetInventoryRangeId,
    );

    const dealerTypeGroup = dealerTypeGroupsData
      ?.filter((item) => item.dealerTypeGroupId === orgData?.dealerTypeGroupIds[0])
      .map((item) => ({ label: item.dealerTypeGroupDisplayName, value: item }));

    const assetMakesValues = assetMakesData
      ?.filter((item) => {
        return orgData?.assetMakeIds.includes(item.assetMakeId);
      }, [])
      .map((item) => ({ label: item.assetMakeName, value: item }));

    const dmsProviderValues = dmsData
      ?.filter((item) => {
        return orgData?.dmsIds.includes(item.dmsId);
      }, [])
      .map((item) => ({ label: item.dmsDisplayName, value: item }));

    const fiMenuValues = fIMenuData
      ?.filter((item) => {
        return orgData?.fiMenuIds.includes(item.fiMenuId);
      }, [])
      .map((item) => ({ label: item.fiMenuDisplayName, value: item }));

    return {
      orgId: orgData?.orgId || null,
      dealerLicenseId: orgData?.dealerLicenseId || '',
      averageAssetInventoryRangeId: averageAssetRangeItem
        ? {
            label: averageAssetRangeItem.averageAssetInventoryRangeValue,
            value: averageAssetRangeItem,
          }
        : null,
      showOems: !!dealerTypeGroup?.find((item) => item.value.dealerTypeGroupId !== 4),
      showOtherDms: !!dmsProviderValues?.find((item) => item.label === 'Other')?.label,
      showOtherMenu: !!fiMenuValues?.find((item) => item.label === 'Other')?.label,
      otherDmsAdditionalError: false,
      otherMenuAdditionalError: false,
      dealerTypeGroup: dealerTypeGroup?.length ? dealerTypeGroup[0] : null,
      assetMakeIds: assetMakesValues ? assetMakesValues : [],
      dmsIds: dmsProviderValues ? dmsProviderValues : [],
      dmsOtherName: orgData?.dmsOtherName || '',
      fiMenuIds: fiMenuValues ? fiMenuValues : [],
      fiMenuOtherName: orgData?.fiMenuOtherName || '',
      integrationPartner: orgData?.preferredIntegrationPartner || '',
    };
  }, [
    assetMakesData,
    averageAssetInventoryRangeData,
    dealerTypeGroupsData,
    orgData,
    dmsData,
    fIMenuData,
  ]);

  const {
    control,
    watch,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    reset,
    setValue,
    trigger,
  } = useForm<DealershipInfoFormType>({
    mode: 'all',
    defaultValues: formDefaultValues,
    resolver: yupResolver(dealershipInfoValidationSchema),
  });

  const formValues = watch();

  const onSubmitWrapper = (data: DealershipInfoFormType) => {
    if (onSubmit)
      onSubmit({
        ...data,
        assetMakeIds: data.showOems ? data.assetMakeIds : [],
        dmsOtherName: data.showOtherDms ? data.dmsOtherName : '',
        fiMenuOtherName: data.showOtherMenu ? data.fiMenuOtherName : '',
      });
  };

  useEffect(() => {
    const {
      dealerLicenseId,
      averageAssetInventoryRangeId,
      dealerTypeGroup,
      assetMakeIds,
      dmsIds,
      dmsOtherName,
      fiMenuIds,
      fiMenuOtherName,
    } = formValues;
    const isValuesEqual = isEqual(formDefaultValues, {
      ...formValues,
      dealerLicenseId,
      averageAssetInventoryRangeId,
      dealerTypeGroup,
      assetMakeIds,
      dmsIds,
      dmsOtherName,
      fiMenuIds,
      fiMenuOtherName,
    });

    if (setIsUnsavedChanges) setIsUnsavedChanges(!isValuesEqual);
    if (setIsFormValid) setIsFormValid(isValid);
  }, [formDefaultValues, formValues, isValid, setIsFormValid, setIsUnsavedChanges]);

  useEffect(() => {
    setValue(
      'showOems',
      !!formValues.dealerTypeGroup && formValues.dealerTypeGroup?.value.dealerTypeGroupId !== 4,
    );
    setSelectedDealershipType(formValues.dealerTypeGroup?.value.dealerTypeGroupId);
    trigger('assetMakeIds');
  }, [formValues.dealerTypeGroup, setValue, trigger]);

  useEffect(() => {
    if (formValues.assetMakeIds.length) {
      const valueDoesNotExist = assetMakesData?.every((item) => {
        if (item.assetMakeId === formValues.assetMakeIds[0].value.assetMakeId) {
          return false;
        }
        return true;
      });
      if (valueDoesNotExist) {
        setValue('assetMakeIds', [], { shouldValidate: true });
      }
    }
  }, [assetMakesData, formValues.assetMakeIds, setValue]);

  useEffect(() => {
    setValue('showOtherDms', !!formValues.dmsIds.find((item) => item.label === 'Other')?.label);
    trigger('dmsOtherName');
  }, [formValues.dmsIds, setValue, trigger]);

  useEffect(() => {
    setValue('showOtherMenu', !!formValues.fiMenuIds.find((item) => item.label === 'Other')?.label);
    trigger('fiMenuOtherName');
  }, [formValues.fiMenuIds, setValue, trigger]);

  useEffect(() => {
    let isFound = false;
    dmsProviderOptions.forEach((item) => {
      formValues.dmsOtherName.split(',').forEach((otherName) => {
        const valueToBeChecked = otherName.trim().toLowerCase();
        if (item.label.toLowerCase() === valueToBeChecked) {
          isFound = true;
          setOtherDmsNameError(t('formField.otherMenu.error', { errorMenuFieldValue: item.label }));
        }
      });
    });
    if (!isFound) {
      setOtherDmsNameError('');
    }
  }, [dmsProviderOptions, formValues.dmsOtherName, t]);

  useEffect(() => {
    if (otherDmsNameError) {
      setValue('otherDmsAdditionalError', true, { shouldValidate: true });
    } else {
      setValue('otherDmsAdditionalError', false, { shouldValidate: true });
    }
  }, [otherDmsNameError, setValue]);

  useEffect(() => {
    let isFound = false;
    fiMenuOptions.forEach((item) => {
      formValues.fiMenuOtherName.split(',').forEach((otherName) => {
        const valueToBeChecked = otherName.trim().toLowerCase();
        if (item.label.toLowerCase() === valueToBeChecked) {
          isFound = true;
          setOtherMenuNameError(
            t('formField.otherMenu.error', { errorMenuFieldValue: item.label }),
          );
        }
      });
    });
    if (!isFound) {
      setOtherMenuNameError('');
    }
  }, [fiMenuOptions, formValues.fiMenuOtherName, t]);

  useEffect(() => {
    if (otherMenuNameError) {
      setValue('otherMenuAdditionalError', true, { shouldValidate: true });
    } else {
      setValue('otherMenuAdditionalError', false, { shouldValidate: true });
    }
  }, [otherMenuNameError, setValue]);

  useEffect(() => {
    if (!isDirty) reset(formDefaultValues);
  }, [formDefaultValues, isDirty, reset]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <form
      onSubmit={handleSubmit(onSubmitWrapper)}
      onReset={() => {
        reset();
      }}
      ref={formRef}
      autoComplete="off"
    >
      <Grid2 container spacing={isSmall ? 1 : 2} mt={1}>
        <Grid2 size={{ xs: 6 }}>
          <Controller
            control={control}
            name={'dealerLicenseId'}
            render={({ field }) => (
              <AppTextField
                {...field}
                variant="outlined"
                label={t('formField.dealerLicenceId')}
                error={!!errors.dealerLicenseId?.message && isEdit}
                errorMessage={isEdit ? errors.dealerLicenseId?.message : undefined}
                disabled={!isEdit}
                inputProps={{ maxLength: 18 }}
              />
            )}
          />
          {isEdit && formValues.dealerLicenseId?.length === 18 && (
            <FeinBox>
              <i>Max Length 18</i>
            </FeinBox>
          )}
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <AppAutocompleteSingleSelect
            control={control as unknown as Control<FieldValues>}
            name={'averageAssetInventoryRangeId'}
            label={t('formField.averageTotalInventory')}
            options={averageAssetInventoryRangeOptions}
            errorMessage={errors?.averageAssetInventoryRangeId?.message}
            disabled={!isEdit}
            enableAnyOption={false}
          />
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Controller
            control={control}
            name={'dealerTypeGroup'}
            render={({ field }) => (
              <AppAutocomplete
                {...field}
                value={field.value}
                options={dealershipTypeOptions}
                disabled={!isEdit}
                isOptionEqualToValue={(option, value) => {
                  return option.value.dealerTypeGroupId === value.value.dealerTypeGroupId;
                }}
                renderInput={(params) => (
                  <AppTextField {...params} label={t('formField.dealershipType')} />
                )}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                renderOption={(optionProps, option) => {
                  return (
                    <AppListItem {...optionProps} key={option.value.dealerTypeGroupId}>
                      <span>{option.label}</span>
                    </AppListItem>
                  );
                }}
                filterOptions={(_options, state) => {
                  const filtered = dealershipTypeOptions?.filter((item) =>
                    item.label.toLowerCase().includes(state.inputValue.toLowerCase()),
                  );
                  return filtered || [];
                }}
                renderTags={(data) => (
                  <TypographySpan>
                    {t('multiple.select.label', {
                      selectedCount: data.length,
                      allCount: dealerTypeGroupsData?.length || 0,
                    })}
                  </TypographySpan>
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          {formValues.showOems && (
            <Controller
              control={control}
              name={'assetMakeIds'}
              render={({ field }) => (
                <AppAutocomplete
                  {...field}
                  disablePortal
                  value={field.value}
                  options={oemsOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.value.assetMakeId === value.value.assetMakeId
                  }
                  renderInput={(params) => (
                    <AppTextField
                      {...params}
                      label={t('formField.oems')}
                      errorMessage={errors.assetMakeIds?.message}
                      error={!!errors.assetMakeIds?.message}
                      disabled={!isEdit}
                    />
                  )}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  multiple
                  disableCloseOnSelect
                  renderOption={(optionProps, option, { selected }) => {
                    return (
                      <AppListItem {...optionProps} key={option.value.assetMakeId}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          color="success"
                          checked={selected}
                          size="small"
                        />
                        <span>{option.label}</span>
                      </AppListItem>
                    );
                  }}
                  getOptionDisabled={() => !isEdit}
                  filterOptions={(_options, state) => {
                    const filtered = oemsOptions?.filter((item) =>
                      item.label.toLowerCase().includes(state.inputValue.toLowerCase()),
                    );

                    return filtered || [];
                  }}
                  renderTags={(data) => (
                    <TypographySpan>
                      {t('multiple.select.label', {
                        selectedCount: data.length,
                        allCount: assetMakesData?.length || 0,
                      })}
                    </TypographySpan>
                  )}
                  disableClearable={!isEdit}
                />
              )}
            />
          )}
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Controller
            control={control}
            name={'dmsIds'}
            render={({ field }) => (
              <AppAutocomplete
                {...field}
                disablePortal
                value={field.value}
                options={dmsProviderOptions}
                disabled={!isEdit}
                isOptionEqualToValue={(option, value) => option.value.dmsId === value.value.dmsId}
                renderInput={(params) => (
                  <AppTextField {...params} label={t('formField.dmsProvider')} />
                )}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                multiple
                disableCloseOnSelect
                renderOption={(optionProps, option, { selected }) => {
                  return (
                    <AppListItem {...optionProps} key={option.value.dmsId}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        color="success"
                        checked={selected}
                        size="small"
                      />
                      <span>{option.label}</span>
                    </AppListItem>
                  );
                }}
                filterOptions={(_options, state) => {
                  const filtered = dmsProviderOptions?.filter((item) =>
                    item.label.toLowerCase().includes(state.inputValue.toLowerCase()),
                  );
                  return filtered || [];
                }}
                renderTags={(data) => (
                  <TypographySpan>
                    {t('multiple.select.label', {
                      selectedCount: data.length,
                      allCount: dmsData?.length || 0,
                    })}
                  </TypographySpan>
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          {formValues.showOtherDms && (
            <Controller
              control={control}
              name={'dmsOtherName'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  variant="outlined"
                  label={t('formField.otherDms')}
                  fullWidth
                  errorMessage={
                    errors.dmsOtherName?.message || errors.otherDmsAdditionalError?.message
                  }
                  error={
                    !!errors.dmsOtherName?.message || !!errors.otherDmsAdditionalError?.message
                  }
                  disabled={!isEdit}
                />
              )}
            />
          )}
          {otherDmsNameError && (
            <FeinBox>
              <i>{otherDmsNameError}</i>
            </FeinBox>
          )}
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Controller
            control={control}
            name={'fiMenuIds'}
            render={({ field }) => (
              <AppAutocomplete
                {...field}
                disablePortal
                value={field.value}
                options={fiMenuOptions}
                disabled={!isEdit}
                isOptionEqualToValue={(option, value) =>
                  option.value.fiMenuId === value.value.fiMenuId
                }
                renderInput={(params) => <AppTextField {...params} label={t('formField.fiMenu')} />}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                multiple
                disableCloseOnSelect
                renderOption={(optionProps, option, { selected }) => {
                  return (
                    <AppListItem {...optionProps} key={option.value.fiMenuId}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        color="success"
                        checked={selected}
                        size="small"
                      />
                      <span>{option.label}</span>
                    </AppListItem>
                  );
                }}
                filterOptions={(_options, state) => {
                  const filtered = fiMenuOptions?.filter((item) =>
                    item.label.toLowerCase().includes(state.inputValue.toLowerCase()),
                  );
                  return filtered || [];
                }}
                renderTags={(data) => (
                  <TypographySpan>
                    {t('multiple.select.label', {
                      selectedCount: data.length,
                      allCount: fIMenuData?.length || 0,
                    })}
                  </TypographySpan>
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          {formValues.showOtherMenu && (
            <Controller
              control={control}
              name={'fiMenuOtherName'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  variant="outlined"
                  label={t('formField.otherMenu')}
                  fullWidth
                  errorMessage={
                    errors.fiMenuOtherName?.message || errors.otherMenuAdditionalError?.message
                  }
                  error={
                    !!errors.fiMenuOtherName?.message || !!errors.otherMenuAdditionalError?.message
                  }
                  disabled={!isEdit}
                />
              )}
            />
          )}
          {otherMenuNameError && (
            <FeinBox>
              <i>{otherMenuNameError}</i>
            </FeinBox>
          )}
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Controller
            control={control}
            name={'integrationPartner'}
            render={({ field }) => (
              <AppTextField
                {...field}
                variant="outlined"
                label={t('formField.integrationPartner')}
                fullWidth
                disabled={!isEdit}
                inputProps={{ maxLength: 18 }}
                errorMessage={errors.integrationPartner?.message}
                error={!!errors.integrationPartner?.message}
              />
            )}
          />
          {isEdit && formValues.integrationPartner?.length === 18 && (
            <FeinBox>
              <i>Max Length 18</i>
            </FeinBox>
          )}
        </Grid2>
      </Grid2>
      {!isEdit && (
        <ModificationLog
          {...{
            createdOn: orgData?.createdOn,
            createdBy: orgData?.createdBy,
            updatedBy: orgData?.updatedBy,
            updatedOn: orgData?.updatedOn,
          }}
        />
      )}
    </form>
  );
};
