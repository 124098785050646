import { Box, Menu } from '@mui/material';
import styled from 'styled-components';
import { LightSlateGray, MainTextColor, White, WhiteSmoke } from '../../../Theme/colorsVariables';
import { FontSizeMed, ElevenPx, FontSizeHeighestMax } from '../../../Theme/screensVariables';

export const UserCustomMenu = styled(Menu)`
  div:nth-child(3) {
    border-radius: 0;
    width: 280px;
    background-color: ${White};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }
  ul {
    a {
      text-decoration: none;
      color: ${LightSlateGray};
      li {
        padding: 6px 0;
        padding-left: 10px;
        font-size: 16px;
        font-family: Gotham-medium;
        color: ${LightSlateGray};
        &:hover {
          background-color: ${WhiteSmoke};
        }
      }
    }
  }
`;

export const UserNameStyle = styled(Box)`
  font-size: ${FontSizeHeighestMax};
  line-height: 20px;
  font-family: Gotham-Medium;
  color: ${MainTextColor};
  white-space: normal;
  word-break: break-word;
  @media (max-width: 1600px) {
    font-size: ${FontSizeMed};
    line-height: 15px;
  }
`;
export const UserEmailStyle = styled(Box)`
  font-size: 12px;
  font-family: Gotham-Medium;
  margin-top: 5px;
  color: ${LightSlateGray};
`;
export const UserPositionStyle = styled(Box)`
  font-size: ${FontSizeMed};
  font-family: Gotham-Medium;
  color: ${LightSlateGray};
  padding-top: 5px;
  @media (max-width: 1600px) {
    font-size: ${ElevenPx};
  }
`;
export const LogOutButton = styled(Box)`
  font-family: 'Gotham-Medium';
  color: #77868f;
  font-size: 16px;
`;

export const UserInfoDiv = styled(Box)`
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
`;

export const AvatarBox = styled(Box)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  @media (max-width: 1600px) {
    width: 40px;
    height: 40px;
  }
`;
