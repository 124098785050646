import { Typography, FormGroup, Checkbox, Grid2, useTheme, useMediaQuery } from '@mui/material';
import { useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { PermissionSet } from '../../types/permission';
import { CheckboxMain } from './styles';

interface PermissionSetProps {
  permissionSets: PermissionSet[];
  disabledPermissionSets: number[];
  control: Control<{ permissionSetIds: number[] }>;
  disabled: boolean;
}

export enum TempPermissionSetNamesWithExtraFunctionality {
  ApproveAgentSellerRelationship = 'Approve Agent-Seller Relationship',
  Signer = 'Signer - Seller & Seller Group Agreements',
  AttachAgreement = 'Attach Agreement',
  DeleteAgreementAttachment = 'Delete Agreement Attachment',
}

export const PermissionSets = ({
  permissionSets,
  disabledPermissionSets,
  control,
  disabled,
}: PermissionSetProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const [
    isApproveAgentSellerRelWasSelectedManually,
    setIsApproveAgentSellerRelWasSelectedManually,
  ] = useState(false);

  const permissionSetGroups = useMemo(() => {
    if (permissionSets) {
      const groups: string[] = [];
      permissionSets.forEach((permissionSet) => {
        const groupName = permissionSet.permissionType.permissionTypeName;
        if (groups.indexOf(groupName) !== -1) {
          return;
        }
        groups.push(groupName);
      });
      const permissionGroups = groups.map((groupName) => {
        return {
          groupName,
          permissionSets: permissionSets.filter(
            (permissionSet) => permissionSet.permissionType.permissionTypeName === groupName,
          ),
        };
      });

      return permissionGroups;
    }
  }, [permissionSets]);

  const approveAgentSellerRelationshipPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) =>
          item.name === TempPermissionSetNamesWithExtraFunctionality.ApproveAgentSellerRelationship,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const attachAgreementPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) => item.name === TempPermissionSetNamesWithExtraFunctionality.AttachAgreement,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const signerPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) => item.name === TempPermissionSetNamesWithExtraFunctionality.Signer,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  const deleteAgreementAttachmentPermissionSetId = useMemo(() => {
    return (
      permissionSets?.find(
        (item) =>
          item.name === TempPermissionSetNamesWithExtraFunctionality.DeleteAgreementAttachment,
      )?.permissionSetId || 0
    );
  }, [permissionSets]);

  return (
    <>
      {permissionSetGroups
        ?.sort((a, b) => a.groupName.localeCompare(b.groupName))
        .map((permissionGroup) => (
          <Grid2 size={{ xs: 12, md: 6 }} key={permissionGroup.groupName}>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: isSmall ? '14px' : '16px',
                fontFamily: 'Gotham-Bold',
              }}
              component="div"
            >
              {permissionGroup.groupName === 'Agencies' ? 'Agents' : permissionGroup.groupName}
            </Typography>
            <FormGroup>
              <Controller
                name="permissionSetIds"
                control={control}
                render={({ field }) => (
                  <>
                    {permissionGroup.permissionSets
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((permissionSet) => (
                        <CheckboxMain
                          key={permissionSet.permissionSetId}
                          data-cy={'data-cy-permissionSet'}
                          label={permissionSet.name}
                          disabled={
                            disabled ||
                            disabledPermissionSets.indexOf(permissionSet.permissionSetId) !== -1
                          }
                          control={
                            <Checkbox
                              color="success"
                              value={permissionSet.permissionSetId}
                              data-cy={'data-cy-permissionSet-checkbox'}
                              checked={
                                field.value?.indexOf(permissionSet.permissionSetId) >= 0 ||
                                disabledPermissionSets.indexOf(permissionSet.permissionSetId) !== -1
                              }
                              onChange={(event, checked) => {
                                const selectedPermissionSets = field.value;
                                if (checked) {
                                  if (
                                    permissionSet.name ===
                                    TempPermissionSetNamesWithExtraFunctionality.Signer
                                  ) {
                                    const res = [
                                      ...new Set([
                                        ...selectedPermissionSets,
                                        +event.target.value,
                                        approveAgentSellerRelationshipPermissionSetId,
                                        attachAgreementPermissionSetId,
                                        deleteAgreementAttachmentPermissionSetId,
                                      ]),
                                    ];
                                    field.onChange(res);
                                  } else {
                                    if (
                                      permissionSet.name ===
                                        TempPermissionSetNamesWithExtraFunctionality.ApproveAgentSellerRelationship &&
                                      !selectedPermissionSets.includes(signerPermissionSetId)
                                    ) {
                                      setIsApproveAgentSellerRelWasSelectedManually(true);
                                    }
                                    field.onChange([
                                      ...selectedPermissionSets,
                                      +event.target.value,
                                    ]);
                                  }
                                } else {
                                  if (
                                    permissionSet.name ===
                                    TempPermissionSetNamesWithExtraFunctionality.Signer
                                  ) {
                                    if (isApproveAgentSellerRelWasSelectedManually) {
                                      field.onChange(
                                        selectedPermissionSets.filter(
                                          (value) => value !== +event.target.value,
                                        ),
                                      );
                                    } else {
                                      field.onChange(
                                        selectedPermissionSets.filter(
                                          (value) =>
                                            value !== +event.target.value &&
                                            value !==
                                              approveAgentSellerRelationshipPermissionSetId &&
                                            value !== attachAgreementPermissionSetId &&
                                            value !== deleteAgreementAttachmentPermissionSetId,
                                        ),
                                      );
                                    }
                                  } else {
                                    if (
                                      permissionSet.name ===
                                        TempPermissionSetNamesWithExtraFunctionality.ApproveAgentSellerRelationship ||
                                      permissionSet.name ===
                                        TempPermissionSetNamesWithExtraFunctionality.AttachAgreement ||
                                      permissionSet.name ===
                                        TempPermissionSetNamesWithExtraFunctionality.DeleteAgreementAttachment
                                    ) {
                                      setIsApproveAgentSellerRelWasSelectedManually(false);
                                    }
                                    field.onChange(
                                      selectedPermissionSets.filter(
                                        (value) => value !== +event.target.value,
                                      ),
                                    );
                                  }
                                }
                              }}
                            />
                          }
                        />
                      ))}
                  </>
                )}
              />
            </FormGroup>
          </Grid2>
        ))}
    </>
  );
};
