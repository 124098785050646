import { useCallback, useContext } from 'react';
import { UnsavedDataContext } from '../context/UnsavedData';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { TabsComunicationChannel } from '../providers';

export const useNavigateAfterDatalossDecision = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { unsavedData, setUnsavedData } = useContext(UnsavedDataContext);

  const navigateToClickedItem = useCallback(
    (url: string) => {
      if (url === 'back') {
        window.history.back();
      } else {
        navigate(url);
      }
    },
    [navigate],
  );

  const navigateAfterDatalossDecision = useCallback(() => {
    if (unsavedData.returnUrl) {
      if (unsavedData.returnUrl === 'logout') {
        localStorage.clear();
        TabsComunicationChannel.postMessage('logout');
        logout();

        setUnsavedData((prevValue) => ({ ...prevValue, returnUrl: '' }));
        return true;
      }
      navigateToClickedItem(unsavedData.returnUrl);

      setUnsavedData((prevValue) => ({ ...prevValue, returnUrl: '' }));
      return true;
    }
    return false;
  }, [logout, navigateToClickedItem, setUnsavedData, unsavedData]);
  return { navigateAfterDatalossDecision };
};
