// eslint-disable-next-line import/no-extraneous-dependencies
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { AppActionButton } from '../../shared/AppActionButton';
import { AlignCenterBox, CenterCenterBox, SpaceEvenlyBox } from '../../shared/FlexBox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, IconButton, List, ListItem, Typography } from '@mui/material';
import { MainColor } from '../../../Theme/colorsVariables';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AttachagreementFormValues } from '../../../types/sellerGroup';
import { FeinBox } from '../../common/styles/feinBox';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document, Page } from 'react-pdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AppTooltip from '../../shared/AppTooltip';

const getColor = (props: {
  $isfocused: boolean;
  $isdragaccept: boolean;
  $isdragreject: boolean;
}) => {
  if (props.$isdragaccept) {
    return '#00e676';
  }
  if (props.$isdragreject) {
    return '#ff1744';
  }
  if (props.$isfocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div<{
  $isfocused: boolean;
  $isdragaccept: boolean;
  $isdragreject: boolean;
}>`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const PdfDocument = styled(Document)`
  margin: 10px;
  border-radius: 6px;
  overflow: auto;
  .react-pdf__Page {
    display: flex;
    justify-content: center;
  }
`;

export const FileDropzone = () => {
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormContext<AttachagreementFormValues>();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    open,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024,
    noClick: true,
    onDrop: (incomingFiles) => {
      setValue('attachedFiles', incomingFiles, { shouldValidate: true });
      const file = incomingFiles[0];

      if (file) {
        const fileURL = URL.createObjectURL(file);
        setPdfFile(fileURL);
      }
    },
    onFileDialogCancel() {
      if (hiddenInputRef.current) {
        hiddenInputRef.current.files = null;
        setValue('attachedFiles', null, { shouldValidate: true });
      }
    },
  });

  const { attachedFiles } = watch();

  useEffect(() => {
    if (attachedFiles && !acceptedFiles?.length) {
      setValue('attachedFiles', null, { shouldValidate: true });
    }
  }, [acceptedFiles, attachedFiles, setValue]);

  const files = attachedFiles?.map((file) => (
    <ListItem key={file.path} sx={{ textAlign: 'center' }}>
      {file.path} - {file.size} bytes
    </ListItem>
  ));
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {!pdfFile ? (
        <Container
          {...getRootProps()}
          $isdragaccept={isDragAccept}
          $isdragreject={isDragReject}
          $isfocused={isFocused}
        >
          <input
            type="file"
            {...register('attachedFiles', { required: true })}
            style={{ opacity: 0 }}
          />
          <input {...getInputProps()} />
          {isDragActive ? (
            isDragReject ? (
              <FeinBox>File type is not allowed.</FeinBox>
            ) : (
              <p>Drop the file here ...</p>
            )
          ) : (
            <CenterCenterBox flexDirection={'column'}>
              <Box>
                <CloudUploadIcon sx={{ fontSize: '60px', fill: MainColor }} />
              </Box>
              <Typography>Drag & Drop PDF file here</Typography>
              <Typography my={1}>or</Typography>
              <AppTooltip arrow placement="right" title={'Max file size: 10Mb'}>
                <AppActionButton sx={{ margin: '0 !important' }} onClick={open}>
                  Select file
                </AppActionButton>
              </AppTooltip>

              <FeinBox>{errors.attachedFiles?.message}</FeinBox>
              <List>{files}</List>
            </CenterCenterBox>
          )}
        </Container>
      ) : (
        <div
          style={{
            border: '1px dashed #ccc',
            borderRadius: '10px',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#fafafa',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <PdfDocument
            file={pdfFile}
            onLoadSuccess={(doc) => {
              setNumPages(doc.numPages);
            }}
          >
            <Page pageNumber={pageNumber} renderTextLayer={false} />
          </PdfDocument>
          <CenterCenterBox my={2}>
            <SpaceEvenlyBox
              sx={{
                borderRadius: '10px',
                boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
                width: '250px',
              }}
            >
              <AlignCenterBox>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
                  }}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
                  }}
                >
                  <NavigateNextIcon />
                </IconButton>
              </AlignCenterBox>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  setPdfFile(null);
                  setNumPages(0);
                  setPageNumber(1);
                  setValue('attachedFiles', null, { shouldValidate: true });
                }}
              >
                <DeleteForeverIcon color="error" />
              </IconButton>
            </SpaceEvenlyBox>
          </CenterCenterBox>
        </div>
      )}
    </div>
  );
};
