import React, { useEffect, useMemo, useState } from 'react';

import CustomModal from '../Modal';
import { useTranslation } from 'react-i18next';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalButtonsBox } from '../Modal/styles';
import { ModalTitle } from './styles';
import { useAuth0 } from '@auth0/auth0-react';
import { FetchLookups } from '../../../services/sellerServices';
import { useLocation } from 'react-router-dom';
import { TabsComunicationChannel } from '../../../providers';

let inactivityTimer: NodeJS.Timeout | undefined = undefined;
let logoutTimer: NodeJS.Timeout | undefined = undefined;

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (char) {
        return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export default function SessionExpireModal() {
  const { t } = useTranslation();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [expirationTime, setExpirationTime] = useState<number>(0);

  const { logout, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const { data: lookups } = FetchLookups({
    enabled: isAuthenticated && location.pathname.includes('/dashboard'),
  });

  const timeToLoguotAfterPopupShown = useMemo(() => {
    if (lookups?.activityModalTimeout[0].value) {
      return +lookups?.activityModalTimeout[0].value;
    }
    return null;
  }, [lookups?.activityModalTimeout]);

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      if (expirationTime && expirationTime > 0 && timeToLoguotAfterPopupShown) {
        inactivityTimer = setTimeout(() => {
          setShowModal(true);
          logoutTimer = setTimeout(() => {
            TabsComunicationChannel.postMessage('logout');
            logout();
          }, expirationTime * timeToLoguotAfterPopupShown * 1000); // % of token expire time from auth0 to logout after popup has been shown
        }, expirationTime * (1 - timeToLoguotAfterPopupShown) * 1000); // % of token expire time from auth0 to show warning popup if user is inactive
      }
    };

    const handleActivity = () => {
      if (expirationTime && expirationTime > 0) {
        resetTimer();
      }
    };

    // Initial setup
    if (expirationTime && expirationTime > 0) {
      resetTimer();
    }

    // Event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Cleanup on component unmount
    return () => {
      clearTimeout(inactivityTimer);
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, [expirationTime, logout, timeToLoguotAfterPopupShown]);

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();
      const expTime = parseJwt(token).exp as number;
      setExpirationTime(
        Math.round((new Date(expTime * 1000).getTime() - new Date().getTime()) / 1000),
      );
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  const handleStayActive = () => {
    setShowModal(false);
    clearTimeout(logoutTimer);
    clearTimeout(inactivityTimer);
    inactivityTimer = undefined;
    logoutTimer = undefined;
  };

  return (
    <CustomModal modalTitle={t('session.timeout.title')} open={showModal} containerWidth="500px">
      <>
        <ModalTitle>
          {t('session.timeout.message', {
            minutes: timeToLoguotAfterPopupShown
              ? Math.round((expirationTime * timeToLoguotAfterPopupShown) / 60)
              : '',
          })}
        </ModalTitle>
        <ModalButtonsBox>
          <AppActionButton
            variant="outlined"
            onClick={() => {
              handleStayActive();
            }}
          >
            {t('action.stayLoggedIn')}
          </AppActionButton>
        </ModalButtonsBox>
      </>
    </CustomModal>
  );
}
