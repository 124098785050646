import InfoIcon from '@mui/icons-material/Info';
import { useMemo } from 'react';
import { TooltipMessage } from './TooltipMessage';
import AppTooltip from '../shared/AppTooltip';
import { useTranslation } from 'react-i18next';
import { formatUtcToLocalTime } from '../../utils/formatUtcToLocalTime';

export type AgreementReasons = 'voided' | 'reviewed' | 'rejected' | 'declined' | 'deliveryFailure';

export default function AgreementStatusInfoTooltip({
  declinedBy,
  statusReasonMessage,
  deliveryFailureEmail,
  reviewedBy,
  reviewedOn,
  agreementStatusReason,
  voidedBy,
}: {
  declinedBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  statusReasonMessage: string | null;
  deliveryFailureEmail: string | null;
  reviewedBy: {
    id: number;
    name: string;
  } | null;
  agreementStatusReason: {
    agreementStatusReason: string;
    agreementStatusReasonId: number;
  } | null;
  reviewedOn: string | null;
  voidedBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
}) {
  const { t } = useTranslation();

  const result = useMemo(() => {
    const events: Record<
      AgreementReasons,
      { firstLine: string; secondLine?: string; thirdLine?: string } | null
    > = {
      declined:
        declinedBy && !reviewedBy
          ? {
              firstLine: t('agreement.declined.person', {
                declinedBy:
                  declinedBy?.firstName +
                  ' ' +
                  declinedBy?.lastName +
                  ' (' +
                  declinedBy?.email +
                  ')',
              }),
              secondLine: t('agreement.declined.reason', {
                declinedReason: statusReasonMessage,
              }),
            }
          : null,
      deliveryFailure: deliveryFailureEmail
        ? {
            firstLine: t('agreement.delivery.reason', {
              email: deliveryFailureEmail,
            }),
          }
        : null,
      reviewed:
        reviewedBy &&
        agreementStatusReason?.agreementStatusReason !== 'Review Declined' &&
        !voidedBy
          ? {
              firstLine: t('agreement.reviewed.person', {
                approvedBy: reviewedBy.name,
              }),
              secondLine: t('agreement.approved.time', {
                approvedOn: formatUtcToLocalTime(reviewedOn || ''),
              }),
            }
          : null,
      rejected:
        !voidedBy &&
        reviewedBy?.name &&
        agreementStatusReason?.agreementStatusReason === 'Review Declined'
          ? {
              firstLine: t('agreement.declined.person', {
                declinedBy: reviewedBy?.name,
              }),
              secondLine: t('agreement.declined.reason', {
                declinedReason: statusReasonMessage,
              }),
              thirdLine:
                agreementStatusReason?.agreementStatusReasonId === 2
                  ? t('agreement.rejected.time', {
                      rejectedOn: formatUtcToLocalTime(reviewedOn || ''),
                    })
                  : undefined,
            }
          : null,
      voided: voidedBy
        ? {
            firstLine: t('agreement.voided.person', {
              voidedBy:
                voidedBy?.firstName + ' ' + voidedBy?.lastName + ' (' + voidedBy?.email + ')',
            }),
            secondLine: t('agreement.reason.for.void', {
              voidedReason: statusReasonMessage,
            }),
          }
        : null,
    };

    const res: string[] = [];

    Object.keys(events).forEach((reasonKey) => {
      const reason = events[reasonKey as unknown as AgreementReasons];
      if (reason) {
        res.push(reason.firstLine);
        if (reason.secondLine) {
          res.push(reason.secondLine);
        }
        if (reason.thirdLine) {
          res.push(reason.thirdLine);
        }
      }
    });
    return res;
  }, [
    agreementStatusReason?.agreementStatusReason,
    agreementStatusReason?.agreementStatusReasonId,
    declinedBy,
    deliveryFailureEmail,
    reviewedBy,
    reviewedOn,
    statusReasonMessage,
    t,
    voidedBy,
  ]);

  return (
    <AppTooltip
      title={
        <div>
          {result.map((line) => (
            <TooltipMessage key={line} message={line} />
          ))}
        </div>
      }
      placement="bottom"
      leaveDelay={500}
    >
      <InfoIcon fontSize="inherit" color="disabled" />
    </AppTooltip>
  );
}
