import { AppTextField } from './../AppTextField/index';
import { Box, TablePagination } from '@mui/material';
import styled from 'styled-components';
import { LightSlateGray, Silver } from '../../../Theme/colorsVariables';
import {
  breakPointBig,
  breakPointMedium,
  breakPointPagination,
  breakPointSmall,
} from '../../../utils/MediaBreakPoint';
import { FlexBox } from '../FlexBox';

const CustomBoxPaginationMediaBig = `
font-size: 15px;
`;

const CustomBoxPaginationMediaSmall = `
font-size: 12px;
`;

const CustomBoxMedia = `
justify-content: center;
flex-direction: column;
`;

export const CustomPaginationPageNumberBox = styled(AppTextField)`
  height: 100%;
  div {
    height: 100%;

    input {
      padding: 6px 3px;
      text-align: center;
    }
  }
  ${breakPointMedium(` 
  div {
    input {
      font-size: 12px;
    }
  }`)}
`;
export const CustomBox = styled(FlexBox)`
  align-items: center;
  justify-content: space-between;
  ${breakPointPagination(CustomBoxMedia)}
`;

export const CustomBoxPagination = styled(Box)`
  font-size: 16px;
  color: ${Silver};
  font-family: Gotham-Bold;
  font-weight: 500;
  ${breakPointSmall(`
    display: flex;
    align-items: center;
    justify-content:center;
    width: 100%;
    margin-top: 10px;
    `)}
  ${breakPointBig(CustomBoxPaginationMediaBig)}
  ${breakPointMedium(CustomBoxPaginationMediaSmall)}
`;

export const TablePaginationSMedia = styled(TablePagination)`
  display: none;
  width: 108%;
  border: none;
  div {
    padding-left: 15px;
  }

  .MuiTablePagination-displayedRows {
    display: none;
  }
  .MuiTablePagination-actions {
    display: none;
  }
  ${breakPointPagination('display: block')}
`;

const BoxPaginationMedia = `
display: flex;
width: 100%;
justify-content: space-evenly;
align-items: center;
`;

export const BoxPagination = styled(Box)`
  display: block;
  ${breakPointPagination(BoxPaginationMedia)}
`;

export const HeadingBox = styled(Box)`
  color: ${LightSlateGray};
  font-size: 16px;
  font-family: Gotham-Medium;
`;

export const TableContainer = styled(FlexBox)`
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`;
