import { useForm } from 'react-hook-form';
import { Main } from './styles';
import { AdvancedSearchProps } from '../../../types/customTable';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppActionButton, AppActionButtonsContainer } from '../AppActionButton';
import { FeinBox } from '../../common/styles/feinBox';

export default function AdvancedSearch({
  onSubmit,
  onReset,
  InputsComponent,
}: AdvancedSearchProps) {
  const formMethods = useForm({ mode: 'onChange' });
  const [items, setItems] = useState(false);
  const { t } = useTranslation();
  const watchData = formMethods.watch();
  const {
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (Object.values(watchData).length > 0) {
      const isDataMapped = Object.values(watchData).filter((item) => item !== '').length > 0;
      setItems(isDataMapped);
    }
  }, [watchData]);
  return (
    <Main>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        {InputsComponent({ formMethods })}
        {formMethods.formState.isDirty && !items && (
          <FeinBox>{t('custom.table.advanced.search.message')}</FeinBox>
        )}
        <AppActionButtonsContainer mt={1}>
          <AppActionButton
            variant="outlined"
            type="reset"
            onClick={() => {
              formMethods.reset();
              onReset();
            }}
          >
            {t('action.reset')}
          </AppActionButton>
          <AppActionButton disabled={Object.keys(errors).length !== 0} type="submit">
            {t('action.search')}
          </AppActionButton>
        </AppActionButtonsContainer>
      </form>
    </Main>
  );
}
