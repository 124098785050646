import { FormControl, Grid2, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ContactFormProps,
  ContactFormValues,
  FormSingleSelectValue,
} from '../../../types/sellerGroup';
import { AppTextField } from '../../shared/AppTextField';
import { useTranslation } from 'react-i18next';
import { CancelModal } from '../../modal/CancelModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useDataloss } from '../../../hooks/useDataloss';
import StatesMultiSelect from '../../shared/StateMultiSelect';
import { StateForAutocomplete } from '../../../types';
import { BoxMain } from './styles';
import RenderStateChip from '../../shared/StateChip';
import { useQuery } from '../../../hooks/useQuery';
import { Organizations } from '../../../services/sellerGroupServices';
import { useAllAgents, useSellerListAll } from '../../../services/sellerServices';
import { ChipStyle } from '../../shared/StateChip/styles';
import { TypographySpan } from '../../shared/StateMultiSelect/styles';
import { AppAutocomplete } from '../../shared/AppAutocomplete';
import { AppPhoneField } from '../../shared/AppPhoneField';
import AppAutocompleteSingleSelect from '../../shared/AppAutocompleteSingleSelect';
import AuthService from '../../../services/authService';
import { getCommonErrorMessages } from '../../../utils';
import { boolean, mixed, object, string } from 'yup';
import { trimmedString, commonSelectSchema, emailSchema } from '../../../Resolvers';
import { getCommonMaxLengthValue } from '../../../utils/inputMaxLength';
import { OrganizationService } from '../../../services/organizationService';
import { getFormattedNumber } from 'react-phone-hooks';
import { UserContext } from '../../../context/User';
import AppFakeTextField from '../../shared/AppFakeTextField';
import { AppLink } from '../../shared/CustomTableAddButton/styles';

export default function ContactForm(props: ContactFormProps) {
  const {
    onHandleResponseSubmit,
    cancelPopUp,
    setCancelPopUp,
    isUnsavedData,
    setIsUnsavedData,
    formRef,
    setIsSaveEnabled,
    isEdit,
    contactData,
    setContactStatus,
    contactStatus,
    businessRolesForContacts,
    onwiseRoles,
    setIsEdit,
    customOrgId,
    customOrganization,
    setShowContactsTable,
  } = props;
  const { id: organizationId, orgName } = useParams();

  const { state: addProductLineForm } = useLocation();
  const queryReturnUrl = useQuery('returnUrl');
  const { data: systemConstants } = AuthService.useCommonDetailsQuery();
  const contactOwnerFieldLabel = useMemo(() => {
    return systemConstants?.find((item) => item.type === 'ContactOwner')?.value || 'Contact Owner';
  }, [systemConstants]);
  const organization = useMemo(() => {
    return customOrganization
      ? customOrganization
      : orgName === 'SellerGroup'
      ? Organizations.SellerGroup
      : orgName === 'Agent'
      ? Organizations.Agent
      : orgName === 'Seller'
      ? Organizations.Seller
      : '';
  }, [customOrganization, orgName]);

  const { data: agentsData } = useAllAgents({});
  const { data: orgTenant } = OrganizationService.useTenant({
    enabled: organization !== 'Agent',
  });

  const agentOptions = useMemo(() => {
    return [
      {
        label: `${orgTenant?.legalName}<span class="vl"></span>${getFormattedNumber(
          `${orgTenant?.phoneNumberCountryCode || '1'}${orgTenant?.phoneNumber}`,
        )}`,
        value: orgTenant?.orgId,
      },
      ...(agentsData?.map((agent) => {
        return {
          label: `${agent.agencyName}<span class="vl"></span>${getFormattedNumber(
            `${agent.phoneNumberCountryCode || '1'}${agent.phoneNumber}`,
          )}`,
          value: agent.agencyId,
        };
      }) || []),
    ];
  }, [
    agentsData,
    orgTenant?.legalName,
    orgTenant?.orgId,
    orgTenant?.phoneNumber,
    orgTenant?.phoneNumberCountryCode,
  ]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const user = useContext(UserContext);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [businesRolesData, setBusinessRolesData] = useState<StateForAutocomplete[]>([]);

  const [onWiseRolesData, setONWiseRolesData] = useState<StateForAutocomplete[]>([]);
  const [sellersData, setSellersData] = useState<{ label: string; value: number }[]>([]);

  const { data: sellerGroupSellers } = useSellerListAll({
    sellerGroupId: Number(customOrgId ?? organizationId),
    options: {
      enabled: organization === Organizations.SellerGroup && !!(organizationId || customOrgId),
    },
  });

  useEffect(() => {
    if (businessRolesForContacts) {
      setBusinessRolesData(
        businessRolesForContacts
          .map((el) => ({
            addressStateId: el.roleId,
            label: el.roleName,
            value: el.roleName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    }
    if (onwiseRoles) {
      setONWiseRolesData(
        onwiseRoles
          .map((el) => ({
            addressStateId: el.roleId,
            label: el.roleName,
            value: el.roleName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    }
    if (sellerGroupSellers?.length) {
      setSellersData([
        { label: 'All', value: 0 },
        ...(sellerGroupSellers.map((el) => ({
          label: el.name,
          value: el.id,
        })) || []),
      ]);
    }
  }, [businessRolesForContacts, onwiseRoles, sellerGroupSellers]);

  const formDefaultValues = useMemo(() => {
    const selectedSellers = contactData?.sellerIds
      ? sellersData?.filter((item) => contactData.sellerIds?.includes(item.value))
      : null;
    const contactOwnerDefaultValue =
      user?.userType === 'Agency'
        ? (agentOptions.find((option) => option.value === user.orgId) as FormSingleSelectValue) ||
          null
        : organization !== Organizations.Agent && !!contactData?.contactManagementOrg
        ? (agentOptions.find(
            (option) => option.value === contactData?.contactManagementOrg?.id,
          ) as FormSingleSelectValue) || null
        : null;
    const res: ContactFormValues = {
      firstName: contactData?.firstName || '',
      lastName: contactData?.lastName || '',
      phone: {
        number:
          contactData?.phoneNumber && contactData?.phoneNumberCountryCode
            ? contactData?.phoneNumberCountryCode + contactData?.phoneNumber
            : '',
        countryCode: contactData?.phoneNumberCountryCode || '',
        ext: contactData?.phoneNumberExt || '',
        isValid: true,
      },
      email: contactData?.email || '',
      title: contactData?.title || '',
      contactClassification:
        contactData?.businessRoles?.map((role) => ({
          addressStateId: role.roleId,
          label: role.roleName,
          value: role.roleName,
        })) || [],
      onwiseSystemRole:
        contactData?.onWiseSystemRoles?.map((role) => ({
          addressStateId: role.roleId,
          label: role.roleName,
          value: role.roleName,
        })) || [],
      isActive: !!contactData?.status,
      associatedSellers: selectedSellers
        ? selectedSellers.length === sellersData.length - 1
          ? sellersData
          : selectedSellers
        : sellersData,
      contactOwner: contactOwnerDefaultValue,
    };
    return res;
  }, [agentOptions, contactData, organization, sellersData, user?.orgId, user?.userType]);

  const formMethods = useForm<ContactFormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      object().shape(
        {
          firstName: trimmedString
            .max(
              getCommonMaxLengthValue('firstName'),
              getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('firstName'),
            )
            .required(getCommonErrorMessages('required')),
          lastName: trimmedString
            .max(
              getCommonMaxLengthValue('lastName'),
              getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('lastName'),
            )
            .required(getCommonErrorMessages('required')),
          email: emailSchema,
          title: trimmedString
            .max(
              getCommonMaxLengthValue('title'),
              getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('title'),
            )
            .max(getCommonMaxLengthValue('title')),
          phone: object().shape({
            number: trimmedString,
            countryCode: trimmedString,
            ext: trimmedString,
            isValid: boolean().oneOf([true], getCommonErrorMessages('required')),
          }),
          contactOwner:
            organization !== 'Agent'
              ? commonSelectSchema
              : object({
                  label: string().required(getCommonErrorMessages('required')),
                  value: mixed().nullable(),
                }).nullable(),
        },
        [],
      ),
    ),
    defaultValues: formDefaultValues,
  });

  const { control, handleSubmit, reset, formState, setValue, watch } = formMethods;

  const { errors, isValid, defaultValues } = formState;

  useEffect(() => {
    reset(formDefaultValues);
  }, [formDefaultValues, reset]);

  const watchAllFields = watch();

  useEffect(() => {
    setValue('isActive', contactStatus);
  }, [setValue, contactStatus]);

  const {
    contactClassification: selectedBusinessRoles,
    onwiseSystemRole: selectedONWiseRoles,
    associatedSellers,
  } = watchAllFields;

  //INFO: checking for unsaved changes to disableaction buttons
  useEffect(() => {
    const isValuesEqual = isEqual(defaultValues, watchAllFields);
    setIsUnsavedData(!isValuesEqual);

    setIsSaveEnabled(isValid ? isValuesEqual === isValid : true);
  }, [defaultValues, errors, isValid, setIsSaveEnabled, setIsUnsavedData, watchAllFields]);

  const handleReset = useCallback(() => {
    reset(formDefaultValues);
    window.scrollTo(0, 0);
  }, [formDefaultValues, reset]);

  const onSubmit: SubmitHandler<ContactFormValues> = async (data) => {
    if (isValid) {
      onHandleResponseSubmit({
        ...data,
        orgId: Number(organizationId),
        contactOwner:
          organization === Organizations.Agent
            ? { value: Number(organizationId), label: '' }
            : data.contactOwner,
        associatedSellers: data.associatedSellers.filter((item) => item.label !== 'All'),
      });
    }
  };
  const onDiscardChanges = useCallback(() => {
    if (setShowContactsTable) {
      setShowContactsTable(true);
      return;
    }
    if ((organizationId || customOrgId) && setIsEdit) {
      setIsEdit(false);
      setCancelPopUp(false);
      if (setContactStatus) setContactStatus(!!contactData?.status);
      handleReset();
    } else {
      if (queryReturnUrl) {
        navigate(queryReturnUrl, { state: addProductLineForm });
      } else {
        if (organization === Organizations.SellerGroup) {
          navigate(`/dashboard/SellerGroup/${organizationId}/Contact`);
        }
        if (organization === Organizations.Agent) {
          navigate(`/dashboard/Agent/${organizationId}/Contact`);
        }
        if (organization === Organizations.Seller) {
          navigate(`/dashboard/Seller/${organizationId}/Contact`);
        }
      }
    }
  }, [
    addProductLineForm,
    contactData?.status,
    customOrgId,
    handleReset,
    organizationId,
    navigate,
    organization,
    queryReturnUrl,
    setCancelPopUp,
    setContactStatus,
    setIsEdit,
    setShowContactsTable,
  ]);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [formRef, setCancelPopUp]);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, [setCancelPopUp]);

  useDataloss(isUnsavedData && isEdit, onDiscardChanges, onSaveChanges, onExit);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset} ref={formRef}>
          <Grid2 container spacing={isSmall ? 1 : 2} mt={2}>
            <Grid2 size={{ xs: 6, md: 4 }}>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    label={t('formField.firstname')}
                    required={isEdit}
                    disabled={!isEdit}
                    errorMessage={errors.firstName?.message}
                    error={!!errors.firstName}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 4 }}>
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    label={t('formField.lastname')}
                    required={isEdit}
                    disabled={!isEdit}
                    errorMessage={errors.lastName?.message}
                    error={!!errors.lastName}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 4 }}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    type="email"
                    label={t('formField.email')}
                    required={isEdit}
                    disabled={!isEdit || !!contactData?.userId}
                    errorMessage={errors.email?.message}
                    error={!!errors.email}
                  />
                )}
              />
            </Grid2>
            {organization !== Organizations.Agent && user?.userType !== 'Agency' && (
              <Grid2 size={{ xs: 6, md: 4 }}>
                <AppAutocompleteSingleSelect
                  control={control as unknown as Control<FieldValues>}
                  name={'contactOwner'}
                  label={contactOwnerFieldLabel}
                  options={agentOptions}
                  disabled={!isEdit}
                  rules={{
                    required: isEdit,
                  }}
                  errorMessage={
                    isEdit
                      ? errors.contactOwner?.message || errors.contactOwner?.label?.message
                      : ''
                  }
                />
              </Grid2>
            )}
            {organization !== Organizations.Agent && user?.userType !== 'Agency' && (
              <Grid2 size={{ md: 8, xs: 12 }} pt={'0px !important'}></Grid2>
            )}
            <Grid2 size={{ xs: 6, md: 4 }}>
              <AppPhoneField required={false} disabled={!isEdit} />
            </Grid2>
            {user?.userType === 'Provider' && organization === 'Agent' && (
              <Grid2 size={{ xs: 12, md: 4 }}>
                <AppFakeTextField
                  label={t('formField.userId')}
                  value={
                    <AppLink to={`/dashboard/User/${contactData?.userId}`} target="_blank">
                      {contactData?.userId}
                    </AppLink>
                  }
                />
              </Grid2>
            )}
            <Grid2
              size={
                user?.userType === 'Provider' && organization === 'Agent'
                  ? { md: 4, xs: 0 }
                  : { md: 8, xs: 12 }
              }
              m={0}
              p={0}
            ></Grid2>
            <Grid2 size={{ md: 4, xs: 12 }}>
              <Controller
                control={control}
                name="title"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    label={t('formField.title')}
                    disabled={!isEdit}
                    errorMessage={errors.title?.message}
                    error={!!errors.title}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 4 }}>
              <Controller
                name="contactClassification"
                control={control}
                defaultValue={[]}
                render={() => (
                  <FormControl fullWidth>
                    <StatesMultiSelect<StateForAutocomplete>
                      label={t('formField.contactClassification')}
                      disabled={!isEdit}
                      originalData={businesRolesData}
                      selectedData={selectedBusinessRoles}
                      handleChangeSelected={(data) => {
                        setValue('contactClassification', data);
                      }}
                    />
                    {selectedBusinessRoles.length > 0 && (
                      <BoxMain style={{ pointerEvents: isEdit ? 'all' : 'none' }}>
                        {selectedBusinessRoles.map(({ addressStateId, label }, index) => (
                          <RenderStateChip
                            key={addressStateId}
                            label={label}
                            ind={index}
                            selectedArray={selectedBusinessRoles}
                            setSelected={(newData: StateForAutocomplete[]) =>
                              setValue('contactClassification', newData)
                            }
                          />
                        ))}
                      </BoxMain>
                    )}
                  </FormControl>
                )}
              />
            </Grid2>

            <Grid2 size={{ xs: 6, md: 4 }}>
              <Controller
                name="onwiseSystemRole"
                control={control}
                defaultValue={[]}
                render={() => (
                  <FormControl fullWidth>
                    <StatesMultiSelect<StateForAutocomplete>
                      label={t('formField.onwiseSystemRole')}
                      disabled={!isEdit}
                      originalData={onWiseRolesData}
                      selectedData={selectedONWiseRoles}
                      handleChangeSelected={(data) => {
                        setValue('onwiseSystemRole', data);
                      }}
                    />
                    <BoxMain style={{ pointerEvents: isEdit ? 'all' : 'none' }}>
                      {selectedONWiseRoles.length > 0 &&
                        selectedONWiseRoles.map(({ addressStateId, label }, index) => (
                          <RenderStateChip
                            key={addressStateId}
                            label={label}
                            ind={index}
                            selectedArray={selectedONWiseRoles}
                            setSelected={(newData: StateForAutocomplete[]) =>
                              setValue('onwiseSystemRole', newData)
                            }
                          />
                        ))}
                    </BoxMain>
                  </FormControl>
                )}
              />
            </Grid2>
            {!!selectedONWiseRoles.length && organization === Organizations.SellerGroup && (
              <Grid2 size={{ xs: 6, md: 4 }}>
                <Controller
                  control={control}
                  name={'associatedSellers'}
                  render={({ field }) => (
                    <AppAutocomplete
                      {...field}
                      disablePortal
                      value={field.value}
                      options={sellersData}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disabled={!isEdit || !selectedONWiseRoles.length}
                      renderInput={(params) => (
                        <AppTextField
                          {...params}
                          label={t('formField.sellers')}
                          errorMessage={errors.associatedSellers?.message}
                          error={!!errors.associatedSellers?.message}
                        />
                      )}
                      onChange={(e, data, reason, details) => {
                        const value = data as { label: string; value: number }[];
                        if (
                          (reason === 'removeOption' && details?.option.label === 'All') ||
                          (reason === 'removeOption' &&
                            value.length === 1 &&
                            value[0].label === 'All')
                        ) {
                          field.onChange([]);
                          return;
                        }

                        if (
                          (reason === 'selectOption' && details?.option.label === 'All') ||
                          (reason === 'selectOption' &&
                            value.length === sellersData.length - 1 &&
                            value.indexOf({ label: 'All', value: 0 }) === -1)
                        ) {
                          field.onChange(sellersData);
                          return;
                        }

                        if (reason === 'removeOption' && value.length === sellersData.length - 1) {
                          field.onChange(value.filter((item) => item.label !== 'All'));
                          return;
                        }
                        field.onChange(value);
                      }}
                      multiple
                      disableCloseOnSelect
                      getOptionDisabled={() => !isEdit}
                      filterOptions={(_options, state) => {
                        const filtered = sellersData?.filter((item) =>
                          item.label.toLowerCase().includes(state.inputValue.toLowerCase()),
                        );

                        return filtered || [];
                      }}
                      renderTags={(data) => (
                        <TypographySpan>
                          {t('multiple.select.label', {
                            selectedCount: data.filter((item) => item.label !== 'All').length,
                            allCount: sellersData?.length - 1 || 0,
                          })}
                        </TypographySpan>
                      )}
                      disableClearable={!isEdit}
                    />
                  )}
                />

                <BoxMain>
                  {associatedSellers.map(({ value, label }) => (
                    <ChipStyle
                      sx={{
                        '& .MuiChip-deleteIcon, .MuiChip-deleteIcon:hover': {
                          color: '#000000',
                        },
                      }}
                      style={{
                        pointerEvents: isEdit && !!selectedONWiseRoles.length ? 'all' : 'none',
                      }}
                      key={value}
                      label={label}
                      onDelete={() => {
                        if (label === 'All') {
                          setValue('associatedSellers', []);
                        } else {
                          setValue(
                            'associatedSellers',
                            associatedSellers.filter(
                              (item) => item.value !== value && item.label !== 'All',
                            ),
                          );
                        }
                      }}
                    />
                  ))}
                </BoxMain>
              </Grid2>
            )}
          </Grid2>
        </form>
      </FormProvider>
      <CancelModal
        isOpened={cancelPopUp}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardChanges}
        onSaveChanges={onSaveChanges}
        onExit={onExit}
      />
    </>
  );
}
