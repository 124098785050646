import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useTranslation } from 'react-i18next';
import { AppActionButton, AppActionButtonProps } from '../AppActionButton';

export const AppCancelButton: React.FC<AppActionButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <AppActionButton {...props} variant="outlined" startIcon={<ClearOutlinedIcon />}>
      {t('action.cancel')}
    </AppActionButton>
  );
};
