import { BaseObject } from 'styled-components/dist/types';

export function breakPointSmall(data: BaseObject) {
  return `@media only screen and (max-width: 900px) {
      ${data}
  }`;
}

export function breakPointLarge(data: string) {
  return `@media only screen and (max-width: 1920px){
    ${data}
  }`;
}

export function breakPointMedium(data: string) {
  return `@media only screen and (max-width: 1500px) {
      ${data}
  }`;
}

export function breakPointLaptop(data: string) {
  return `@media only screen and (max-width: 1231px){
    ${data}
  }`;
}

export function breakPointBig(data: string) {
  return `@media only screen and (max-width: 1153px) {
      ${data}
  }`;
}

export function breakPointPagination(data: string) {
  return `@media only screen and (max-width: 1280px) {
    ${data}
}`;
}

export function breakPointPaginationTop(data: string) {
  return `@media only screen and (max-width: 1024px) {
    ${data}
}`;
}
