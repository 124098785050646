import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalButtonsBox } from '../Modal/styles';
import { QuickConfirmationModalContext } from '../../../context/QuickModal';
import { ModalMessageText } from '../CancelModal/styles';

export default function QuickConfirmationModal() {
  const { t } = useTranslation();
  const { quickModal } = useContext(QuickConfirmationModalContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(quickModal.isOpen);
  }, [quickModal]);

  return (
    <CustomModal open={isOpen} containerWidth="600px" modalTitle={quickModal.modalTitle}>
      <>
        <ModalMessageText sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: quickModal.message }} />
        <ModalButtonsBox>
          <AppActionButton
            variant="outlined"
            onClick={() => {
              setIsOpen(false);
              if (quickModal.yesCallBack) quickModal.yesCallBack();
            }}
          >
            {t('action.ok')}
          </AppActionButton>
        </ModalButtonsBox>
      </>
    </CustomModal>
  );
}
