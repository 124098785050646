import React from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { BusinessRoleParams, SellerInformation } from '../../types';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from 'react-query';
import { SellerPage } from '../Seller';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';

export interface ViewSellerOutletContext {
  sellerInformation: SellerInformation | undefined;
  isEdit: boolean;
  voidAgreement: boolean;
  businessRolesForContacts: BusinessRoleParams[] | undefined;
  isCancel: boolean;
  refetchSellerInfo: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<SellerInformation, Error>>;
  onChangeProduct: (data: any) => void;
}

export const ProtectedSellerPage = () => {
  const { t } = useTranslation();
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);

  if (!isViewSellerPermission) {
    return <NoPermissionMessage message={t('restrictions.noPermissions')} />;
  }

  return <SellerPage />;
};
