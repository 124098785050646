import { Grid2 } from '@mui/material';
import { StyledTypography } from '../../Forms/RoleForm/noPermissionsText';

export const NoPermissionMessage = ({ message }: { message: string }) => {
  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }}>
        <StyledTypography>{message}</StyledTypography>
      </Grid2>
    </Grid2>
  );
};
