import React, { useContext } from 'react';
import { StyledInnerBox, StyledBox } from './styles';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/User';
import AppTooltip from '../shared/AppTooltip';

export default function CustomAvatar({
  handleClick,
}: {
  handleClick: React.MouseEventHandler<HTMLDivElement>;
}) {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <AppTooltip title={t('myProfile.title')}>
      <StyledBox id={t('myProfile.title')} onClick={handleClick}>
        {user?.name ? (
          <StyledInnerBox>{`${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`}</StyledInnerBox>
        ) : (
          <img src="/assets/user_profile_ic.svg" alt="avatar" />
        )}
      </StyledBox>
    </AppTooltip>
  );
}
