import React from 'react';
import { SidebarType } from '../../../types';
import sidebarItems from './items';
import NavItem from '../NavItem';
import { SidebarItemProps } from './types';
import { SideItemsList } from '../styles';

export default function SidebarItem({ isDrawerOpen, activeColor, onClose }: SidebarItemProps) {
  return (
    <SideItemsList>
      {sidebarItems().map((item: SidebarType) => {
        return (
          <NavItem
            section="outer"
            activeColor={activeColor}
            key={item.text}
            item={item}
            level={1}
            isDrawerOpen={isDrawerOpen}
            onClose={onClose}
          />
        );
      })}
    </SideItemsList>
  );
}
