import styled from 'styled-components';
import { FlexBox } from '../../shared/FlexBox';

export const BoxMain = styled(FlexBox)`
  max-height: 130px;
  overflow-y: auto;
  margin-top: 12px;
  flex-wrap: wrap;
  gap: 10px;
`;
