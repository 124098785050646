import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteEntityModal from '../../components/modal/DeleteEntityModal';
import ContactForm from '../../components/Forms/ContactForm';
import { AppActionButton } from '../../components/shared/AppActionButton';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import { AppDeleteButton } from '../../components/shared/AppDeleteButton';
import { AppEditButton } from '../../components/shared/AppEditButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { FlexBox } from '../../components/shared/FlexBox';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { useToast } from '../../hooks/useToast';
import {
  useOrganizationContactDelete,
  useOrganizationContactUpdate,
} from '../../services/sellerGroupContactsService';
import {
  Organizations,
  useOrganizationContact,
  useOrganizationInfo,
} from '../../services/sellerGroupServices';
import RoleType from '../../types/businessRoles';
import {
  ContactFormValues,
  ContactPayload,
  ContactResponse,
  ContactRole,
} from '../../types/sellerGroup';
import { AppStatusButton, SecondHeaderBox } from './styles';
import ContactStatusModal from '../../components/modal/ContactStatusModal';
import { White } from '../../Theme/colorsVariables';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';
import { useAppRolesByType } from '../../services/sellerServices';
import { OrganizationContact } from '../../types/sellerGroupContacts';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import { ApiError, User } from '../../types';
import { ModificationLog } from '../../components/ModificationLog';
import { getPhonePayload } from '../../components/shared/AppPhoneField';
import AppTooltip from '../../components/shared/AppTooltip';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';
import { useQueryClient } from 'react-query';

export const OrganizationContactInfoPage = ({
  customContactId,
  customOrgId,
  setShowContactsTable,
  customFormRef,
  setContactToEdit,
  customOrganization,
}: {
  customOrgId?: string;
  customOrganization?: Organizations;
  customContactId?: string;
  setShowContactsTable?: Dispatch<SetStateAction<boolean>>;
  setContactToEdit?: Dispatch<SetStateAction<OrganizationContact | undefined>>;
  customFormRef?: React.RefObject<HTMLFormElement>;
}) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const location = useLocation();
  const toast = useToast();
  const { id: urlOrgId, contactId: urlContactId, orgName } = useParams();
  const { setQuickModal } = useContext(QuickConfirmationModalContext);
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const id = useMemo(() => {
    return customOrgId ? customOrgId : urlOrgId;
  }, [customOrgId, urlOrgId]);
  const contactId = useMemo(() => {
    return customContactId ? customContactId : urlContactId;
  }, [customContactId, urlContactId]);

  const isReadContactPermission = usePermissions(PermissionKeys.ReadContact);
  const isUpdateContactPermission = usePermissions(PermissionKeys.UpdateContact);
  const isDeleteContactPermission = usePermissions(PermissionKeys.DeleteContact);
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const isViewAgentPermission = usePermissions(PermissionKeys.ReadAgent);
  const isCreateAgentUserPermission = usePermissions(PermissionKeys.CreateAgentUser);

  const navigate = useNavigate();
  const organization = useMemo(() => {
    return customOrganization
      ? customOrganization
      : orgName === 'SellerGroup'
      ? Organizations.SellerGroup
      : orgName === 'Agent'
      ? Organizations.Agent
      : orgName === 'Seller'
      ? Organizations.Seller
      : '';
  }, [customOrganization, orgName]);

  const isViewOrganizationPermission =
    organization === Organizations.SellerGroup || organization === Organizations.Seller
      ? isViewSellerPermission
      : organization === Organizations.Agent
      ? isViewAgentPermission
      : false;

  const { data: organizationData } = useOrganizationInfo({
    id: Number(id),
    isEnabled: isViewOrganizationPermission && isReadContactPermission,
    organisation: organization,
  });

  const { data: organisationContactData, refetch } = useOrganizationContact({
    id: Number(contactId),
    isEnabled: isReadContactPermission && isViewOrganizationPermission,
  });
  const { data: businessRolesForContacts } = useAppRolesByType(RoleType.business, {
    enabled: isViewOrganizationPermission && isReadContactPermission,
  });
  const { data: agencyRolesForContacts } = useAppRolesByType(RoleType.agencyUser, {
    enabled: isViewOrganizationPermission && isReadContactPermission,
  });
  const { data: onwiseRoles } = useAppRolesByType(RoleType.onWiseUser, {
    enabled: isViewOrganizationPermission && isReadContactPermission,
  });
  const initialFormRef = useRef<HTMLFormElement>(null);
  const formRef = useMemo(() => {
    return customFormRef ? customFormRef : initialFormRef;
  }, [customFormRef]);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [cancelPopUp, setCancelPopUp] = useState<boolean>(false);
  const [isUnsavedData, setIsUnsavedData] = useState<boolean>(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [popUp, setPopUp] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [statusMessage, setStatusMessage] = useState<string>('');

  useEffect(() => {
    if (contactId && organisationContactData) {
      setIsActive(organisationContactData.status);
      const contactStatus = organisationContactData.status
        ? t('action.inactivate').toLowerCase()
        : t('action.activate').toLowerCase();
      setStatusMessage(
        t('relationships.organization.statuschange.warning', {
          contactStatus: contactStatus,
          contactName: organisationContactData?.firstName + ' ' + organisationContactData?.lastName,
        }),
      );
    }
  }, [contactId, organisationContactData, t]);

  const handleConfirm = useCallback(() => {
    setIsActive(!!!organisationContactData?.status);
    setPopUp(false);
  }, [organisationContactData]);

  const closeModal = useCallback(() => {
    setPopUp(false);
  }, [setPopUp]);

  const onSuccessUpdateContact = (res: ContactResponse) => {
    toast.success(res.message);
    refetch();
    setIsEdit(false);

    navigateAfterDatalossDecision();
  };

  const { mutate: updateContact, isLoading: isUpdateContactLoading } = useOrganizationContactUpdate(
    {
      onSuccess: (res) => {
        queryClient.removeQueries({
          queryKey: ['organizationContacts'],
        });
        onSuccessUpdateContact(res);
      },
      onError(err, variables) {
        if (err.response.data.code === 40029) {
          setQuickModal({
            isOpen: true,
            message: t(
              organization !== 'Agent' ? 'error.contact.duplicate' : 'error.agentContact.duplicate',
              {
                firstName: variables.payload.contact.firstName,
                lastName: variables.payload.contact.lastName,
                email: variables.payload.contact.email,
                contactOwnerName: variables.additionalInfo.contactOwnerName,
              },
            ),
            modalTitle: 'Duplicates!',
          });
        } else {
          toast.error(err.response.data.message);
        }
      },
    },
  );

  const handleSubmitForm = (data: ContactFormValues) => {
    const payload: ContactPayload = {
      payload: {
        orgId: Number(id),
        orgType: 4,
        contactManagementOrgId:
          organization !== Organizations.Agent ? Number(data.contactOwner?.value) : null,

        contact: {
          businessRoles:
            data.contactClassification.map<ContactRole>((role) => ({
              roleId: role.addressStateId,
              description: role.value,
              roleName: role.label,
            })) || [],
          contactId: organisationContactData?.contactId,
          firstName: data.firstName,
          lastName: data.lastName,
          ...getPhonePayload(data.phone),
          email: data.email,
          title: data.title,
          status: isActive,
          onWiseSystemRoles:
            data.onwiseSystemRole.map<ContactRole>((role) => ({
              roleId: role.addressStateId,
              description: role.value,
              roleName: role.label,
            })) || [],
          ...(organization === Organizations.SellerGroup && !!data.onwiseSystemRole.length
            ? { sellerIds: data.associatedSellers.map((item) => item.value) || null }
            : {}),
        },
      },
      additionalInfo: {
        contactOwnerName: (data.contactOwner?.label || '').slice(
          0,
          data.contactOwner?.label.indexOf('<span'),
        ),
      },
    };
    updateContact(payload);
  };

  const navigateToContactsPageForCurrentOrganization = useCallback(() => {
    if (setShowContactsTable) {
      setShowContactsTable(true);
      return;
    }
    if (organization === Organizations.SellerGroup) {
      navigate(`/dashboard/SellerGroup/${id}/Contact`);
    } else if (organization === Organizations.Agent) {
      navigate(`/dashboard/Agent/${id}/Contact`);
    } else if (organization === Organizations.Seller) {
      navigate(`/dashboard/Seller/${id}/Contact`);
    }
  }, [id, navigate, organization, setShowContactsTable]);

  const onSuccessDeleteContact = () => {
    navigateToContactsPageForCurrentOrganization();
    if (setContactToEdit) {
      setContactToEdit(undefined);
    }
    if (setShowContactsTable) {
      setShowContactsTable(false);
    }
  };

  const onErrorDeleteContact = (error: ApiError) => {
    setIsDeleteModalOpen(false);
    setQuickModal({ isOpen: true, modalTitle: 'Error', message: error.response.data.message });
  };

  const { mutate: deleteContact } = useOrganizationContactDelete(() => {
    queryClient.removeQueries({
      queryKey: ['organizationContacts'],
    });
    onSuccessDeleteContact();
  }, onErrorDeleteContact);

  const handleDeleteContact = () => {
    setIsDeleteModalOpen(false);
    if (
      organization === Organizations.Agent &&
      organisationContactData?.isAssociatedWithAgreement
    ) {
      setQuickModal({
        isOpen: true,
        message: t('agent.contact.existingAgreements.delete.message'),
        modalTitle: 'Warning',
      });
    } else {
      deleteContact(Number(contactId));
    }
  };

  const createUserBasedOnContact = () => {
    if (organisationContactData) {
      const payload: User = {
        firstName: organisationContactData?.firstName,
        lastName: organisationContactData?.lastName,
        email: organisationContactData?.email,
        phoneNumber: organisationContactData?.phoneNumber || '',
        phoneNumberCountryCode: organisationContactData?.phoneNumberCountryCode || '1',
        phoneNumberExt: organisationContactData?.phoneNumberExt || '',
        title: organisationContactData.title,
        applicationRoleIds: [],
        authUserId: '',
        contactClassification: organisationContactData.businessRoles.map((role) => role.roleId),
        invitationlinkSentTime: null,
        isUserVerified: false,
        legalName: organizationData?.orgName || '',
        loggedInUser: 0,
        orgId: Number(urlOrgId),
        orgTypeName: 'Agency',
        profilePic: null,
        signupInvitationlink: null,
        status: organisationContactData.status ? 1 : 0,
        userId: 0,
        permissionSetIds: [],
        restrictAccessToOwnAgents: false,
        createdOn: undefined,
        createdBy: undefined,
        updatedOn: undefined,
        updatedBy: undefined,
        isParentOrgRelationshipsManager: false,
      };
      navigate('/dashboard/User/Add', { state: { payload, returnUrl: location.pathname } });
    }
  };

  return (
    <>
      {!customContactId && (
        <PageHeaderContentHost
          title={
            organization === Organizations.SellerGroup
              ? 'Seller Group'
              : organization === Organizations.Agent
              ? 'Agent'
              : organization === Organizations.Seller
              ? 'Seller'
              : ''
          }
          name={
            organizationData?.legalName ? organizationData?.legalName : organizationData?.orgName
          }
          status={organizationData?.status}
        />
      )}
      <SecondHeaderBox mt={1}>
        <PageHeaderContentHost
          title="Contact"
          name={
            (organisationContactData?.firstName || '') +
            ' ' +
            (organisationContactData?.lastName || '')
          }
          status={isActive}
          color={`${White}`}
        />
        {isEdit ? (
          <AppStatusButton
            onClick={() => {
              setPopUp(true);
            }}
          >
            {isActive ? t('action.inactivate') : t('action.activate')}
          </AppStatusButton>
        ) : (
          <></>
        )}
        {!isEdit ? (
          // View action buttons
          <FlexBox>
            <AppActionButton
              onClick={() => {
                if (setShowContactsTable) {
                  setShowContactsTable(true);
                  return;
                }
                navigateToContactsPageForCurrentOrganization();
              }}
            >
              {t('action.close')}
            </AppActionButton>

            {organization === Organizations.Agent && (
              <AppActionButton
                onClick={createUserBasedOnContact}
                disabled={!isCreateAgentUserPermission || !!organisationContactData?.userId}
              >
                {t('action.createUser')}
              </AppActionButton>
            )}

            <AppTooltip title={!isUpdateContactPermission && 'no permission'}>
              <span>
                <AppEditButton
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                  disabled={!isUpdateContactPermission}
                />
              </span>
            </AppTooltip>

            <AppDeleteButton
              onClick={() => setIsDeleteModalOpen(true)}
              disabled={!isDeleteContactPermission}
            />

            <DeleteEntityModal
              open={isDeleteModalOpen}
              setOpen={setIsDeleteModalOpen}
              entityName={
                (organisationContactData?.firstName || '') +
                ' ' +
                (organisationContactData?.lastName || '')
              }
              entityType={
                organization === Organizations.SellerGroup
                  ? 'Seller Group Contact'
                  : organization === Organizations.Agent
                  ? 'Agent Contact'
                  : organization === Organizations.Seller
                  ? 'Seller Contact'
                  : ''
              }
              onConfirm={handleDeleteContact}
              userId={organisationContactData?.userId || undefined}
              isContactAssociatedWithAgreements={organisationContactData?.isAssociatedWithAgreement}
            />
          </FlexBox>
        ) : (
          //Edit action buttons
          <FlexBox>
            {!isEdit && (
              <AppActionButton
                onClick={() => {
                  if (setShowContactsTable) {
                    setShowContactsTable(true);
                    return;
                  }
                  navigateToContactsPageForCurrentOrganization();
                }}
              >
                {t('action.close')}
              </AppActionButton>
            )}

            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setCancelPopUp(true);
                } else {
                  setIsEdit(false);
                }
              }}
            />
            <AppResetButton
              onClick={() => {
                setIsActive(!!organisationContactData?.status);
                formRef.current?.dispatchEvent(
                  new Event('reset', { cancelable: true, bubbles: true }),
                );
              }}
            />
            <AppSaveButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }}
              disabled={isSaveEnabled || isUpdateContactLoading}
            />
          </FlexBox>
        )}
      </SecondHeaderBox>
      {organisationContactData && (
        <>
          <ContactForm
            isEdit={isEdit}
            contactStatus={isActive}
            setContactStatus={setIsActive}
            setIsEdit={setIsEdit}
            contactData={organisationContactData}
            onHandleResponseSubmit={handleSubmitForm}
            cancelPopUp={cancelPopUp}
            setCancelPopUp={setCancelPopUp}
            setIsUnsavedData={setIsUnsavedData}
            isUnsavedData={isUnsavedData}
            isSaveEnabled={isSaveEnabled}
            setIsSaveEnabled={setIsSaveEnabled}
            formRef={formRef}
            businessRolesForContacts={
              organization === Organizations.Agent
                ? agencyRolesForContacts
                : businessRolesForContacts
            }
            onwiseRoles={onwiseRoles}
            customOrgId={customOrgId}
            customOrganization={organization}
          />

          {!isEdit && (
            <ModificationLog
              {...{
                createdBy: organisationContactData.createdBy,
                createdOn: organisationContactData.createdOn,
                updatedBy: organisationContactData.updatedBy,
                updatedOn: organisationContactData.updatedOn,
              }}
            />
          )}

          <ContactStatusModal
            popUp={popUp}
            handleYes={handleConfirm}
            handleModal={closeModal}
            statusChangeMessage={statusMessage}
          />
        </>
      )}
    </>
  );
};

export const ProtectedOrganizationContactInfoPage = () => {
  const { orgName } = useParams();
  const { t } = useTranslation();

  const isReadContactPermission = usePermissions(PermissionKeys.ReadContact);
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const isViewAgentPermission = usePermissions(PermissionKeys.ReadAgent);

  const organization = useMemo(() => {
    return orgName === 'SellerGroup'
      ? Organizations.SellerGroup
      : orgName === 'Agent'
      ? Organizations.Agent
      : orgName === 'Seller'
      ? Organizations.Seller
      : '';
  }, [orgName]);

  const isViewOrganizationPermission =
    organization === Organizations.SellerGroup || organization === Organizations.Seller
      ? isViewSellerPermission
      : organization === Organizations.Agent
      ? isViewAgentPermission
      : false;

  if (!isReadContactPermission || !isViewOrganizationPermission) {
    return <NoPermissionMessage message={t('relationships.organisation.view.permission')} />;
  }
  return <OrganizationContactInfoPage />;
};
