import React, { ReactNode } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { QuickTableFilter } from '../components/shared/CustomTableFilters/interface';
import { CellText } from '../components/shared/CustomTableRows/types';
import { DocumentsData } from './document';
import { Order } from './query-params';
import { AuthUser } from './user';
import { TableCellProps } from '@mui/material';

enum CellType {
  Link = 'link',
  Info = 'info',
  StatusFilter = 'status',
  DocuSign = 'docusign',
  Password = 'password',
  Action = 'action',
}

enum AdvancedSearchFieldType {
  Text = 'text',
  Number = 'number',
  Phone = 'phone',
  Select = 'select',
  SelectMulti = 'selectMulti',
  StateSelect = 'stateSelect',
  EmptySpace = 'emptySpace',
}

export enum NewLinkState {
  active = 'active',
  disabled = 'disabled',
}
export interface AddNewLink {
  link: string;
  state?: NewLinkState;
}
interface InputsComponentProps {
  formMethods: UseFormReturn<FieldValues, object>;
}

interface AdvancedSearchInputProps extends InputsComponentProps {
  advancedSearchInputParams: AdvancedSearchParam<any>[];
  children?: ReactNode;
}

interface DisplayOptions {
  tableOnly: boolean;
  paginationTop: boolean;
  paginationBottom: boolean;
}

interface CustomTableProps {
  children?: ReactNode;
  page: number;
  rowsPerPage: number;
  data: Cell[][];
  valueRef: any;
  goError: boolean;
  selectArray?: QuickTableFilter[];
  isAdvanceSearch?: boolean;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleRowsPerPageChange: (page: number) => void;
  rowsPerPageOptions?: number[];
  tableHeadingsArray: Record<string, string>[];
  advancedSearchVisibility: boolean;
  setAdvancedSearchVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setPagination: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitAdvancedSearch: (data: any) => void;
  onResetAdvancedSearch: () => void;
  searchValue: string;
  handleSearchValue: (value: string) => void;
  setPage: (value: number) => void;
  tableTitle: string;
  placeHolder: string;
  addNewLink: AddNewLink;
  order: Order;
  err?: boolean;
  orderBy: string;
  handleRequestSort: (order: Order, orderBy: string) => void;
  InputsComponent: (props: InputsComponentProps) => JSX.Element;
  isDataLoading: boolean;
  total: number;
  isDataPagination?: boolean;
  openAdvancedSearch: (data: any) => void;
  displayOptions?: Partial<DisplayOptions>;
  noResultsMessage?: string;
  searchInfoMessage?: string;
  hideMainSearch?: boolean;
  unsortableColumns?: string[];
  extraHeightVar?: number;
}

interface CustomTableFiltersProps {
  advancedSearchVisibility: boolean;
  selectArray?: QuickTableFilter[];
  setAdvancedSearchVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setPagination: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitAdvancedSearch: (data: any) => void;
  onResetAdvancedSearch: () => void;
  searchValue: string;
  handleSearchValue: (value: string) => void;
  isAdvanceSearch?: boolean;
  page: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleRowsPerPageChange: (page: number) => void;
  goError: boolean;
  rowsPerPageOptions: number[];
  valueRef: any;
  setPage: (value: number) => void;
  paginationTop: boolean;
  total: number;
  pageTotal: number;
  data: any;
  err?: boolean;
  rowsPerPage: number;
  tableTitle: string;
  placeHolder: string;
  addNewLink: AddNewLink;
  InputsComponent: (props: InputsComponentProps) => JSX.Element;
  openAdvancedSearch: (data: any) => void;
  searchInfoMessage?: string;
  hideMainSearch?: boolean;
}

interface CustomTableHeadProps {
  onRequestSort: (order: Order, orderBy: string) => void;
  order: Order;
  orderBy: string;
  heading: Record<string, string>[];
  unsortableColumns?: Array<string>;
  tableHeadCellProps?: TableCellProps[];
}

interface DocuSignTableData {
  callbackOnEdit: () => void;
  editAgreement: boolean;
  addAgreement: boolean;
  viewAgreement: boolean;
  deleteAgreement: boolean;
  downloadAgreement: boolean;
  callbackOnAdd: () => void;
  callbackOnView: () => void;
  callbackOnDelete: () => void;
  callbackOnDownload: () => void;
  agreement: DocumentsData;
  title: string;
}

interface PasswordTableData {
  callbackPassword: () => void;
  rawUserData: AuthUser;
}

interface Cell {
  data:
    | number
    | string
    | undefined
    | null
    | CellText
    | DocuSignTableData
    | string[]
    | PasswordTableData
    | JSX.Element;
  type: CellType;
  linkTo?: string;
  defaultStatus?: string;
  styles?: React.CSSProperties;
  tableCellProps?: TableCellProps;
}

interface CustomTableRowsProps {
  page: number;
  rowsPerPage: number;
  columns: Cell[][];
  isDataPagination?: boolean;
}

interface CustomTablePaginationProps {
  page: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleRowsPerPageChange: (page: number) => void;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  goError: boolean;
  total: number;
  valueRef: any;
  setPage: (value: number) => void;
  data: any;
  pageTotal: number;
}

interface AdvancedSearchProps {
  onSubmit: (data: FieldValues) => void;
  onReset: () => void;
  InputsComponent: (props: InputsComponentProps) => JSX.Element;
  err?: boolean;
}

interface SingleSearchProps {
  searchValue: string;
  isAdvanceSearch?: boolean;
  setSearchValue: (value: string) => void;
  setAdvancedSearchVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setPagination: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder: string;
  openAdvancedSearch: (data: any) => void;
  setPage: (value: number) => void;
  total: number;
  tableTitle: string;
  advancedSearchVisibility: boolean;
}
interface SelectItem<T = string> {
  value: T;
  text: string;
}

interface MultiSelectData<T = any> {
  name: string;
  selects: SelectItem<T>[];
}

interface AdvancedSearchParam<T = Array<MultiSelectData>, K = any> {
  label: string;
  name: string;
  type: AdvancedSearchFieldType;
  selectTypeParams?: SelectItem<K>[] | null;
  multiSelectArray?: T;
  selectedValues?: T;
  disabled?: boolean;
  callback?: (data: any) => void;
}

export type {
  CustomTableProps,
  CustomTableFiltersProps,
  CustomTableHeadProps,
  Cell,
  CustomTableRowsProps,
  CustomTablePaginationProps,
  AdvancedSearchProps,
  SingleSearchProps,
  AdvancedSearchParam,
  InputsComponentProps,
  AdvancedSearchInputProps,
  MultiSelectData,
  SelectItem,
  DocuSignTableData,
};
export { Order, CellType, AdvancedSearchFieldType };
