import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTableHeadProps } from '../../../types/customTable';
import { Order } from '../../../types/query-params';
import { HeadingBox } from '../CustomTable/styles';

export default function CustomTableHead(props: CustomTableHeadProps) {
  const { t } = useTranslation();
  const { order, orderBy, onRequestSort, heading, unsortableColumns } = props;

  const unSortableHeadings = useMemo(
    () => [
      t('formField.availableInStates'),
      'Product Lines',
      'Actions',
      ...(unsortableColumns || []),
    ],
    [t, unsortableColumns],
  );

  const unSortableColumnProps = useCallback(
    (headLabel: string) => {
      if (unSortableHeadings.includes(headLabel)) {
        return { hideSortIcon: true, onClick: undefined, sx: { cursor: 'default' } };
      }
      return {};
    },
    [unSortableHeadings],
  );

  return (
    <TableHead>
      <TableRow>
        {heading.map((headCell, index) => {
          const columnName = Object.keys(headCell)[0];
          const columnSortName = headCell[columnName];

          return (
            <TableCell
              key={columnName + index}
              sortDirection={order}
              {...{
                ...props.tableHeadCellProps?.[index],
                style: { ...props.tableHeadCellProps?.[index]?.style, minWidth: '150px' },
              }}
            >
              <TableSortLabel
                active={orderBy ? orderBy === columnSortName : false}
                direction={order === Order.ASC ? Order.DESC : Order.ASC}
                onClick={() => {
                  onRequestSort(order === Order.DESC ? Order.ASC : Order.DESC, columnSortName);
                }}
                {...unSortableColumnProps(columnName)}
              >
                <HeadingBox mr={1}>{columnName}</HeadingBox>
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
