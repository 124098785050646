import { OptionsObject, useSnackbar } from 'notistack';

export const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    closeSnackbar,
    info(message: string, options?: OptionsObject) {
      enqueueSnackbar(message, { variant: 'info', style: { whiteSpace: 'pre-line' }, ...options });
    },
    success(message: string, options?: OptionsObject) {
      enqueueSnackbar(message, {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        ...options,
      });
    },
    warning(message: string, options?: OptionsObject) {
      enqueueSnackbar(message, {
        variant: 'warning',
        style: { whiteSpace: 'pre-line' },
        ...options,
      });
    },
    error(message: string, options?: OptionsObject) {
      enqueueSnackbar(message, { variant: 'error', style: { whiteSpace: 'pre-line' }, ...options });
    },
  };
};
