import { AddCircleOutlineRounded } from '@mui/icons-material';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { AppActionButton } from '../../shared/AppActionButton';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { Box, Grid2 } from '@mui/material';

import {
  Organizations,
  useSellerAssociatedProductLines,
} from '../../../services/sellerGroupServices';
import { StickyContainer } from '../../../Theme/oldStylesInUse/oldAddSellerStyles';
import { useTranslation } from 'react-i18next';
import ProductService from '../../../services/productService';
import { CustomWidthTooltip } from '../../Forms/CreateOrganizationForm/styles';
import { ThirdBackground, White } from '../../../Theme/colorsVariables';
import InfoIcon from '@mui/icons-material/Info';
import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import StyledProductLineHeader from '../../shared/CustomProductLineHeader';

import { useToast } from '../../../hooks/useToast';
import { AppWarningParagraph } from '../../shared/AppWarningParagraph';
import { Cell, CellType, Order } from '../../../types/customTable';
import CustomTable from '../../shared/CustomTable';
import { AgreementStatus } from '../../../types/agreements';
import { formatUtcToLocalTime } from '../../../utils/formatUtcToLocalTime';
import { IconWrapper, StatusText } from '../Agreements/styles';
import AgreementStatusInfoTooltip from '../../InfoTooltip';
import CustomModal from '../../modal/Modal';
import EnvelopeAuditLogTable from '../../EnvelopeAuditLogTable';
import {
  useSellerAvailableProductLines,
  VoidAgreementQuery,
} from '../../../services/sellerServices';
import { ApiError, EditVoidAgreementsType } from '../../../types';
import { AlignCenterBox, FlexBox } from '../../shared/FlexBox';
import {
  AssociatedProductLinesContainer,
  FullHeightContainer,
  ProductLinesAbsoluteBox,
  ProductLinesScrollableBox,
  ProductLineText,
} from '../../SellerGroupTabs/ProductLinesTab/styles';
import { OrganizationType } from '../../../types/query-params';
import { UserContext } from '../../../context/User';
import {
  EnvelopeAuditLog,
  EnvelopeId,
  GenerateSigningUrl,
  GetEnvelopeAuditLogDetails,
  useDownloadAgreementByEnvelopeId,
} from '../../../services/DocuSignService';
import DownloadIcon from '../../assets/DownloadIcon';
import VoidIcon from '../../assets/VoidIcon';
import ViewIcon from '../../assets/ViewIcon';
import { CommonOrganization } from '../../../types/sellerGroup';
import ReviewDocumentIcon from '../../assets/ReviewDocumentIcon';
import { TooltipMessage } from '../../InfoTooltip/TooltipMessage';
import AppTooltip from '../../shared/AppTooltip';
import VoidAgreementModal from '../../modal/VoidAgreementModal';
import { AppLink } from '../../shared/CustomTableAddButton/styles';
import { AgreementsService } from '../../../services/agreementsService';
import RemoveDocumentIcon from '../../assets/RemoveDocumentIcon';
import { QuickDecisionModalContext } from '../../../context/QuickDecisionModal';

const ProductLinesContainer = ({
  headerText,
  children,
}: {
  headerText: string;
  children: ReactNode;
}) => {
  return (
    <Grid2 size={{ xs: 6 }} sx={{ position: 'relative', background: ThirdBackground }}>
      <ProductLinesAbsoluteBox>
        <ProductLinesScrollableBox>
          <StickyContainer style={{ background: 'white' }}>
            <StyledProductLineHeader text={headerText} />
          </StickyContainer>
          {children}
        </ProductLinesScrollableBox>
      </ProductLinesAbsoluteBox>
    </Grid2>
  );
};

export const SellerProductLinesTab = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const { setQuickDecisionModal } = useContext(QuickDecisionModalContext);
  const {
    isCompletedAgreement,
    refetchAgreementsList,
    refetchIsAgreementsInProgress,
    isAgreementInProcess,
    sellerInformation,
  } = useOutletContext<{
    isCompletedAgreement: boolean;
    isAgreementInProcess: boolean;
    refetchAgreementsList: () => void;
    refetchIsAgreementsInProgress: () => void;
    sellerInformation?: CommonOrganization;
  }>();

  const isLoggedInUserProvider = useMemo(() => user?.userType === 'Provider', [user?.userType]);

  const isReadContactPermission = usePermissions(PermissionKeys.ReadContact);
  const hasRequiredPermissions = usePermissions(PermissionKeys.SendForSignatures);
  const isAttachAgreementPermission = usePermissions(PermissionKeys.AttachAgreements);
  const isReadProductLinePermission = usePermissions(PermissionKeys.ViewProducts);
  const isSendForSignaturesPermission = usePermissions(PermissionKeys.SendForSignatures);
  const isReadAgentPermission = usePermissions(PermissionKeys.ReadAgent);
  const isReadSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const isDeleteAttachementPermission = usePermissions(PermissionKeys.DeleteAttachments);

  const [voidAgreementConfirmation, setVoidAgreementConfirmation] = useState(false);
  const [order, setOrder] = useState<Order>(Order.ASC);
  const [orderBy, setOrderBy] = useState<string>('productLineName');

  const tableHeadingsArray = useMemo(() => {
    if (isLoggedInUserProvider) {
      return [
        { 'Product Line': 'productLineName' },
        { Agent: 'agent' },
        { 'Agent Relationship Owner': 'agentContact' },
        { 'Signing Party': 'signingParty' },
        { 'Effective Date': 'effectiveDate' },
        { Signer: 'signer' },
        { 'DS Status': 'status' },
        { Actions: '' },
      ] as Record<string, string>[];
    }
    return [
      { 'Product Line': 'productLineName' },
      { 'Signing Party': 'signingParty' },
      { 'Effective Date': 'effectiveDate' },
      { Signer: 'signer' },
      { 'DS Status': 'status' },
      { Actions: '' },
    ] as Record<string, string>[];
  }, [isLoggedInUserProvider]);

  const { id } = useParams();

  const { data: availableProducts, refetch: refetchAvailableProducts } =
    useSellerAvailableProductLines(Number(id));

  const { data: unavailableProducts, refetch: refetchUnvailableProducts } =
    ProductService.useSellerUnavailableProductLines(Number(id), {
      onError(err) {
        toast.error(err.response.data.message);
      },
    });

  const { data: associatedProductLines, refetch: refetchAssociatedProducts } =
    useSellerAssociatedProductLines({
      sellerId: Number(id),
      sortColumn: orderBy || null,
      sortDirection: orderBy ? order : null,
      options: { enabled: isReadSellerPermission },
    });

  const { mutateAsync: getEnvelopeAuditLogDetails } = GetEnvelopeAuditLogDetails();

  const { mutateAsync: generateSigningUrl } = GenerateSigningUrl();

  const { mutate: downloadAgreementAsync } = useDownloadAgreementByEnvelopeId();
  const { mutate: downloadAgreementAttachment } =
    AgreementsService.useDownloadAgreementAttachment();

  const { mutate: deleteAttachedAgreement } = AgreementsService.useDeleteAttachedAgreement(
    Organizations.Seller,
  );

  const onDeleteAttachment = useCallback(
    ({ agreementId }: { agreementId: number }) => {
      setQuickDecisionModal({
        modalTitle: 'Warning!',
        message: t('confirmation.delete.attachment'),
        isOpen: true,
        yesCallBack: () => {
          deleteAttachedAgreement({ agreementId });
        },
      });
    },
    [deleteAttachedAgreement, setQuickDecisionModal, t],
  );

  const onVoidSuccess = (data: EditVoidAgreementsType) => {
    toast.success(data.message);
    refetchAvailableProducts();
    refetchUnvailableProducts();
    refetchAssociatedProducts();
    refetchIsAgreementsInProgress();
    refetchAgreementsList();
  };

  const onVoidError = (err: ApiError) => {
    toast.error(err.response.data.message);
  };

  const { mutate: VoidAgreement } = VoidAgreementQuery(onVoidSuccess, onVoidError);

  const [isAuditLogOpened, setIsAuditLogOpened] = useState(false);
  const [envelopeAuditLogDetails, setEnvelopeAuditLogDetails] = useState<EnvelopeAuditLog[]>([]);

  const sortedAvailableProducts = useMemo(() => {
    return availableProducts
      ? availableProducts
          .filter((p) => !p.isLegacyAgreementProduct)
          .sort((a, b) => a.productName.localeCompare(b.productName))
      : [];
  }, [availableProducts]);

  const openAuditLogModal = useCallback(
    async ({ envelopeId }: EnvelopeId) => {
      const envelopeAuditLog = await getEnvelopeAuditLogDetails({ envelopeId });
      if (envelopeAuditLog && envelopeAuditLog.length && typeof envelopeAuditLog !== 'string') {
        setIsAuditLogOpened(true);
        setEnvelopeAuditLogDetails(envelopeAuditLog);
      }
    },
    [getEnvelopeAuditLogDetails],
  );

  const openTabToViewAgreement = useCallback(
    async ({ agreementId }: { agreementId?: number }) => {
      if (!agreementId) {
        toast.error('Agreement Id has not been found.');
        return;
      }
      const { signingUrl } = await generateSigningUrl({
        agreementId: agreementId,
        redirectUrl: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      });

      if (signingUrl && signingUrl.indexOf('ApiException') === -1) {
        window.open(signingUrl, '_blank', 'noreferrer');
      }
    },
    [generateSigningUrl, toast],
  );

  const onVoidConfirm = (voidReason: string) => {
    if (id) {
      VoidAgreement({ sellerId: Number(id), voidReason });
    }
    setVoidAgreementConfirmation(false);
  };

  const tableRows = useMemo<Cell[][]>(() => {
    return (
      associatedProductLines?.map<Cell[]>((product) => {
        // Additional columns For Provider
        const getProviderCells = (): Cell[] => {
          if (isLoggedInUserProvider) {
            return [
              {
                data: (
                  <>
                    {isReadAgentPermission && product.agent ? (
                      <AppLink to={`/dashboard/Agent/${product.agent?.id}`} target={'_blank'}>
                        {product.agent?.name || '-'}
                      </AppLink>
                    ) : (
                      <>{product.agent?.name || '-'}</>
                    )}
                  </>
                ),
                type: CellType.Action,
              },
              {
                data: (
                  <>
                    {isReadAgentPermission &&
                    isReadContactPermission &&
                    product.agent &&
                    product.agentContact ? (
                      <AppLink
                        to={`/dashboard/Agent/${product.agent?.id}/Contact/${product.agentContact?.id}`}
                        target={'_blank'}
                      >
                        {product.agentContact?.name || '-'}
                      </AppLink>
                    ) : (
                      <>{product.agentContact?.name || '-'}</>
                    )}
                  </>
                ),
                type: CellType.Action,
              },
            ];
          }
          return [];
        };

        const providerCells = getProviderCells();
        return [
          {
            data: (
              <>
                {isReadProductLinePermission && (
                  <AppLink to={`/dashboard/ProductLine/${product.productLineId}`} target={'_blank'}>
                    {product.productLineName || '-'}
                  </AppLink>
                )}
                {!isReadProductLinePermission && <>{product.productLineName || '-'}</>}
              </>
            ),
            type: CellType.Action,
          },
          ...providerCells,
          {
            data: (
              <>
                {product.signingParty?.orgType === OrganizationType.SellerGroup ? (
                  <AppLink to={`/dashboard/SellerGroup/${product.signingParty.id}`} target="_blank">
                    {product.signingParty.name}
                  </AppLink>
                ) : (
                  <>{product.signingParty?.name}</>
                )}
              </>
            ),
            type: CellType.Action,
          },
          {
            data: product.effectiveDate
              ? formatUtcToLocalTime(`${product.effectiveDate}`, false)
              : '-',
            type: CellType.Info,
          },
          {
            data: (
              <>
                {isReadContactPermission && !!product.signer?.id && (
                  <AppLink
                    to={`/dashboard/${
                      product.signingParty?.orgType === OrganizationType.SellerGroup
                        ? 'SellerGroup'
                        : 'Seller'
                    }/${product.signingParty?.id}/Contact/${product.signer?.id}`}
                    target={'_blank'}
                  >
                    {product.signer?.name || '-'}
                  </AppLink>
                )}
                {!isReadContactPermission && <>{product.signer?.name || '-'}</>}
              </>
            ),
            type: CellType.Action,
          },
          {
            data: (
              // DocuSign statuses https://support.docusign.com/s/document-item?language=en_US&rsc_301&bundleId=oeq1643226594604&topicId=wdm1578456348227.html&_LANG=enus
              <AlignCenterBox>
                <StatusText
                  active={`${!!product.envelopeId}`}
                  onClick={() => {
                    if (!!product.envelopeId) {
                      openAuditLogModal({ envelopeId: product.envelopeId });
                    }
                  }}
                >
                  {product.status}
                </StatusText>
                {(!!product.declinedBy ||
                  !!product.deliveryFailureEmail ||
                  !!product.reviewedBy) && (
                  <AgreementStatusInfoTooltip
                    agreementStatusReason={product.agreementStatusReason}
                    declinedBy={product.declinedBy}
                    deliveryFailureEmail={product.deliveryFailureEmail}
                    reviewedBy={product.reviewedBy}
                    reviewedOn={product.reviewedOn}
                    statusReasonMessage={product.statusReasonMessage}
                    voidedBy={null}
                  />
                )}
              </AlignCenterBox>
            ),
            type: CellType.Action,
          },
          {
            data: (
              <>
                {product.envelopeId ? (
                  <FlexBox>
                    {isLoggedInUserProvider &&
                      product?.agreementStatusReason?.agreementStatusReason === 'Review Pending' &&
                      product.reviewType && (
                        <ReviewDocumentIcon
                          onClick={() => {
                            navigate(
                              `/dashboard/Seller/${id}/Agreement/Review?ReviewType=${product.reviewType}`,
                            );
                          }}
                          signingGroupMessage={
                            product.signingParty?.orgType === OrganizationType.SellerGroup
                              ? `This Agreement can only be reviewed from the Seller Group ${product.signingParty.name}`
                              : ''
                          }
                          reviewType={product.reviewType}
                        />
                      )}
                    {![
                      AgreementStatus.Correcting,
                      AgreementStatus.PurgingSoon,
                      AgreementStatus.Purged,
                      AgreementStatus.Voided,
                      AgreementStatus.Expired,
                    ].includes(product.status) && (
                      <AppTooltip title={'Download Agreement'}>
                        <IconWrapper
                          iconlabel="Download Agreement"
                          onClick={() => {
                            downloadAgreementAsync({
                              envelopeId: product.envelopeId,
                              fileName: `${
                                sellerInformation?.legalName
                              }_Agreement_${formatUtcToLocalTime(
                                `${product.effectiveDate}`,
                                false,
                              )}`,
                            });
                          }}
                          isallowed="true"
                        >
                          <DownloadIcon />
                        </IconWrapper>
                      </AppTooltip>
                    )}
                    {![
                      AgreementStatus.Correcting,
                      AgreementStatus.PurgingSoon,
                      AgreementStatus.Purged,
                    ].includes(product.status) ? (
                      <AppTooltip title={'View Agreement'}>
                        <IconWrapper
                          iconlabel="View Agreement"
                          onClick={() => {
                            openTabToViewAgreement({ agreementId: product.agreementId });
                          }}
                          isallowed="true"
                        >
                          <ViewIcon />
                        </IconWrapper>
                      </AppTooltip>
                    ) : (
                      <StatusText active={`false`}>No possible actions</StatusText>
                    )}

                    {[
                      AgreementStatus.Draft,
                      AgreementStatus.Sent,
                      AgreementStatus.Delivered,
                      AgreementStatus.WaitingForOthers,
                      AgreementStatus.NeedsToSign,
                      AgreementStatus.NeedsToView,
                    ].includes(product.status) &&
                      isSendForSignaturesPermission && (
                        <AppTooltip
                          title={
                            product.signingParty?.orgType !== OrganizationType.SellerGroup
                              ? 'Void Agreement'
                              : `This Agreement can only be voided from the Seller Group ${product.signingParty.name}`
                          }
                        >
                          <IconWrapper
                            iconlabel="Void Icon"
                            onClick={() => {
                              if (product.signingParty?.orgType !== OrganizationType.SellerGroup) {
                                setVoidAgreementConfirmation(true);
                              }
                            }}
                            isallowed={
                              product.signingParty?.orgType !== OrganizationType.SellerGroup
                                ? 'true'
                                : 'false'
                            }
                          >
                            <VoidIcon />
                          </IconWrapper>
                        </AppTooltip>
                      )}
                  </FlexBox>
                ) : !product.envelopeId && !!product.agentContact ? (
                  <FlexBox>
                    <AppTooltip title={'Download Agreement'}>
                      <IconWrapper
                        iconlabel="Download Agreement"
                        onClick={() => {
                          downloadAgreementAttachment({
                            agreementId: `${product.agreementId}`,
                            fileName: `${
                              sellerInformation?.legalName
                            }_Agreement_${formatUtcToLocalTime(`${product.effectiveDate}`, false)}`,
                          });
                        }}
                        isallowed="true"
                      >
                        <DownloadIcon />
                      </IconWrapper>
                    </AppTooltip>
                    {isDeleteAttachementPermission && (
                      <AppTooltip title={'Delete Attachment'}>
                        <IconWrapper
                          iconlabel="Delete Attachment"
                          onClick={() => {
                            onDeleteAttachment({ agreementId: product.agreementId });
                          }}
                          isallowed={'true'}
                        >
                          <RemoveDocumentIcon />
                        </IconWrapper>
                      </AppTooltip>
                    )}
                  </FlexBox>
                ) : (
                  <StatusText active={`false`}>No possible actions</StatusText>
                )}
              </>
            ),
            type: CellType.Action,
          },
        ];
      }) || []
    );
  }, [
    associatedProductLines,
    downloadAgreementAsync,
    downloadAgreementAttachment,
    id,
    isDeleteAttachementPermission,
    isLoggedInUserProvider,
    isReadAgentPermission,
    isReadContactPermission,
    isReadProductLinePermission,
    isSendForSignaturesPermission,
    navigate,
    onDeleteAttachment,
    openAuditLogModal,
    openTabToViewAgreement,
    sellerInformation?.legalName,
  ]);

  return (
    <FlexBox sx={{ flexFlow: 'column', flex: '1 1 auto', height: '100%' }}>
      <FlexBox justifyContent={'flex-end'} alignItems={'center'} flexGrow={'0 1 auto'}>
        {isAgreementInProcess && (
          <FlexBox>
            <AppWarningParagraph $align="left">
              <i>{t('productLine.inProgress.warning')}</i>
            </AppWarningParagraph>
          </FlexBox>
        )}
        {isAttachAgreementPermission && (
          <AppLink to={`/dashboard/Seller/${id}/AttachAgreement`}>
            <AppActionButton variant="outlined" startIcon={<AddCircleOutlineRounded />}>
              {t('action.attachAgreement')}
            </AppActionButton>
          </AppLink>
        )}
        {(isAgreementInProcess || isCompletedAgreement) && (
          <AppLink to={`/dashboard/Seller/${id}/CreateAgreement`}>
            <AppActionButton
              variant="outlined"
              startIcon={<AddCircleOutlineRounded />}
              disabled={!hasRequiredPermissions || isAgreementInProcess}
            >
              {t('action.createAgreement')}
            </AppActionButton>
          </AppLink>
        )}
      </FlexBox>

      <FullHeightContainer mt={2}>
        <Box sx={{ flexGrow: 1, maxHeight: '50%' }}>
          <Grid2 container spacing={1} sx={{ height: '100%' }}>
            <ProductLinesContainer
              headerText={t('sellerGroup.productLine.available')}
              children={sortedAvailableProducts?.map((product) => (
                <AlignCenterBox key={product.productId}>
                  <ProductLineText>{product.productName}</ProductLineText>
                </AlignCenterBox>
              ))}
            />
            <ProductLinesContainer
              headerText={t('sellerGroup.productLine.unavailable')}
              children={unavailableProducts?.map((product) => (
                <AlignCenterBox key={product.productId}>
                  <ProductLineText>{product.product}</ProductLineText>
                  <CustomWidthTooltip
                    title={
                      <div style={{ whiteSpace: 'pre-line' }}>
                        {product.informationMessages.map((message) => (
                          <TooltipMessage key={message} message={message} />
                        ))}
                      </div>
                    }
                    placement="top-start"
                  >
                    <InfoIcon fontSize="inherit" color="disabled" />
                  </CustomWidthTooltip>
                </AlignCenterBox>
              ))}
            />
          </Grid2>
        </Box>
        <Box pl={1} sx={{ background: White, flexGrow: 0 }} mt={1}>
          <StyledProductLineHeader text={t('sellerGroup.productLine.associated')} />
        </Box>

        <AssociatedProductLinesContainer>
          <CustomTable
            displayOptions={{ paginationTop: false, paginationBottom: false, tableOnly: true }}
            unsortableColumns={['Actions']}
            page={0}
            rowsPerPage={0}
            data={tableRows}
            valueRef={undefined}
            goError={false}
            handlePageChange={() => {}}
            handleRowsPerPageChange={() => {}}
            tableHeadingsArray={tableHeadingsArray}
            advancedSearchVisibility={true}
            setAdvancedSearchVisibility={() => {}}
            setPagination={() => {}}
            onSubmitAdvancedSearch={() => {}}
            onResetAdvancedSearch={() => {}}
            searchValue={''}
            handleSearchValue={() => {}}
            setPage={() => {}}
            tableTitle={''}
            placeHolder={t('action.search')}
            addNewLink={{ link: '', state: undefined }}
            order={order}
            orderBy={orderBy}
            handleRequestSort={(newOrder, newOrderBy) => {
              setOrder(newOrder);
              setOrderBy(newOrderBy);
            }}
            InputsComponent={() => <></>}
            isDataLoading={false}
            total={0}
            openAdvancedSearch={() => {}}
            isAdvanceSearch={false}
          />
        </AssociatedProductLinesContainer>
      </FullHeightContainer>
      <CustomModal
        modalTitle={'Audit Log'}
        open={isAuditLogOpened}
        containerWidth={'1000px'}
        modalProps={{
          onClose: () => {
            setIsAuditLogOpened(false);
            setEnvelopeAuditLogDetails([]);
          },
        }}
      >
        {envelopeAuditLogDetails.length && (
          <EnvelopeAuditLogTable envelopeAuditLogDetails={envelopeAuditLogDetails} />
        )}
      </CustomModal>
      <VoidAgreementModal
        popup={voidAgreementConfirmation}
        confirm={onVoidConfirm}
        cancel={() => setVoidAgreementConfirmation(false)}
      />
    </FlexBox>
  );
};
