import axios from 'axios';
import { useQuery, useMutation, UseQueryOptions } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  ProductsData,
  ProductDetails,
  SellerProductsInfo,
  ProductsDataNew,
  ProductLinePayload,
  SellerStatus,
  DeletePayload,
  ApiError,
  UnavailableProduct,
  Pagination,
} from '../types';
import { NewProduct, NewProductWithStates } from '../types/product-types';
import { useToast } from '../hooks/useToast';

const ProductService = {
  useProductStatus: (productId?: number) => {
    const getSellerStatus = async () => {
      const fetch = await axios.get(`/Product/HasSellerAgreementInProcess?productId=${productId}`);
      return fetch.data;
    };
    const state = useQuery<SellerStatus, Error>('SellerStatus', getSellerStatus, {
      retry: true,
      enabled: false,
    });
    return state;
  },

  useDeleteProduct: (
    onSuccess: (
      data: unknown,
      variables: DeletePayload,
      context: unknown,
    ) => void | Promise<unknown>,
    onError: (
      error: ApiError,
      variables: DeletePayload,
      context: unknown,
    ) => void | Promise<unknown>,
  ) => {
    const deleteProduct = async (payload: DeletePayload) => {
      const fetch = await axios.post('/Product/DeleteProduct', payload);
      return fetch.data;
    };

    const state = useMutation<unknown, ApiError, DeletePayload>('delete-product', deleteProduct, {
      onError,
      onSuccess,
    });

    return state;
  },

  useProductsListAll: (payload: ProductLinePayload) => {
    const fetchProductsList = async () => {
      const fetch = await axios.post<Pagination<ProductsDataNew>>(`/Product/page`, payload);
      return fetch.data;
    };
    const state = useQuery<Pagination<ProductsDataNew>, Error>(
      ['productLines', payload],
      fetchProductsList,
    );
    return state;
  },

  useProductDetailQuery: () => {
    const { id } = useParams();
    const toast = useToast();
    const fetchProductDetailData = async () => {
      const fetch = await axios.get(`/Product/${id}`);
      return fetch.data;
    };
    const state = useQuery<ProductDetails, ApiError>(
      ['productDetail', id],
      fetchProductDetailData,
      {
        onError(err) {
          toast.error(err.response.data.message);
        },
      },
    );
    return state;
  },

  useAddProductQuery: (
    onSuccess: (response: SellerProductsInfo) => void,
    onError: (error: ApiError, variables: NewProduct) => void,
  ) => {
    const addProduct = async (obj: NewProduct) => {
      const fetch = await axios.post('/Product', obj);
      return fetch.data;
    };
    return useMutation<SellerProductsInfo, ApiError, NewProduct>('productAdd', addProduct, {
      onSuccess,
      onError,
    });
  },
  useUpdateProductQuery: (
    onSuccess: () => void,
    onError: (error: ApiError, variables: NewProductWithStates) => void,
  ) => {
    const updateProduct = async (obj: NewProductWithStates) => {
      const fetch = await axios.patch('/Product', obj);
      return fetch.data;
    };
    return useMutation<ProductsData, ApiError, NewProductWithStates>(
      'product-update',
      updateProduct,
      {
        onSuccess,
        onError,
      },
    );
  },
  useSellerGroupUnavailableProductLinesByOrgId: (
    orgId: number,
    options?: Omit<UseQueryOptions<UnavailableProduct[], ApiError>, 'queryKey' | 'queryFn'>,
  ) => {
    const toast = useToast();
    const getProducts = async () => {
      const res = await axios.get(`/SellerGroup/${orgId}/UnavailableProducts`);
      return res.data;
    };
    return useQuery<UnavailableProduct[], ApiError>(
      'useSellerGroupUnavailableProductLinesByOrgId',
      getProducts,
      {
        onError(err) {
          toast.error(err.response.data.message);
        },
        ...options,
      },
    );
  },
  useSellerUnavailableProductLines: (
    orgId: number,
    options?: Omit<UseQueryOptions<UnavailableProduct[], ApiError>, 'queryKey' | 'queryFn'>,
  ) => {
    const toast = useToast();
    const getProducts = async () => {
      const res = await axios.get(`/Sellers/${orgId}/UnavailableProducts`);
      return res.data;
    };
    return useQuery<UnavailableProduct[], ApiError>(
      'useSellerUnavailableProductLines',
      getProducts,
      {
        onError(err) {
          toast.error(err.response.data.message);
        },
        ...options,
      },
    );
  },
};

export default ProductService;
