import { Box, Grid2, useMediaQuery, useTheme } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationService } from '../../../services/configurationService';
import { useQuery } from '../../../hooks/useQuery';
import { AppTextField } from '../../../components/shared/AppTextField';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';

export default function AssetMakes() {
  const apiRef = useGridApiRef();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ReadConfiguration);
  const { dealerGroupTypeId } = useParams();
  const dealerTypeGroupName = useQuery('dealerTypeGroupName');
  const dealerType = useQuery('dealerType');
  const { data: assetMakes, isFetching } = ConfigurationService.useAssetMakes(
    Number(dealerGroupTypeId),
    {
      enabled: isEnabled,
    },
  );

  const rows = useMemo(() => {
    return (
      assetMakes?.map((item) => ({
        assetMakeName: item.assetMakeName,
        id: item.assetMakeId,
      })) || []
    );
  }, [assetMakes]);

  const columns: GridColDef[] = [
    {
      field: 'assetMakeName',
      headerName: 'Asset Make',
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      apiRef.current?.autosizeColumns({
        includeHeaders: true,
        columns: ['assetMakeName'],
        expand: true,
      });
    }, 500);
  }, [apiRef, rows, assetMakes]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.noPermissions') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Asset Makes" />
      </Box>
      <Grid2 container spacing={isSmall ? 1 : 2} mb={1}>
        <Grid2 size={{ xs: 3 }}>
          <AppTextField
            label={'Dealer Type Group'}
            size="small"
            value={dealerTypeGroupName}
            disabled
          />
        </Grid2>
        <Grid2 size={{ xs: 3 }}>
          <AppTextField label={'Dealer Type'} size="small" value={dealerType} disabled />
        </Grid2>
      </Grid2>

      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        loading={isFetching}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
      />
    </>
  );
}
