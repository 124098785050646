import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircleOutlineRounded } from '@mui/icons-material';
import { useMediaQuery, Grid2, FormGroup, Checkbox, Box } from '@mui/material';
import { DatePicker, PickersDay, pickersDayClasses } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { useForm, Control, FieldValues, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { UserContext } from '../../../context/User';
import { useDataloss } from '../../../hooks/useDataloss';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { SecondHeaderBox } from '../../../pages/OrganizationContactInfoPage/styles';
import { useAgentContactsAll } from '../../../services/agentService';
import AuthService from '../../../services/authService';
import {
  useIsSendForSignaturesEnabled,
  useOrganizationInfo,
  Organizations,
} from '../../../services/sellerGroupServices';
import {
  useAllAgents,
  useSellerAvailableProductLines,
  useSellerSignersList,
} from '../../../services/sellerServices';
import { White, MainRed, SeperatorLine } from '../../../Theme/colorsVariables';
import { StickyContainer } from '../../../Theme/oldStylesInUse/oldAddSellerStyles';
import { FormSingleSelectValue, AttachagreementFormValues } from '../../../types/sellerGroup';
import { pastingCheck } from '../../../utils';
import { FeinBox } from '../../common/styles/feinBox';
import { StyledTypography } from '../../Forms/RoleForm/noPermissionsText';
import { CancelModal } from '../../modal/CancelModal';
import { CheckboxMain } from '../../PermissionSets/styles';

import { AppActionButton } from '../../shared/AppActionButton';
import AppAutocompleteSingleSelect from '../../shared/AppAutocompleteSingleSelect';
import { AppCancelButton } from '../../shared/AppCancelButton';
import PageHeaderContentHost from '../../shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../shared/AppPageHeaderContentHost/styles';
import { AppTextField } from '../../shared/AppTextField';
import { AppWarningParagraph } from '../../shared/AppWarningParagraph';
import {
  SpaceBetweenBox,
  AlignCenterBox,
  JustifyContentRightBox,
  FlexBox,
  CenterCenterBox,
  FlexColumn,
} from '../../shared/FlexBox';
import {
  AddContactBtn,
  ButtonWrapper,
} from '../../SellerGroupTabs/ProductLinesTab/AddProductLines/styles';
import { OrganizationService } from '../../../services/organizationService';
import { FileDropzone } from './FileDropzone';
import { attachAgreementValidationSchema } from '../../../Resolvers/attachAgreementValidationSchema';
import { AgreementsService } from '../../../services/agreementsService';
import { useToast } from '../../../hooks/useToast';
import { QuickConfirmationModalContext } from '../../../context/QuickModal';
import { useQueryClient } from 'react-query';
import { EligibilityCriteria } from '../../EligibilityCriteria';

const AttachAgreement = () => {
  const theme = useTheme();
  const toast = useToast();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { id } = useParams();
  const queryClient = useQueryClient();

  const location = useLocation();
  const { state } = location;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const isCreateOrganizationContact = usePermissions(PermissionKeys.CreateContact);
  const isReadAgent = usePermissions(PermissionKeys.ReadAgent);

  const { data: commonData } = AuthService.useCommonDetailsQuery();
  const { data: orgTenant } = OrganizationService.useTenant();

  const agreementEffectiveDateRangeOver = useMemo(() => {
    const obj = commonData?.find((item) => item.type === 'AgreementEffectiveDateRangeOver');
    if (obj) {
      return +obj.value;
    }
    return null;
  }, [commonData]);
  const agreementEffectiveDateRangeUnder = useMemo(() => {
    const obj = commonData?.find((item) => item.type === 'AgreementEffectiveDateRangeUnder');
    if (obj) {
      return +obj.value;
    }
    return null;
  }, [commonData]);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [defaultAgentRelationshipOwner, setDefaultAgentRelationshipOwner] =
    useState<FormSingleSelectValue | null>(null);
  const [defaultSigner, setDefaultSigner] = useState<FormSingleSelectValue | null>(null);
  const [defaultSelectedAgent, setDefaultSelectedAgent] = useState<FormSingleSelectValue | null>(
    null,
  );

  const addProductLineForm = useMemo<AttachagreementFormValues | undefined>(() => {
    return state;
  }, [state]);

  const formDefaultValues = useMemo<AttachagreementFormValues>(() => {
    return {
      productLines: [],
      legacyProductLines: [],
      legacyProductLinesFlag: false,
      signer: defaultSigner || null,
      effectiveDate: dayjs(new Date()),
      agent: defaultSelectedAgent || null,
      agentRelationshipOwner: defaultAgentRelationshipOwner || null,
      tenantSigner: null,
      attachedFiles: null,
    };
  }, [defaultAgentRelationshipOwner, defaultSelectedAgent, defaultSigner]);

  const formMethods = useForm<AttachagreementFormValues>({
    mode: 'all',
    defaultValues: formDefaultValues,
    resolver: yupResolver(attachAgreementValidationSchema),
  });

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = formMethods;

  const allFields = watch();

  const {
    agent: selectedAgent,
    signer,
    productLines,
    agentRelationshipOwner,
    effectiveDate,
    legacyProductLines: legacyProductLinesFormValue,
    attachedFiles,
    tenantSigner,
  } = allFields;

  const { data: isSendForSignaturesEnabledData } = useIsSendForSignaturesEnabled({
    agencyContactId: agentRelationshipOwner && signer ? agentRelationshipOwner?.value : 0,
    signerContactId: agentRelationshipOwner && signer ? signer?.value : 0,
  });

  const { data: agreementType } = AgreementsService.useAgreementTypeByAgentAndOrgId({
    agentId: Number(selectedAgent?.value),
    orgId: Number(id),
  });

  const { data: tenantSigners } = OrganizationService.useTenantSigners();

  const { data: signersListByAgent, status: signersStatus } = useSellerSignersList({
    sellerId: Number(id) || 0,
    agentId: selectedAgent?.value || 0,
    options: {
      enabled: !!selectedAgent?.value && !!id,
    },
  });

  const { data: organizationData } = useOrganizationInfo({
    id: Number(id),
    isEnabled: true,
    organisation: location.pathname.includes('SellerGroup')
      ? Organizations.SellerGroup
      : Organizations.Seller,
  });

  const { data: agencies } = useAllAgents({});

  const { data: agentContacts, isFetching: isAgentContactsFetching } = useAgentContactsAll({
    agentId: Number(selectedAgent?.value),
    options: { enabled: !!selectedAgent?.value },
  });

  const { data: availableProductLines, isFetching: isProductsFetching } =
    useSellerAvailableProductLines(Number(id), selectedAgent?.value, {
      onSuccess(data) {
        setValue(
          'productLines',
          data.filter((item) => !item.isLegacyAgreementProduct).map((item) => item.productId),
        );
        const legacyProducts = data.filter((item) => item.isLegacyAgreementProduct);
        if (legacyProducts.length) {
          setValue(
            'legacyProductLines',
            legacyProducts.map((item) => item.productId),
          );
          setValue('legacyProductLinesFlag', true);
        } else {
          setValue('legacyProductLinesFlag', false);
        }
      },
    });

  const legacyProductLines = useMemo(() => {
    if (availableProductLines?.length) {
      return availableProductLines
        .filter((item) => item.isLegacyAgreementProduct)
        .map((item) => ({
          name: item.productName,
          id: item.productId,
        }));
    }
    return [];
  }, [availableProductLines]);

  const signerOptions = useMemo(
    () =>
      signersListByAgent?.map((contact) => ({
        label: `${contact.firstName} ${contact.lastName}<span class="vl"></span>${
          contact.title || ''
        }<span class="vl"></span>${contact.email}`,
        value: Number(contact.contactId),
      })) || [],
    [signersListByAgent],
  );

  const tenantSignersOptions = useMemo(
    () =>
      tenantSigners?.data?.map((item) => ({
        label: `${item.name}`,
        value: Number(item.id),
      })) || [],
    [tenantSigners?.data],
  );

  const agents = useMemo(() => {
    return (
      agencies?.map((agent) => {
        return {
          label: `${agent.agencyName}<span class="vl"></span>${pastingCheck(
            agent?.phoneNumber || '',
          )}`,
          value: agent.agencyId,
        };
      }) || []
    );
  }, [agencies]);

  const agentRelationshipOwners = useMemo(() => {
    return (
      agentContacts?.map((contact) => {
        return {
          label: `${contact.firstName} ${contact.lastName}<span class="vl"></span>${
            contact.title || ''
          }<span class="vl"></span>${contact.email}`,
          value: Number(contact.contactId),
        };
      }) || []
    );
  }, [agentContacts]);

  const sortedProducts = useMemo(() => {
    return availableProductLines
      ? availableProductLines
          .filter((item) => !item.isLegacyAgreementProduct)
          .sort((a, b) => a.productName.localeCompare(b.productName))
      : [];
  }, [availableProductLines]);

  const isAgentUserHaveContact = useMemo(() => {
    return agentContacts?.find(
      (agentContact) =>
        user?.name === `${agentContact.firstName} ${agentContact.lastName}` &&
        user?.email === agentContact.email,
    );
  }, [agentContacts, user]);

  useEffect(() => {
    if (user && user.userType === 'Agency') {
      const userAgent = agents.find((agentObject) => agentObject.value === user.orgId);

      if (userAgent) {
        setValue('agent', userAgent);
        setDefaultSelectedAgent(userAgent);
      }

      if (isAgentUserHaveContact && !formDefaultValues.agentRelationshipOwner) {
        setDefaultAgentRelationshipOwner({
          label: `${isAgentUserHaveContact.firstName} ${isAgentUserHaveContact.lastName}<span class="vl"></span>${isAgentUserHaveContact.title}<span class="vl"></span>${isAgentUserHaveContact.email}`,
          value: Number(isAgentUserHaveContact.contactId),
        });
        setValue('agentRelationshipOwner', {
          label: `${isAgentUserHaveContact.firstName} ${isAgentUserHaveContact.lastName}<span class="vl"></span>${isAgentUserHaveContact.title}<span class="vl"></span>${isAgentUserHaveContact.email}`,
          value: Number(isAgentUserHaveContact.contactId),
        });
      }
    }
    if (user && user.userType === 'Provider') {
      if (agentRelationshipOwners.length === 1) {
        setValue('agentRelationshipOwner', agentRelationshipOwners[0], { shouldValidate: true });
        setDefaultAgentRelationshipOwner(agentRelationshipOwners[0]);
      }
    }
  }, [agentRelationshipOwners, agents, formDefaultValues, isAgentUserHaveContact, setValue, user]);

  useEffect(() => {
    if (signerOptions.length === 1) {
      setDefaultSigner(signerOptions[0]);
      setValue('signer', signerOptions[0], { shouldValidate: true });
    } else {
      setDefaultSigner(null);
      setValue('signer', null);
    }
  }, [setValue, signerOptions]);

  useEffect(() => {
    if (addProductLineForm && addProductLineForm.hasOwnProperty('agent')) {
      setValue('agent', addProductLineForm.agent);
      setValue('agentRelationshipOwner', addProductLineForm.agentRelationshipOwner);
      setValue('effectiveDate', addProductLineForm.effectiveDate);
      setValue('signer', addProductLineForm.signer);
      setValue('productLines', addProductLineForm.productLines);
      setValue('tenantSigner', addProductLineForm.tenantSigner);
      setValue('attachedFiles', addProductLineForm.attachedFiles);
      window.history.replaceState({}, '', location.pathname);
    }
  }, [addProductLineForm, location.pathname, setValue]);

  useEffect(() => {
    if (agentRelationshipOwner) {
      const valueDoesNotExist = agentContacts?.every((item) => {
        if (item.contactId === agentRelationshipOwner.value) {
          return false;
        }
        return true;
      });
      if (valueDoesNotExist) {
        setValue('agentRelationshipOwner', null, { shouldValidate: true });
      }
    }
  }, [agentContacts, agentRelationshipOwner, setValue]);

  const isUnsavedData = useMemo(() => {
    const currentFormDefaultValues = {
      ...formDefaultValues,
      agent: defaultSelectedAgent,
      agentRelationshipOwner: defaultAgentRelationshipOwner,
      signer: defaultSigner,
      effectiveDate: {
        year: formDefaultValues.effectiveDate?.get('year'),
        month: formDefaultValues.effectiveDate?.get('month'),
        day: formDefaultValues.effectiveDate?.get('day'),
      },
      productLines: availableProductLines?.map((item) => item.productId).sort((a, b) => a - b),
    };

    const currentFormValues = {
      ...allFields,
      effectiveDate: {
        year: allFields.effectiveDate?.get('year'),
        month: allFields.effectiveDate?.get('month'),
        day: allFields.effectiveDate?.get('day'),
      },
      productLines: allFields.productLines?.sort((a, b) => a - b),
    };

    return !isEqual(currentFormValues, currentFormDefaultValues);
  }, [
    formDefaultValues,
    defaultSelectedAgent,
    defaultAgentRelationshipOwner,
    defaultSigner,
    availableProductLines,
    allFields,
  ]);

  const { mutate: attachAgreement } = AgreementsService.useAttachAgreement({
    onSuccess(data) {
      toast.success(data.message);
      queryClient.removeQueries('useSellerAvailableProductLines');
      queryClient.removeQueries('useSellerUnavailableProductLines');
      queryClient.removeQueries('useSellerAssociatedProductLines');
      queryClient.removeQueries('useSellerAgreements');
      navigate(`/dashboard/Seller/${id}/ProductLine`);
    },
    onError(error) {
      setQuickModal({ isOpen: true, message: error.response.data.message, modalTitle: 'Error' });
    },
  });

  const onSubmit = (data: AttachagreementFormValues) => {
    const payloadObject = new FormData();
    data.attachedFiles?.forEach((file) => {
      payloadObject.append(`Files`, new File([file], file.name, { type: file.type }));
    });
    data.productLines.forEach((pl) => {
      payloadObject.append(`ProductIds`, `${pl}`);
    });
    payloadObject.append('agentContactId', `${Number(data.agentRelationshipOwner?.value)}`);
    payloadObject.append('agentOrgId', `${Number(data.agent?.value)}`);
    payloadObject.append('orgId', `${Number(id)}`);
    payloadObject.append('signerContactId', `${Number(data.signer?.value)}`);
    payloadObject.append('effectiveDate', `${dayjs(data.effectiveDate).format('YYYY-MM-DD')}`);
    payloadObject.append('providerSignerUserId', `${Number(data.tenantSigner?.value)}`);
    payloadObject.append('documentTypeId', `${agreementType?.data.documentTypeId}`);

    attachAgreement(payloadObject);
  };

  const onDiscardChanges = useCallback(() => {
    navigate(`/dashboard/Seller/${id}/ProductLine`);
    setConfirmPopup(false);
  }, [id, navigate]);

  const onSaveChanges = useCallback(() => {}, []);

  const onExit = useCallback(() => {}, []);

  useDataloss(isUnsavedData, onDiscardChanges, onSaveChanges, onExit, true);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ flex: '1', display: 'flex', flexDirection: 'column' }}
      >
        <PageHeaderContainer mb={3}>
          <SpaceBetweenBox>
            <PageHeaderContentHost
              title="Seller"
              name={organizationData?.legalName}
              status={organizationData?.status}
            />
          </SpaceBetweenBox>
        </PageHeaderContainer>
        <SecondHeaderBox>
          <PageHeaderContentHost color={`${White}`} title={'Attach Agreement'} />
          <AlignCenterBox>
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setConfirmPopup(true);
                } else {
                  setConfirmPopup(false);
                  navigate(`/dashboard/Seller/${id}/ProductLine`);
                }
              }}
            />

            <AppActionButton
              disabled={
                legacyProductLinesFormValue.length
                  ? !selectedAgent ||
                    !agentRelationshipOwner ||
                    !signer ||
                    !effectiveDate?.date() ||
                    !isSendForSignaturesEnabledData?.enableSendForSignatures ||
                    !tenantSigner ||
                    !attachedFiles?.length
                  : !selectedAgent ||
                    !agentRelationshipOwner ||
                    !signer ||
                    productLines?.length === 0 ||
                    !effectiveDate?.date() ||
                    !isSendForSignaturesEnabledData?.enableSendForSignatures ||
                    !tenantSigner ||
                    !attachedFiles?.length
              }
              type="submit"
            >
              {t('action.save')}
            </AppActionButton>
          </AlignCenterBox>
        </SecondHeaderBox>
        {isSendForSignaturesEnabledData?.message && (
          <JustifyContentRightBox>
            <AppWarningParagraph>
              <i>{isSendForSignaturesEnabledData?.message}</i>
            </AppWarningParagraph>
          </JustifyContentRightBox>
        )}
        <FlexColumn
          sx={{
            flex: '1',
          }}
          mb={2}
        >
          <EligibilityCriteria />
          <FlexBox sx={{ flex: '1', width: '100%', position: 'relative' }}>
            <FlexBox sx={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
              <FlexColumn
                sx={{
                  flex: '8',
                  borderRight: `2px solid ${SeperatorLine}`,
                }}
              >
                <Grid2 container spacing={isSmall ? 1 : 2} mt={2} flex={'0 1 auto'}>
                  <Grid2 size={8}>
                    <AppAutocompleteSingleSelect
                      control={control as unknown as Control<FieldValues>}
                      name={'agent'}
                      label={'Agent'}
                      options={agents}
                      rules={{
                        required: true,
                      }}
                      disabled={user?.userType === 'Agency'}
                      errorMessage={errors.agent?.message}
                    />
                  </Grid2>
                  <Grid2 size={4} />

                  <Grid2 size={8}>
                    <AppAutocompleteSingleSelect
                      control={control as unknown as Control<FieldValues>}
                      name={'agentRelationshipOwner'}
                      label={'Agent Relationship Owner'}
                      options={agentRelationshipOwners}
                      disabled={!selectedAgent || agentRelationshipOwners.length === 0}
                      rules={{
                        required: true,
                      }}
                      errorMessage={errors.agentRelationshipOwner?.message}
                    />
                  </Grid2>

                  <Grid2 size={4}>
                    <ButtonWrapper>
                      <AddContactBtn
                        variant="outlined"
                        disabled={!isCreateOrganizationContact || !isReadAgent || !selectedAgent}
                        startIcon={<AddCircleOutlineRounded />}
                        onClick={() => {
                          navigate(
                            `/dashboard/Agent/${selectedAgent?.value}/AddContact?returnUrl=/dashboard/Seller/${id}/AttachAgreement`,
                            { state: allFields },
                          );
                        }}
                      >
                        {t('action.addAgentContact')}
                      </AddContactBtn>
                    </ButtonWrapper>
                  </Grid2>
                  {agentRelationshipOwners.length === 0 &&
                    !isAgentContactsFetching &&
                    !!selectedAgent && (
                      <Grid2 size={12}>
                        <FeinBox>{t('sellerGroup.productLine.noAgentContactWarning')}</FeinBox>
                      </Grid2>
                    )}
                  <Grid2 container spacing={isSmall ? 1 : 2} size={12}>
                    <Grid2 size={8}>
                      <AppAutocompleteSingleSelect
                        control={control as unknown as Control<FieldValues>}
                        name={'signer'}
                        label={'Seller Signer'}
                        options={signerOptions}
                        rules={{
                          required: true,
                        }}
                        disabled={signerOptions.length === 0}
                        errorMessage={errors.signer?.message}
                        helperText={
                          signerOptions.length === 0
                            ? user?.userType === 'Agency'
                              ? t('sellerGroup.productLine.noContactWarning')
                              : selectedAgent && signersStatus === 'success'
                              ? t('sellerGroup.productLine.noContactWarningProvider')
                              : undefined
                            : undefined
                        }
                      />
                    </Grid2>
                    <Grid2 size={4}>
                      <ButtonWrapper>
                        <AddContactBtn
                          variant="outlined"
                          disabled={!isCreateOrganizationContact || !selectedAgent}
                          startIcon={<AddCircleOutlineRounded />}
                          onClick={() => {
                            navigate(
                              `/dashboard/Seller/${id}/AddContact?returnUrl=/dashboard/Seller/${id}/AttachAgreement`,
                              { state: allFields },
                            );
                          }}
                        >
                          {t('add.contact')}
                        </AddContactBtn>
                      </ButtonWrapper>
                    </Grid2>
                    <Grid2 size={8}>
                      <Controller
                        name="effectiveDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            label="Effective Date"
                            minDate={
                              agreementEffectiveDateRangeUnder &&
                              dayjs().subtract(agreementEffectiveDateRangeUnder, 'day')
                            }
                            maxDate={
                              agreementEffectiveDateRangeOver &&
                              dayjs().add(agreementEffectiveDateRangeOver, 'day')
                            }
                            slots={{
                              textField: AppTextField,
                              day: (props) => (
                                <PickersDay
                                  {...props}
                                  sx={{
                                    [`&&.${pickersDayClasses.selected}`]: {
                                      backgroundColor: MainRed,
                                    },
                                  }}
                                />
                              ),
                            }}
                            slotProps={{
                              day: {
                                sx: {
                                  backgroundColor: 'red',
                                },
                              },
                              textField: {
                                required: true,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid2>
                    <Grid2 size={8}>
                      <AppAutocompleteSingleSelect
                        control={control as unknown as Control<FieldValues>}
                        name={'tenantSigner'}
                        label={orgTenant?.legalName ? `${orgTenant?.legalName} Signer` : 'Signer'}
                        options={tenantSignersOptions}
                        rules={{
                          required: true,
                        }}
                        errorMessage={errors.tenantSigner?.message}
                      />
                    </Grid2>
                    <Grid2 size={4} />
                    <Grid2 size={8}>
                      <AppTextField
                        value={agreementType?.data.displayName || ''}
                        label={'Agreement Type'}
                        disabled
                      />
                    </Grid2>
                    <Grid2 size={4} />
                  </Grid2>
                </Grid2>
                <Box
                  flex={'1'}
                  sx={{
                    overflowY: 'auto',
                    maxHeight: '100%',
                    position: 'relative',
                    height: '100%',
                  }}
                  mt={2}
                >
                  <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
                    <StickyContainer>
                      <b>{t('sellerGroup.productLine.selectProductLine')}</b>
                    </StickyContainer>
                    {!sortedProducts.length && !isProductsFetching && (
                      <Grid2 container>
                        <Grid2 size={12}>
                          <StyledTypography>
                            {t('sellerGroup.productLine.noRecords')}
                          </StyledTypography>
                        </Grid2>
                      </Grid2>
                    )}
                    {errors.productLines?.message && (
                      <FeinBox>{errors.productLines?.message}</FeinBox>
                    )}
                    <Controller
                      name="productLines"
                      control={control}
                      render={({ field }) => (
                        <>
                          {sortedProducts.map((item) => (
                            <Grid2 size={{ xs: 12 }} key={item.productId}>
                              <AlignCenterBox>
                                <FormGroup>
                                  <CheckboxMain
                                    control={
                                      <Checkbox
                                        color="success"
                                        value={item.productId}
                                        checked={field.value?.indexOf(item.productId) >= 0}
                                        onChange={(event, checked) => {
                                          if (checked) {
                                            field.onChange([...field.value, +event.target.value]);
                                          } else {
                                            field.onChange(
                                              field.value.filter(
                                                (value) => value !== +event.target.value,
                                              ),
                                            );
                                          }
                                        }}
                                      />
                                    }
                                    label={item.productName}
                                  />
                                </FormGroup>
                              </AlignCenterBox>
                            </Grid2>
                          ))}
                        </>
                      )}
                    />
                    {!!legacyProductLines.length && (
                      <>
                        <StickyContainer>
                          <b>{t('sellerGroup.productLine.legacyProductLine')}</b>
                        </StickyContainer>
                        <Controller
                          name="legacyProductLines"
                          control={control}
                          render={({ field }) => (
                            <>
                              {legacyProductLines.map((item) => (
                                <Grid2 size={{ xs: 12 }} key={item.id}>
                                  <AlignCenterBox>
                                    <FormGroup>
                                      <CheckboxMain
                                        disabled
                                        control={
                                          <Checkbox
                                            color="success"
                                            value={item.id}
                                            checked={field.value?.indexOf(item.id) >= 0}
                                            onChange={(event, checked) => {
                                              if (checked) {
                                                field.onChange([
                                                  ...field.value,
                                                  +event.target.value,
                                                ]);
                                              } else {
                                                field.onChange(
                                                  field.value.filter(
                                                    (value) => value !== +event.target.value,
                                                  ),
                                                );
                                              }
                                            }}
                                          />
                                        }
                                        label={item.name}
                                      />
                                    </FormGroup>
                                  </AlignCenterBox>
                                </Grid2>
                              ))}
                            </>
                          )}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </FlexColumn>

              <Box sx={{ flex: '6' }} ml={1}>
                <CenterCenterBox height={'100%'} width={'100%'}>
                  <FileDropzone />
                </CenterCenterBox>
              </Box>
            </FlexBox>
          </FlexBox>
        </FlexColumn>
        {confirmPopup && (
          <CancelModal
            modalTitle="Warning"
            onDiscardChanges={onDiscardChanges}
            onSaveChanges={onSaveChanges}
            onExit={() => {
              setConfirmPopup(false);
            }}
            hideSaveDataButton={true}
            isOpened={confirmPopup}
          />
        )}
      </form>
    </FormProvider>
  );
};

export const AttachSellerAgreementProtected = () => {
  const { t } = useTranslation();

  const isAttachAgreementPermission = usePermissions(PermissionKeys.AttachAgreements);

  if (!isAttachAgreementPermission) {
    return (
      <Grid2 container>
        <Grid2 size={{ xs: 12 }}>
          <StyledTypography>{t('relationships.organisation.view.permission')}</StyledTypography>
        </Grid2>
      </Grid2>
    );
  }

  return <AttachAgreement />;
};
