import { Box, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import DataTable from '../DataTable';
import { CustomTableType } from './interface';
import {
  StyledInput,
  StyledPagination,
  IconDiv,
  TableCountRowsText,
  StyledButtonBox,
  StyledPaginationBox,
  BoxForPage,
} from './styles';
import { useTranslation } from 'react-i18next';
import { HeadingBox } from '../../shared/CustomTable/styles';
import { Order } from '../../../types/query-params';
import UpDown from '../../assets/UpDown';

export default function CustomTableSmall({
  children,
  page,
  rowsPerPage,
  data,
  valueRef,
  goError,
  handlePageChange,
  handleGoclick,
  tableHeadingsArray,
  showPaginationContainer,
  onSubmitType,
  order,
  orderBy,
  unsortableColumns,
}: CustomTableType) {
  const { t } = useTranslation();
  const emptyRows = 0; // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const [pageInputValue, setPageInputValue] = useState('');
  const headerClick = (header: string) => {
    onSubmitType(header);
  };

  const unSortableHeadings = useMemo(
    () => [
      t('formField.availableInStates'),
      'Product Lines',
      'Actions',
      ...(unsortableColumns || []),
    ],
    [t, unsortableColumns],
  );

  const unSortableColumnProps = useCallback(
    (headLabel: string) => {
      if (unSortableHeadings?.includes(headLabel)) {
        return { hideSortIcon: true, onClick: undefined, sx: { cursor: 'default' } };
      }
      return {};
    },
    [unSortableHeadings],
  );

  return (
    <>
      <DataTable tableMaxHeight={'1000px'}>
        <>
          <TableHead>
            <TableRow>
              {tableHeadingsArray
                ? tableHeadingsArray.map((header: string) => (
                    <TableCell
                      key={header}
                      align={'left'}
                      sortDirection={orderBy === header ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === header}
                        direction={orderBy === header ? order : Order.ASC}
                        onClick={() => {
                          headerClick(header);
                        }}
                        {...unSortableColumnProps(header)}
                      >
                        <HeadingBox mr={1}>{header}</HeadingBox>
                      </TableSortLabel>
                    </TableCell>
                  ))
                : (Object.keys(data[0]) ?? []).map((header: string) => (
                    <TableCell key={header} align="left">
                      <Box display="flex" alignItems="baseline">
                        {header}{' '}
                        <IconDiv onClick={() => headerClick(header)}>
                          {header && <UpDown />}
                        </IconDiv>
                      </Box>
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!children &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowItem: any) => (
                  <TableRow key={`${rowItem.name}${rowItem.phone}`}>
                    {Object.keys(rowItem).map((item: any) => (
                      <TableCell
                        key={`${rowItem.phone}asdsae${(Math.random() + 1)
                          .toString(36)
                          .substring(7)}`}
                        align="left"
                      >
                        {rowItem[item]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            {children && children}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 70 * emptyRows,
                }}
              >
                <TableCell colSpan={Object.keys(data[0]).length} />
              </TableRow>
            )}
          </TableBody>
        </>
      </DataTable>

      {showPaginationContainer ? (
        <Box mt={3} display="flex" alignItems="center" justifyContent="space-between">
          <TableCountRowsText>
            Showing{' '}
            {data.length < (page + 1) * rowsPerPage ? data.length : (page + 1) * rowsPerPage} of{' '}
            {data.length} records
          </TableCountRowsText>
          <Box display="flex" alignItems="center">
            <StyledPagination
              onChange={handlePageChange}
              page={page + 1}
              count={Math.ceil(data.length / rowsPerPage)}
              showFirstButton
              showLastButton
            />
            <Box display="flex" alignItems="center">
              <BoxForPage ml={3} mr={1}>
                Go to Page:
              </BoxForPage>
              <StyledPaginationBox>
                <StyledInput
                  type="number"
                  placeholder="1"
                  hiddenLabel
                  id="filled-hidden-label-small"
                  variant="outlined"
                  value={pageInputValue}
                  size="small"
                  onChange={(e) => setPageInputValue(e.target.value)}
                  inputRef={valueRef}
                  error={goError}
                />
              </StyledPaginationBox>
              <StyledButtonBox onClick={handleGoclick}>Go</StyledButtonBox>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}
