import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { AlignCenterBox } from '../shared/FlexBox';
import { FontSizeMax } from '../../Theme/screensVariables';

export const EligibilityCriteria = () => {
  return (
    <Box my={1}>
      <AlignCenterBox>
        <InfoIcon color="error" />
        <Typography ml={1} fontWeight={'600'} fontSize={FontSizeMax}>
          Eligibility Criteria:
        </Typography>
      </AlignCenterBox>
      <br />
      <Typography>
        If an existing Originator has an executed <b>WFI Originator Agreement</b> on file that has a
        version of <b>v020120</b> (i.e. 2/1/2020) or newer, then a .pdf copy of that agreement can
        be attached in the portal and take the place of a Portal-generated agreement delivered via
        Docusign.
      </Typography>
      <br />
      <Typography>
        Regarding PLAs, if an existing executed PLA on file has a version of v120122 (i.e.
        12/1/2022) or newer and includes one of the following words in the footer (“Initial” or
        “Add-On”), then a .pdf copy of that PLA can be attached in the portal and take the place of
        the Portal-generated PLA delivered via Docusign.
      </Typography>
    </Box>
  );
};
