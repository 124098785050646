const patterns: Record<string, RegExp> = {
  phone: /^[0-9-]+$/i,
  phoneInput: /^\d{1,14}$/i,
  city: /[\s\S]*$/i,
  cityM: /[\s\S]*$/i,
  agencyName: /[\s\S]*$/i,
  sellerGroupName: /[\s\S]*$/i,
  'sellerGroup.legalName': /[\s\S]*$/i,
  'sellerGroup.doingBusinessName': /[\s\S]*$/i,
  legalNameS: /[\s\S]*$/i,
  doingBusinessAsS: /[\s\S]*$/i,
  mainPhone: /^[+]?[(]?[0-9]{3}[)]?(-)[0-9]{3}(-)[0-9]{4}$/i,
  website:
    /(^$)|^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
  'sellerGroup.feinName': /^[0-9]+$/i,
  feinS: /^[0-9-]+$/i,
  mainFax: /^[0-9-]+$/i,
  'sellerGroup.address[0].zipCode': /^[0-9]+$/i,
  otherDms: /^[A-Z0-9 ]+$/i,
  menu: /^[A-Z0-9 ]+$/i,
  oem: /^[0-9A-Z]+$/i,
  numbersAndLetters: /^[0-9A-Z]+$/i,
  phoneAndFax: /^(\d{3}-?\d{3}-?\d{4})?$/,
  zip: /^\d{5}(-\d{4})?$/,
  email: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,}$/,
};

export const getCommonPattern = (str: string): RegExp => {
  const reg = /[\s\S]*$/i;
  const pattern = patterns[str];

  return pattern ?? reg;
};
