import { Button, ButtonProps } from '@mui/material';
import styled from 'styled-components';
import { HoverColor, MainColor } from '../../../Theme/colorsVariables';
import { FlexBox } from '../FlexBox';
import { breakPointMedium } from '../../../utils';

export interface AppActionButtonProps extends ButtonProps {
  fullHeight?: boolean;
  background?: string;
}

export const AppActionButton = styled(Button)<AppActionButtonProps>`
  font-size: 16px;
  position: relative;
  font-weight: bold;
  font-family: Gotham-Book;
  text-wrap: nowrap;
  padding: 6px 12px;
  background: ${(props) => (props.background ? props.background : MainColor)};
  text-transform: capitalize;
  color: #fff;
  border: none;
  border-radius: 20px;
  min-width: 90px;
  margin-left: 8px;
  height: ${(props) => (props?.fullHeight ? '100%' : '34px')};

  span {
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: #fff;
      }
    }
  }
  &:hover {
    background: ${HoverColor};
    border: none;
  }

  &:disabled {
    color: #cccccc;
    background: #eeeeee;
    span {
      svg {
        path {
          fill: #cccccc;
        }
      }
    }
  }
  ${breakPointMedium(`max-height: 24px; font-size: 12px; min-width: 74px;   
  span {
    svg {
      width: 12px;
      height: 12px;
     
    }
  }`)}
`;

export const AppActionButtonsContainer = styled(FlexBox)`
  justify-content: flex-end;
  margin: 10px 0;
  margin-left: auto;
`;
