import { Box } from '@mui/material';
import styled from 'styled-components';

export const FlexBox = styled(Box)({
  display: 'flex',
});
export const FlexColumn = styled(FlexBox)({
  flexDirection: 'column',
});

export const SpaceBetweenBox = styled(FlexBox)`
  justify-content: space-between;
  position: relative;
  align-items: center;
`;

export const SpaceEvenlyBox = styled(FlexBox)`
  justify-content: space-evenly;
  position: relative;
  align-items: center;
`;

export const JustifyCenterBox = styled(FlexBox)`
  justify-content: center;
  width: 100%;
`;
export const JustifyRightBox = styled(FlexBox)`
  justify-content: end;
  width: 100%;
`;

export const BoxRequired = styled(Box)`
  position: absolute;
  bottom: -12px;
`;

export const JustifyContentRightBox = styled(FlexBox)`
  justify-content: end;
`;

export const AlignCenterBox = styled(FlexBox)`
  align-items: center;
`;

export const CenterCenterBox = styled(FlexBox)`
  justify-content: center;
  align-items: center;
`;
