import { CustomTableFiltersProps } from '../../../types/customTable';
import InfoIcon from '@mui/icons-material/Info';
import AdvancedSearch from '../CustomTableAdvancedSearch';
import AddNewButton from '../CustomTableAddButton';
import { ParentBox, SelectBox, StateMultiselectBox, StatesMainBox, InfoLabelText } from './styles';
import SingleSearch from '../CustomTableSingleSearch';
import { MenuItemsSelect, QuickTableFilter, TypeSelect } from './interface';
import { FormControl, Box } from '@mui/material';
import { AddressState } from '../../../types';
import StatesMultipleSelect from '../StatesMultipleSelectChips';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import CustomTablePagination from '../CustomTablePagination';
import { CenterCenterBox, SpaceBetweenBox } from '../FlexBox';
import { AppTextField } from '../AppTextField';
import { AppAutocomplete } from '../AppAutocomplete';
import { AdvancedSearchLabel } from '../CustomTableSingleSearch/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AppListItem from '../AppListItem';
import AppTooltip from '../AppTooltip';
import { TypographySpan } from '../StateMultiSelect/styles';

export default function CustomTableFilters({
  advancedSearchVisibility,
  setAdvancedSearchVisibility,
  onSubmitAdvancedSearch,
  onResetAdvancedSearch,
  searchValue,
  handleSearchValue,
  selectArray,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  rowsPerPage,
  goError,
  setPagination,
  total,
  pageTotal,
  err,
  valueRef,
  paginationTop,
  rowsPerPageOptions,
  openAdvancedSearch,
  tableTitle,
  placeHolder,
  addNewLink,
  setPage,
  data,
  InputsComponent,
  isAdvanceSearch,
  searchInfoMessage,
  hideMainSearch,
}: CustomTableFiltersProps) {
  const { t } = useTranslation();
  const renderSingleSelect = (item: QuickTableFilter): JSX.Element => {
    return (
      <AppAutocomplete
        data-cy={'data-cy-quick-filter'}
        fullWidth
        disabled={item.disabled}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        disablePortal
        options={item.filterOptions.map((option) => ({
          label: option.text,
          value: option.value,
        }))}
        forcePopupIcon
        value={item.value}
        renderInput={(params) => (
          <AppTextField {...params} label={item.label} fullWidth size="small" />
        )}
        disableClearable={item.value.label === 'Any'}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => {
          if (option.label) {
            return (
              <AppListItem {...props} key={JSON.stringify(option)}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: option.label,
                  }}
                ></span>
              </AppListItem>
            );
          }
        }}
        onChange={(e, val, reason, details) => {
          if (reason === 'clear') {
            item.handleFilter({ label: 'Any', value: 'Any' });
          } else {
            item.handleFilter(val);
          }
        }}
      />
    );
  };

  const renderMultiSelect = (item: QuickTableFilter): JSX.Element => {
    return (
      <AppAutocomplete
        size="small"
        isOptionEqualToValue={(option, value) => {
          return option.label === value.label;
        }}
        multiple
        options={item.filterOptions.map((selectItem) => ({
          label: selectItem.text,
          value: selectItem.value,
        }))}
        fullWidth
        value={item.value as MenuItemsSelect[]}
        disabled={item.disabled}
        renderInput={(params) => (
          <AppTextField {...params} label={item.label} disabled={item.disabled} fullWidth />
        )}
        disableClearable={item.value[0].label === 'Any'}
        getOptionLabel={(option) =>
          option.label
            ? option.label
                .replaceAll('<span class="vl"></span>', ' | ')
                .replaceAll('<i>', '')
                .replaceAll('</i>', '')
            : ''
        }
        renderTags={(selectedOptions) => (
          <TypographySpan>
            {selectedOptions.length === 1 && selectedOptions[0].label === 'Any'
              ? 'Any'
              : t('multiple.select.label', {
                  selectedCount: selectedOptions.filter(
                    (selectedOption) => selectedOption.label !== 'All',
                  ).length,
                  allCount: item.filterOptions?.length - 1 || 0,
                })}
          </TypographySpan>
        )}
        renderOption={(props, option) => {
          if (option.label) {
            return (
              <AppListItem
                {...props}
                key={JSON.stringify(option)}
                data-cy-option={'select-option'}
                data-cy={`select-option-${option.label}`}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: option.label,
                  }}
                ></span>
              </AppListItem>
            );
          }
        }}
        onChange={(e, newData) => {
          item.handleFilter(newData);
        }}
      />
    );
  };

  const renderMultiAutocomplete = ({
    items,
    selectedItems,
    changeSelectedItems,
  }: QuickTableFilter<AddressState[]>) => {
    return (
      <StatesMainBox>
        <StateMultiselectBox>
          <p>{t('formField.availableInStates')}</p>
          <hr></hr>
          <FormControl>
            <StatesMultipleSelect<AddressState>
              disabled={false}
              isFilter={true}
              originalData={items as AddressState[]}
              selectedData={selectedItems as AddressState[]}
              handleChangeSelected={(newSelectedItems) => {
                if (changeSelectedItems) {
                  changeSelectedItems(newSelectedItems);
                }
              }}
            />
          </FormControl>
        </StateMultiselectBox>
        <InfoLabelText ml={2}>{t('formField.availableInStates.atleast')}</InfoLabelText>
      </StatesMainBox>
    );
  };

  const SelectRenderer: {
    [k in TypeSelect]: (item: QuickTableFilter) => JSX.Element;
  } = {
    single: (item) => renderSingleSelect(item),
    multi: (item) => renderMultiSelect(item),
    multiAutocomplete: (item) => renderMultiAutocomplete(item),
  };

  const setUpAdvancedSearch = useCallback(() => {
    setAdvancedSearchVisibility(!advancedSearchVisibility);
    setPagination(false);
    openAdvancedSearch([]);
    setPage(0);
  }, [
    setAdvancedSearchVisibility,
    advancedSearchVisibility,
    setPagination,
    openAdvancedSearch,
    setPage,
  ]);

  return (
    <>
      {tableTitle && (
        <ParentBox mt={'6px'}>
          <AddNewButton tabTitle={tableTitle} addNewLink={addNewLink} />
          {isAdvanceSearch && (
            <AppTooltip
              title={
                !advancedSearchVisibility
                  ? t('table.advancedSearchMore')
                  : t('table.advancedSearchHide')
              }
            >
              <AdvancedSearchLabel
                onClick={setUpAdvancedSearch}
                advancedsearchvisibility={`${advancedSearchVisibility}`}
              >
                <FilterAltIcon />
              </AdvancedSearchLabel>
            </AppTooltip>
          )}
        </ParentBox>
      )}

      <SpaceBetweenBox
        style={{
          alignItems: 'center',
          gap: '10px',
        }}
        flexWrap={'wrap'}
        my={1}
      >
        <CenterCenterBox>
          {!hideMainSearch && (
            <SingleSearch
              setPagination={setPagination}
              searchValue={searchValue}
              setSearchValue={handleSearchValue}
              advancedSearchVisibility={advancedSearchVisibility}
              setAdvancedSearchVisibility={setAdvancedSearchVisibility}
              placeholder={placeHolder}
              isAdvanceSearch={isAdvanceSearch}
              openAdvancedSearch={openAdvancedSearch}
              setPage={setPage}
              tableTitle={tableTitle}
              total={total}
            />
          )}

          {searchInfoMessage && (
            <AppTooltip title={searchInfoMessage} placement="right">
              <InfoIcon fontSize="inherit" color="disabled" />
            </AppTooltip>
          )}
        </CenterCenterBox>
        {selectArray && (
          <SelectBox
            style={{
              justifyContent: 'flex-end',
              maxWidth: '750px',
              flex: '1 1 auto',
              gap: '10px',
            }}
          >
            {selectArray.map((item) => (
              <Box
                key={item.label}
                style={{
                  flex: '1 1 auto',
                  maxWidth: selectArray.length === 1 ? '300px' : '',
                }}
              >
                {SelectRenderer[item.type](item)}
              </Box>
            ))}
          </SelectBox>
        )}
      </SpaceBetweenBox>

      {advancedSearchVisibility && (
        <AdvancedSearch
          InputsComponent={InputsComponent}
          onSubmit={onSubmitAdvancedSearch}
          onReset={onResetAdvancedSearch}
          err={err}
        />
      )}
      {paginationTop && (
        <CustomTablePagination
          page={page}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          goError={goError}
          total={total}
          valueRef={valueRef}
          setPage={setPage}
          data={data}
          pageTotal={pageTotal}
        />
      )}
    </>
  );
}
