import React, {
  useState,
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { AddNewLink, Cell, CellType, NewLinkState } from '../../types/customTable';
import { EntityType, Order } from '../../types/query-params';
import { AgreementsAdvancedSearchData } from './interface';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../hooks/useDebounce';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import useTrim from '../../hooks/useTrim';
import { useToast } from '../../hooks/useToast';
import { useQuery } from '../../hooks/useQuery';
import AgreementTemplateService from '../../services/documentService';
import ProductService from '../../services/productService';
import CustomTable from '../../components/shared/CustomTable';
import AddTemplate from '../../components/modal/AddTemplate';
import {
  ApiError,
  DocumentItem,
  DocumentsData,
  DocumentTypeOptions,
  ProductNamesList,
  TemplateDocumentPayload,
} from '../../types';
import DeleteEntityModal from '../../components/modal/DeleteEntityModal';
import { getSearchString } from '../../utils';
import { linkColor } from '../../Theme/colorsVariables';
import { useSaveSearchCriteria } from '../../hooks/useSaveSearchCriteria';
import { QuickTableFilter } from '../../components/shared/CustomTableFilters/interface';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';
import { UserContext } from '../../context/User';
import { AgreementTemplateNew } from '../../components/modal/AddTemplate/types';
import { Box } from '@mui/material';
import EditIcon from '../../components/assets/EditIcon';
import { AlignCenterBox, CenterCenterBox } from '../../components/shared/FlexBox';
import EditTemplateModal from '../../components/modal/EditTemplate';
import NotificationHub from '../../hooks/useNotificationHub';
import AppTooltip from '../../components/shared/AppTooltip';

const SEARCH_KEY = 'agreementTemplateList.search';

function AgreementTemplateList() {
  const isAddAgreementPermission = usePermissions(PermissionKeys.AddAgreement);
  const isEditAgreementPermission = usePermissions(PermissionKeys.ModifyAgreement);
  const isViewAgreementPermission = usePermissions(PermissionKeys.ViewAgreement);
  const isDeleteAgreementPermission = usePermissions(PermissionKeys.DeleteAgreement);
  const isViewProductLinePermission = usePermissions(PermissionKeys.ViewProducts);

  const {
    searchTerm,
    page,
    rowsPerPage,
    order,
    orderBy,
    productLineType,
    usedForEntity,
    productLine,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setProductLineType,
    setUsedForEntity,
    setProductLine,
  } = useSaveSearchCriteria({
    searchKey: SEARCH_KEY,
  });

  const [agreementRows, setAgreementRows] = useState<Cell[][]>([]);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState<boolean>(false);
  const [showEditTemplateModal, setShowEditTemplateModal] = useState<boolean>(false);
  const [deletedTemplateId, setDeletedTemplateId] = useState(0);

  const [agreementDeleteModal, setAgreementDeleteModal] = useState(false);
  const toast = useToast();
  /** This is to identify which method to use in pagination */
  const [pagination, setPagination] = useState<boolean>(true);
  const [productData, setProductData] = useState<ProductNamesList>([]);
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeOptions>([]);
  const [documentObj, setDocumentObj] = useState<DocumentItem>({
    documentTypeId: 0,
    'document type': '',
    'used for entity': '',
    product: '',
    documentId: 0,
    productId: 0,
    documentOrgId: 0,
  });
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const idProductLine = useQuery('product-id');
  const user = useContext(UserContext);
  const valueRef = useRef<any>();
  const { t } = useTranslation();

  const tableHeadingsArray = useMemo(() => {
    return [
      { 'Agreement Type': 'agreementtype' },
      { 'Used for Entity': 'usedForEntity' },
      { 'Product Line': 'productLine' },
      { 'Template ID': 'documentOrgId' },
      { Actions: 'templateId' },
    ] as Record<string, string>[];
  }, []);
  const debouncedSearchTerm: string = useDebounce(searchTerm, 300);
  const trimValue = useTrim(searchTerm);
  const [totalCount, setTotalCount] = useState(0);
  const [dataAdvancedSearch, setDataAdvancedSearch] = useState<AgreementsAdvancedSearchData>();
  const [addNewLink, setAddNewLink] = useState<AddNewLink>({
    link: '',
    state: NewLinkState.active || NewLinkState.disabled,
  });
  const handleProductLineTypeChange = (value: { label: string; value: string }) => {
    setProductLineType(value);
    setPage(0);
  };
  const handleUsedForEntityChange = (value: { label: string; value: string }) => {
    setUsedForEntity(value);
    setPage(0);
  };
  const handleProductLineChange = (value: { label: string; value: string }) => {
    setProductLine(value);
    setPage(0);
  };
  const selectArrayAgreemntTemplate: QuickTableFilter[] = [
    {
      label: t('formField.agreementType'),
      value: productLineType,
      handleFilter: handleProductLineTypeChange,
      filterOptions: [{ value: EntityType.All, text: t('status.any') }, ...documentTypes],
      type: 'single',
    },
    {
      label: t('formField.usedForEntity'),
      value: usedForEntity,
      handleFilter: handleUsedForEntityChange,
      filterOptions: [
        { value: EntityType.All, text: t('status.any') },
        { value: 'Seller', text: 'Seller' },
        { value: 'Seller Group', text: 'Seller Group' },
      ],
      type: 'single',
    },
    {
      label: t('formField.productLine'),
      value: productLine,
      handleFilter: handleProductLineChange,
      filterOptions: [{ value: EntityType.All, text: t('status.any') }, ...productData],
      type: 'single',
    },
  ];
  const {
    data: documentLists,
    refetch,
    isFetching: isDocumentsLoading,
  } = AgreementTemplateService.useAll({
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    sortColumn: orderBy.replace(' ', ''),
    sortDirection: order,
    productId:
      idProductLine !== ''
        ? +idProductLine
        : +(productLine?.value as string) !== 0
        ? +(productLine?.value as string)
        : undefined,
    agreementType:
      getSearchString(debouncedSearchTerm) || productLineType?.label === 'Any'
        ? ''
        : productLineType?.label,
    entityType:
      dataAdvancedSearch?.usedForEntity || usedForEntity?.label === 'Any'
        ? ''
        : usedForEntity?.label,
    name: getSearchString(debouncedSearchTerm),
    advancedSearch: dataAdvancedSearch,
  });

  const { mutate: deleteTemplate } = AgreementTemplateService.useDeleteTemplate(
    () => {
      setAgreementDeleteModal(false);
      toast.success(t('template-removed-message'));
      setTimeout(() => {
        refetch();
      }, 1000);
    },
    (error) => {
      setAgreementDeleteModal(false);
      toast.error(error.response.data.message);
    },
  );

  const { data: documentTypeList } = AgreementTemplateService.useDocumentTypes();
  const { data: productList } = ProductService.useProductsListAll({});

  const onEditTemplateSuccess = (docusignUrl: string) => {
    window.open(docusignUrl, '_blank', 'noreferrer');
  };
  const onEditTemplateError = (error: ApiError) => {
    toast.error(error.response.data.message);
  };

  const onViewTemplateSuccess = (data: Blob) => {
    if (data?.size > 0) {
      const file = window.URL.createObjectURL(data!);
      window.open(file, '_blank', 'noreferrer');
    }
  };

  const onBusinessError = () => {
    toast.error(t('security.roles.roleForm.role.error'));
  };

  const { mutate: editTemplate } = AgreementTemplateService.useEditTemplate(
    onEditTemplateSuccess,
    onEditTemplateError,
  );

  const { mutate: viewTemplate } = AgreementTemplateService.useViewTemplate(
    onViewTemplateSuccess,
    onBusinessError,
  );
  const { mutate: downloadTemplate } = AgreementTemplateService.useDownload();

  const onEdd = useCallback(
    (item: DocumentsData) => {
      //FIXME: redirect url is temporary solution. Please consider this in future.
      const data = {
        redirectUri: `${process.env.REACT_APP_DOCUSIGN_REDIRECT_URL}?templateId=${
          item.templateId
        }&productId=${!!item.productId}`,
        tempOrEnvId: item.templateId,
      };
      editTemplate(data);

      setDocumentObj({
        documentTypeId: item.documentTypeId,
        'document type': item.documentTypeName,
        'used for entity': item.usedForEntity,
        product: item.productName,
        documentId: item.documentId,
        documentOrgId: item.documentOrgId,
        productId: item.productId,
      });
    },
    [editTemplate],
  );

  const onEditTemplateDocument = (item: DocumentsData) => {
    setShowEditTemplateModal(true);
    setDocumentObj({
      documentTypeId: item.documentTypeId,
      'document type': item.documentTypeName,
      'used for entity': item.usedForEntity,
      product: item.productName,
      documentId: item.documentId,
      documentOrgId: item.documentOrgId,
      productId: item.productId,
    });
  };

  const OnView = useCallback(
    (item: DocumentsData) => {
      //FIXME: redirect url is temporary solution. Please consider this in future.
      const data = {
        documentId: item?.documentId,
      };
      viewTemplate(data);

      setDocumentObj({
        documentTypeId: item.documentTypeId,
        'document type': item.documentTypeName,
        'used for entity': item.usedForEntity,
        product: item.productName,
        documentId: item.documentId,
        documentOrgId: item.documentOrgId,
        productId: item.productId,
      });
    },
    [viewTemplate],
  );

  const onAdd = (item: DocumentsData) => {
    setShowAddTemplateModal(true);
    setDocumentObj({
      documentTypeId: item.documentTypeId,
      'document type': item.documentTypeName,
      'used for entity': item.usedForEntity,
      product: item.productName,
      documentId: item.documentId,
      documentOrgId: item.documentOrgId,
      productId: item.productId,
    });
    setDeletedTemplateId(0);
  };

  const onDelete = (item: DocumentsData) => {
    setDocumentObj({
      documentTypeId: item.documentTypeId,
      'document type': item.documentTypeName,
      'used for entity': item.usedForEntity,
      product: item.productName,
      documentId: item.documentId,
      documentOrgId: item.documentOrgId,
      productId: item.productId,
    });
    setAgreementDeleteModal(true);
  };

  useEffect(() => {
    if (documentObj) {
      if (documentObj.product) {
        setDocumentTitle(
          `${documentObj.product} - ${documentObj['document type']} - ${documentObj['used for entity']}`,
        );
      } else {
        setDocumentTitle(`${documentObj['document type']} - ${documentObj['used for entity']}`);
      }
    }
  }, [documentObj]);

  const agreementRowsMapper = useCallback(
    (data: DocumentsData[]): void => {
      const mappedCell = data.map((agreement: DocumentsData) => [
        {
          data: agreement.documentTypeName,
          type: CellType.Info,
        },
        {
          data: agreement.usedForEntity,
          type: CellType.Info,
        },
        {
          data: agreement.productName,
          type: isViewProductLinePermission ? CellType.Link : CellType.Info,
          linkTo: `/dashboard/ProductLine/${agreement.productId}`,
          styles: { color: isViewProductLinePermission ? linkColor : '' },
        },
        {
          data: (
            <AlignCenterBox>
              <Box sx={{ width: '100px' }}>{agreement.documentOrgId}</Box>
              {agreement.templateId && isEditAgreementPermission && (
                <AppTooltip title={'Edit Template ID'}>
                  <CenterCenterBox
                    ml={2}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      onEditTemplateDocument(agreement);
                    }}
                  >
                    <EditIcon />
                  </CenterCenterBox>
                </AppTooltip>
              )}
            </AlignCenterBox>
          ),
          type: CellType.Action,
        },
        {
          data: {
            callbackOnEdit: () => onEdd(agreement),
            editAgreement: isEditAgreementPermission,
            addAgreement: isAddAgreementPermission,
            viewAgreement: isViewAgreementPermission,
            deleteAgreement: isDeleteAgreementPermission,
            callbackOnDelete: () => onDelete(agreement),
            callbackOnAdd: () => onAdd(agreement),
            callbackOnView: () => OnView(agreement),
            agreement,
            title: 'restrictions.noPermissions',
            downloadAgreement: isViewAgreementPermission,
            callbackOnDownload: () => {
              downloadTemplate({
                templateId: agreement.templateId,
                fileName: `${agreement.documentTypeName} ${agreement.usedForEntity} ${agreement.productName}`,
              });
            },
          },
          type: CellType.DocuSign,
          styles: { color: linkColor },
        },
      ]);
      setAgreementRows(mappedCell);
    },
    [
      isViewProductLinePermission,
      isEditAgreementPermission,
      isAddAgreementPermission,
      isViewAgreementPermission,
      isDeleteAgreementPermission,
      onEdd,
      OnView,
      downloadTemplate,
    ],
  );

  const onTemplateAddSuccess = (data: any) => {
    toast.success(t('template-added-message'));
    setTimeout(() => {
      refetch();
    }, 1000);
    window.open(data?.previewURL, '_blank', 'noreferrer');
  };

  //TODO: investigate if message is valid
  const onTemplateAddError = (error: ApiError) => {
    toast.error(error.response.data.message);
    setShowAddTemplateModal(false);
  };

  const {
    mutate: addAgreementTemplate,
    isLoading: isAddDocumentLoading,
    isSuccess: isAddDocumentSuccess,
  } = AgreementTemplateService.useAddTemplate(onTemplateAddSuccess, onTemplateAddError);

  const { mutate: editDocumentOrgId } = AgreementTemplateService.useAddTemplate(
    () => {
      setShowEditTemplateModal(false);
      toast.success('Agreement Document Template ID updated');
      setTimeout(() => {
        refetch();
      }, 1000);
    },
    (error) => {
      toast.error(error.response.data.message);
    },
  );

  const { events } = NotificationHub();
  useEffect(() => {
    events((documentId) => {
      setDeletedTemplateId(documentId);
      refetch();
    });
  }, [events, refetch]);

  useEffect(() => {
    if (deletedTemplateId) {
      const document = documentLists?.data.find((doc) => doc.documentId === deletedTemplateId);
      if (document) onAdd(document);
    }
  }, [deletedTemplateId, documentLists?.data]);

  useEffect(() => {
    if (productList?.data && productList?.data.length > 0) {
      const products: { text: string; value: string }[] = [];
      productList?.data.forEach((item) => {
        products.push({
          text: item.productName,
          value: `${item.productId}`,
        });
      });
      setProductData(products);
    }
    if (documentTypeList && documentTypeList.length > 0) {
      const docsType: { text: string; value: string }[] = [];
      documentTypeList.forEach((item) => {
        docsType.push({
          text: item.documentTypeName,
          value: item.documentTypeName,
        });
      });
      setDocumentTypes(docsType);
    }
  }, [productList, documentTypeList]);

  useEffect(() => {
    if (!documentLists) {
      return;
    } else {
      agreementRowsMapper(documentLists.data);
      setDataAdvancedSearch({
        usedForEntity: '',
        agreementType: '',
        productLine: undefined,
      });
      setTotalCount(documentLists.totalCount);
    }
  }, [agreementRowsMapper, documentLists, isAddDocumentSuccess, setSearchTerm]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage - 1);
    },
    [setPage],
  );

  const handleSearchValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setPage, setSearchTerm],
  );

  const onSubmitAddTemplate = async (templateData: AgreementTemplateNew) => {
    setShowAddTemplateModal(false);

    const data: TemplateDocumentPayload = {
      templateName: templateData.fileName,
      desc: templateData.fileName,
      redirectUri: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      productId: templateData.productId,
      documentOrgId: templateData.documentOrgId,
      uploadDocs: {
        documentItems: [
          {
            fileName: templateData.fileName,
            documentId: templateData.documentId,
            documentTypeId: templateData.documentTypeId,
            usedForEntity: templateData.usedForEntity,
            base64String: templateData.templateJson.split(',')[1],
            fileExtension: 'pdf',
          },
        ],
      },
    };

    addAgreementTemplate(data);
  };

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy],
  );

  const handleRowsPerPageChange = useCallback(
    (newLimit: number): void => {
      setRowsPerPage(newLimit);
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  useEffect(() => {
    if (isViewAgreementPermission) {
      setAddNewLink({ link: '', state: NewLinkState.active });
    } else {
      setAddNewLink({ link: '', state: NewLinkState.disabled });
    }
  }, [isViewAgreementPermission]);

  const onDeleteDocument = () => {
    const data = {
      documentId: documentObj.documentId,
      loggedInUser: user?.userId || 0,
    };
    deleteTemplate(data);
  };

  return (
    <>
      <CustomTable
        isDataLoading={isDocumentsLoading}
        setPagination={setPagination}
        goError={false}
        page={page}
        rowsPerPage={rowsPerPage}
        data={agreementRows}
        handlePageChange={handlePageChange}
        valueRef={valueRef}
        tableHeadingsArray={tableHeadingsArray}
        order={order}
        orderBy={orderBy}
        setPage={setPage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        advancedSearchVisibility={false}
        setAdvancedSearchVisibility={() => {}}
        onSubmitAdvancedSearch={() => {}}
        onResetAdvancedSearch={() => {}}
        searchValue={trimValue}
        handleSearchValue={handleSearchValue}
        selectArray={selectArrayAgreemntTemplate}
        tableTitle="Agreement Templates"
        placeHolder={t('action.search')}
        addNewLink={addNewLink}
        handleRequestSort={onHandleSetOrder}
        openAdvancedSearch={agreementRowsMapper}
        isDataPagination={pagination}
        isAdvanceSearch={false}
        total={totalCount}
        hideMainSearch={true}
        InputsComponent={() => <></>}
      />

      <AddTemplate
        open={showAddTemplateModal}
        onClose={() => {
          setShowAddTemplateModal(false);
        }}
        documentData={documentObj || {}}
        isLoading={isAddDocumentLoading}
        onHandleFormSubmit={onSubmitAddTemplate}
      />

      <EditTemplateModal
        open={showEditTemplateModal}
        onClose={() => {
          setShowEditTemplateModal(false);
        }}
        isLoading={false}
        documentData={documentObj || {}}
        onHandleFormSubmit={(templateFormData: DocumentItem) => {
          editDocumentOrgId({
            documentOrgId: templateFormData.documentOrgId,
            productId: templateFormData.productId,
            uploadDocs: {
              documentItems: [
                {
                  documentId: templateFormData.documentId,
                },
              ],
            },
          });
        }}
      />

      {isDeleteAgreementPermission && (
        <DeleteEntityModal
          open={agreementDeleteModal}
          setOpen={setAgreementDeleteModal}
          entityName={documentTitle}
          entityType={t('agreement.delete.modal.title')}
          onConfirm={onDeleteDocument}
        />
      )}
    </>
  );
}

export const ProtectedAgreementTemplateList = () => {
  const { t } = useTranslation();
  const isViewAgreementPermission = usePermissions(PermissionKeys.ViewAgreement);
  if (!isViewAgreementPermission) {
    return <NoPermissionMessage message={t('relationships.organisation.view.permission')} />;
  }
  return <AgreementTemplateList />;
};
