import { ConfigurationService } from '../../../services/configurationService';
import { Box } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid, getGridBooleanOperators, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { ActiveText } from '../../../components/shared/AppPageHeaderContentHost/styles';
import { FlexBox } from '../../../components/shared/FlexBox';

export default function LegalOrganizationPage() {
  const apiRef = useGridApiRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ReadConfiguration);
  const { data: legalOrganizations, isFetching } = ConfigurationService.useLegalEntityTypes({
    enabled: isEnabled,
  });

  const rows = useMemo(() => {
    return (
      legalOrganizations?.map((item) => ({
        ...item,
        id: item.legalEntityTypeId,
      })) || []
    );
  }, [legalOrganizations]);

  const columns: GridColDef[] = [
    { field: 'legalEntityTypeName', headerName: 'Legal Organization Name' },
    { field: 'legalEntityTypeDisplayName', headerName: 'Display Name' },
    {
      field: 'status',
      headerName: 'Status',
      renderCell({ row }) {
        return (
          <FlexBox alignItems={'center'} height={'100%'}>
            <ActiveText active={`${row.status}`}>
              {row.status ? t('status.active') : t('status.inactive')}
            </ActiveText>
          </FlexBox>
        );
      },
      filterOperators: getGridBooleanOperators().filter((item) => item.value === 'is'),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      apiRef.current?.autosizeColumns({
        includeHeaders: true,
        columns: ['legalEntityTypeName', 'legalEntityTypeDisplayName', 'status'],
        expand: true,
      });
    }, 500);
  }, [apiRef, rows, legalOrganizations]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.noPermissions') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Legal Organizations" />
      </Box>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        loading={isFetching}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
      />
    </>
  );
}
