export enum AgreementStatus {
  Draft = 'Draft',
  Sent = 'Sent',
  Delivered = 'Delivered',
  WaitingForOthers = 'Waiting for Others',
  NeedsToSign = 'Needs To Sign',
  NeedsToView = 'Needs to View',
  Correcting = 'Correcting',
  Voided = 'Voided',
  Declined = 'Declined',
  Completed = 'Completed',
  Expired = 'Expired',
  DeliveryFailure = 'Delivery Failure',
  AuthenticationFailed = 'Authentication Failed',
  PurgingSoon = 'Completed or Voided - Purging Soon',
  Purged = 'Completed or Voided - Purged',
}

export enum AgreementActions {
  GenerateAgreements = 'Generate Agreement',
  SendForSignatures = 'Send for Signatures',
  ResendForSignatures = 'Resend for Signatures',
  CancelAgreement = 'Cancel Agreement',
  Voided = 'Voided',
  DeliveryFailure = 'Delivery Failure',
  Void = 'Void',
}

export enum Gates {
  MAG = 'MultiAgent',
  DOG = 'DuplicateSeller',
}

export interface SellerAgreement {
  actionType: Array<AgreementActions>;
  agreementId: number | null;
  agreementStatusReferenceId: number | null;
  agreementTypeId: number | null;
  documentTypeDisplayName: string | null;
  agreementDeliveryFailure?: {
    createdOn: Date;
    emailAddress: string;
    message: string;
  };
  declinedReason: null | string;
  declinerEmail: null | string;
  declinedBy: null | string;
  documentId: number | null;
  envelopeId: string | null;
  generatedOn: Date | null;
  message: string | null;
  ofacCheckStatus: number | null;
  optionalComments: string | null;
  sentBy: string | null;
  sentDate: Date | null;
  signer: string | null;
  status: AgreementStatus;
}

export type ReviewPossiblePayload = {
  orgId: number;
  reviewType: Gates;
};

export interface AttachAgreementPayload {
  orgId: number;
  signerContactId: number;
  effectiveDate: string;
  agentContactId: number;
  providerSignerUserId: number;
  agentOrgId: number;
  documentTypeId: number;
  productIds: Array<number>;
  files: FormData;
}

export interface DownloadAttachmentPayload {
  agreementId: string;
  fileName: string;
}
