import { mixed, object, string } from 'yup';
import { getCommonErrorMessages, getCommonPattern } from '../utils';

const commonSelectSchema = object({
  label: string().required(getCommonErrorMessages('required')),
  value: mixed().nullable(),
})
  .nullable()
  .required(getCommonErrorMessages('required'));

const trimmedString = string().matches(/^(?!\s+$).*/, { message: 'Empty spaces are not allowed' });

const emailSchema = trimmedString
  .required(getCommonErrorMessages('required'))
  .matches(getCommonPattern('email'), {
    message: getCommonErrorMessages('email'),
  });

export { commonSelectSchema, trimmedString, emailSchema };
