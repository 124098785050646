import { AnySchema, array, boolean, date, mixed, number, object, ObjectSchema, string } from 'yup';
import { commonSelectSchema } from '.';
import { AddProductLineFormValues } from '../types/sellerGroup';
import { getCommonErrorMessages } from '../utils';

export const attachAgreementValidationSchema: ObjectSchema<
  Record<keyof AddProductLineFormValues, AnySchema>
> = object().shape(
  {
    signer: commonSelectSchema,
    tenantSigner: commonSelectSchema,
    productLines: array(number()).when('legacyProductLinesFlag', {
      is: false,
      then: array(number())
        .min(1, getCommonErrorMessages('productLine'))
        .required(getCommonErrorMessages('required')),
      otherwise: array(number()),
    }),
    legacyProductLines: array(number()),
    legacyProductLinesFlag: boolean(),
    message: string(),
    agent: commonSelectSchema,
    agentRelationshipOwner: commonSelectSchema,
    effectiveDate: date().required(getCommonErrorMessages('required')),
    attachedFiles: mixed()
      .required('At least one file is required')
      .test('min-files', 'You must upload at least one file', (value) => {
        return value && value.length > 0;
      }),
  },
  [],
);
