import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { InputAdornment, IconButton, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import KeyIcon from '../../components/assets/KeyIcon';
import EyeCloseIcon from '../../components/assets/EyeCloseIcon';
import EyeOpenIcon from '../../components/assets/EyeOpenIcon';
import MailIcon from '../../components/assets/MailIcon';
import {
  ButtonS,
  ErrorMessageText,
  SignUpText,
  StyledBox,
  StyledContainer,
  StyledRegisterButton,
  StyledText,
  TextFieldContainer,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../hooks/useToast';
import { AppTextField } from '../../components/shared/AppTextField';
import { AccountService } from '../../services/accountService';
import AuthService from '../../services/authService';
import encryptPassword from '../../utils/encryptPassword';
import { CustomContainer } from '../Login/styles';

export default function Registration() {
  const toast = useToast();
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const [ErrorMessage, setErrorMessage] = useState<string | null | undefined>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string | null | undefined>();
  const [confirmPassword, setConfirmPassword] = useState<string | null | undefined>();
  const [errorPassword, setErrorPassword] = useState<boolean>(true);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState<boolean>(true);
  const [emailValidate, setEmailValidate] = useState<boolean>(false);
  const [emailValidateError, setEmailValidateError] = useState<string | null | undefined>();
  const { t } = useTranslation();
  const helperText =
    'Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:';

  const mailId = searchParams.get('mailid') || 'asdasd@asd.com';

  AccountService.useValidateSignUpUser({
    email: mailId || '',
    options: {
      enabled: !!mailId,
      retry: false,
      onSuccess() {
        setEmailValidate(false);
      },
      onError(error) {
        toast.error(error.response.data.message);
        setEmailValidateError(error.response.data.message);
        setEmailValidate(true);
      },
    },
  });

  const { mutate: signUpUser } = AuthService.useSignupUser({
    onSuccess() {
      toast.success(t('registration.successfull.message'));
      loginWithRedirect();
      setErrorMessage('');
    },
    onError(error) {
      toast.error(error.response.data.message);
      setErrorMessage(error.response.data.message);
    },
  });

  useEffect(() => {
    if (mailId === null) {
      setEmailValidateError(t('registration.blank.email.error'));
      setEmailValidate(true);
    }
  }, [mailId, t]);

  const validatePassword = (passwordData: string) => {
    const regrs = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    if (!regrs.test(passwordData)) {
      return false;
    }
    return true;
  };

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const showConfirmPasswordHandler = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const passwordHandler = (pass: string) => {
    setErrorPassword(validatePassword(pass));
    setPassword(pass);
  };

  const confirmPasswordHandler = (confirmpassword: string) => {
    setErrorConfirmPassword(validatePassword(confirmpassword));
    setConfirmPassword(confirmpassword);
  };
  const submitHandler = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Password and confirm password should be same!');
      return;
    }

    let encryptedPassword = '';

    try {
      encryptedPassword = encryptPassword(password || '');
    } catch (error: any) {
      toast.error(error?.message);
      return;
    }

    signUpUser({
      email: mailId || '',
      password: encryptedPassword,
      confirmPassword: encryptedPassword,
    });
  };

  return (
    <CustomContainer>
      <StyledBox>
        <form onSubmit={submitHandler}>
          <StyledContainer>
            <SignUpText>{t('registration.user.signup.heading')}</SignUpText>
            <TextFieldContainer>
              <Box my={1}>
                <AppTextField
                  placeholder="Email id"
                  helperText={emailValidateError}
                  error={emailValidate}
                  value={mailId}
                  InputLabelProps={{ shrink: 'userName' && true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="search icon" edge="start">
                          <MailIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  disabled
                />
              </Box>
              <Box my={1}>
                <AppTextField
                  placeholder="Enter Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => passwordHandler(e.target.value)}
                  error={!errorPassword}
                  helperText={!errorPassword ? helperText : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="search icon" edge="start">
                          <KeyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={showPasswordHandler}
                        >
                          {showPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  disabled={emailValidate}
                />
              </Box>
              <Box my={1}>
                <AppTextField
                  autoComplete="current-password"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    confirmPasswordHandler(e.target.value)
                  }
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  error={!errorConfirmPassword}
                  helperText={!errorConfirmPassword ? helperText : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="search icon" edge="start">
                          <KeyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={showConfirmPasswordHandler}
                        >
                          {showConfirmPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  disabled={emailValidate}
                />
                {ErrorMessage && <ErrorMessageText>{ErrorMessage}</ErrorMessageText>}
              </Box>
            </TextFieldContainer>
          </StyledContainer>

          <StyledRegisterButton
            variant="contained"
            disabled={emailValidate || !errorConfirmPassword || !errorPassword}
            type="submit"
          >
            {t('action.register')}
          </StyledRegisterButton>
          <StyledText variant="body2" sx={{ fontSize: '18px' }} mt={3}>
            {t('registration.user.already.registered.heading')}
            <ButtonS
              onClick={() => {
                loginWithRedirect();
              }}
            >
              {t('action.login')}
            </ButtonS>
          </StyledText>
        </form>
      </StyledBox>
    </CustomContainer>
  );
}
