import HomeIcon from '../../components/assets/Home';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoIcon from '../../components/assets/LogoIcon';
import { AppActionButton } from '../../components/shared/AppActionButton';
import { AlignCenterBox, CenterCenterBox } from '../../components/shared/FlexBox';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';
import { StyledToolBar } from '../../layout/Topbar/styles';
import { Box } from '@mui/material';

export const NotFoundPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const error = location.state?.error?.replace('Product', 'Product Line') as string;

  return (
    <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh' }}>
      <StyledToolBar>
        <Link style={{ textDecoration: 'none' }} to="/dashboard">
          <AlignCenterBox>
            <AlignCenterBox style={{ marginRight: '10px' }}>
              <HomeIcon />
            </AlignCenterBox>
            <AlignCenterBox>
              <LogoIcon />
            </AlignCenterBox>
          </AlignCenterBox>
        </Link>
      </StyledToolBar>
      <CenterCenterBox flexDirection={'column'} sx={{ height: '100%' }}>
        <NoPermissionMessage message={error ? error : 'Page not found.'} />
        <Box mt={1}>
          <AppActionButton
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Home
          </AppActionButton>
        </Box>
      </CenterCenterBox>
    </Box>
  );
};
