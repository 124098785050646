import { Box, Checkbox, InputLabel, Select } from '@mui/material';
import styled from 'styled-components';
import {
  DarkGray,
  FlashWhite,
  Gray,
  LightSlateGray,
  MainGreen,
  MainTextColor,
  MediumGray,
  MurkGray,
  White,
} from '../../../Theme/colorsVariables';
import { breakPointBig, breakPointMedium } from '../../../utils/MediaBreakPoint';
import { AlignCenterBox, FlexBox } from '../FlexBox';

export const ParentCustomSearchBoxMedia = `
  display: block !important;
`;

const SelectFilterMedia = `
  height: 42px;
`;

export const ParentBox = styled(FlexBox)`
  font-size: 16px;
  align-items: center;
  font-family: Gotham-Bold;
`;

export const FilterLabel = styled(Box)`
  font-size: 16px;
  color: ${LightSlateGray};
  font-family: Gotham-Medium;
  display: inline-block;
  margin-left: 30px;
`;

export const SelectBox = styled(FlexBox)`
  margin: 0;
`;

export const CheckBoxStyle = styled(Checkbox)`
  &.Mui-checked {
    color: ${MainGreen} !important;
  }
  color: ${MediumGray};
  padding: 8px;
  margin-right: 10px;
  & .MuiSvgIcon-root {
    font-size: 25px;
  }
`;

export const InputLabelS = styled(InputLabel)`
  color: ${LightSlateGray};
  font-family: Gotham-Book;
  font-size: 16px;
  font-weight: 500;
  &.Mui-focused {
    color: ${MainTextColor};
  }
`;

export const SelectS = styled(Select)`
  background: ${White};
  border-radius: 14px;
  font-size: 16px;
  font-family: Gotham-Medium;
  width: 400px;
  fieldset {
    border: 1px solid ${Gray};
    border-color: ${Gray} !important;
  }
`;

export const BorderDiv = styled.div`
  border-top: 3px solid ${Gray};
  margin: 1px 8px 0px 8px;
  height: 0;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: '0px 0px 14px 14px',
      boxShadow: 'none',
      border: `1px solid ${Gray}`,
      borderTop: 0,
      marginTop: '-9px',
    },
    sx: {
      '.MuiMenu-list': { paddingTop: '0px' },
      '&::-webkit-scrollbar': {
        width: '0.3em',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: `${FlashWhite}`,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: `${DarkGray}`,
        outline: `1px solid ${DarkGray}`,
        borderRadius: '30px',
        minHeight: '80px',
      },
    },
  },
};

export const CustomSelect = styled(AlignCenterBox)`
  color: ${LightSlateGray};
  margin-left: 30px;
  border: none;
`;

export const SelectFilter = styled(Select)`
  width: 135px;
  height: 40px;
  font-size: 16px;
  border-radius: 0;
  background: ${White};
  border: none;
  & fieldset {
    border: none;
  }
  ${breakPointBig(SelectFilterMedia)}
`;

export const StateMultiselectBox = styled(FlexBox)`
  flex-direction: row;
  background: ${White};
  width: 570px;
  height: 100%;
  padding: 8px 15px;
  ${breakPointMedium('width: 400px; padding: 8px 5px;')}
  hr {
    width: 1px;
    height: 100%;
    background-color: ${MurkGray};
    border: none;
    margin-top: 0;
  }
  p {
    font-family: Gotham-Medium;
    font-size: 15px;
    color: ${MainTextColor};
    margin: 0;
    ${breakPointMedium('font-size: 12px;')}
  }
  label {
    left: -15px;
    top: -15px;
  }
  div {
    background: transparent !important;
    height: 100%;
    padding: 0 !important;
    input {
      padding: 0 !important;
      text-indent: 10px;
    }
    fieldset {
      border: none !important;
      &:focus {
        border: none !important;
      }
      &:hover {
        border: none !important;
      }
      span {
        padding: 0;
        width: 0;
        height: 0;
      }
    }
  }
  & .MuiFormControl-root {
    max-width: 320px;
    width: 100%;
    ${breakPointMedium('max-width: 230px;')}
  }
`;

export const StatesMainBox = styled(FlexBox)`
  flex-direction: column;
  ${breakPointMedium('height: 35px;')}
`;

export const InfoLabelText = styled(Box)`
  font-size: 12px;
  ${breakPointMedium('font-size: 10px;')}
`;
