import * as React from 'react';
import { styled } from '@mui/material/styles';

import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box, Typography } from '@mui/material';
import AppTooltip from '../../shared/AppTooltip';
import { breakPointMedium } from '../../../utils';

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <AppTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
});

export const CenterDiv = styled(Box)`
  margin-top: 20px;
`;

export const SectionHeadings = styled(Typography)`
  font-family: Gotham-Bold;
  font-size: 16px;
  display: inline-block;
  margin-right: 10px;
  ${breakPointMedium('font-size: 14px;')}
`;
