import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CardStyle } from './styles';
import { useTranslation } from 'react-i18next';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { AppEditButton } from '../../components/shared/AppEditButton';
import { Checkbox, Grid2, useMediaQuery, useTheme } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { SelectItem, UserFormType, UserInfo } from '../../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { userProfileValidationSchema } from '../../Resolvers/userValidationSchema';
import { AppTextField } from '../../components/shared/AppTextField';
import { AppPhoneField, getPhonePayload } from '../../components/shared/AppPhoneField';
import { FlexBox } from '../../components/shared/FlexBox';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { useAppRolesByType } from '../../services/sellerServices';
import RoleType from '../../types/businessRoles';
import { TypographySpan } from '../../components/shared/StateMultiSelect/styles';
import { CancelModal } from '../../components/modal/CancelModal';
import { useDataloss } from '../../hooks/useDataloss';
import { isEqual } from 'lodash';
import { useMyProfileUpdate } from '../../services/userServices';
import { useToast } from '../../hooks/useToast';
import { UserContext } from '../../context/User';
import { AppAutocomplete } from '../../components/shared/AppAutocomplete';

export type MyProfile = Pick<
  UserFormType,
  'firstName' | 'lastName' | 'email' | 'title' | 'contactClassification' | 'phone'
>;

export default function ViewUserProfile() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const { t } = useTranslation();
  const userData = useContext(UserContext);
  const toast = useToast();

  const formRef = useRef<HTMLFormElement>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [cancelPopUp, setCancelPopUp] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const { data: agencyContactClassifications } = useAppRolesByType(RoleType.agencyUser);

  const { data: providerContactClassifications } = useAppRolesByType(RoleType.businessWithOther);

  const formMethods = useForm<MyProfile>({
    mode: 'onChange',
    defaultValues: {
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      email: userData?.email || '',
      title: userData?.title || '',
      contactClassification:
        userData && userData.contactClassification
          ? [...(providerContactClassifications || []), ...(agencyContactClassifications || [])]
              .filter((item) => userData.contactClassification.includes(item.roleId))
              .map((item) => ({ label: item.roleName, value: item.roleId }))
          : [],
      phone: {
        number:
          userData?.phoneNumber && userData?.phoneNumberCountryCode
            ? userData?.phoneNumberCountryCode + userData?.phoneNumber
            : '',
        countryCode: userData?.phoneNumberCountryCode || '',
        ext: userData?.phoneNumberExt || '',
        isValid: true,
      },
    },
    resolver: yupResolver(userProfileValidationSchema),
  });

  const { control, handleSubmit, watch, reset, formState } = useMemo(
    () => formMethods,
    [formMethods],
  );

  const { errors, defaultValues, isValid } = formState;

  const formValues = watch();

  const { mutate: updateMyProfile } = useMyProfileUpdate({
    onSuccess: (res) => {
      setIsEdit(false);
      userData?.refetchUserData();
      reset({
        firstName: res?.firstName || '',
        lastName: res?.lastName || '',
        email: res?.email || '',
        title: res?.title || '',
        contactClassification: res
          ? [...(providerContactClassifications || []), ...(agencyContactClassifications || [])]
              .filter((item) => res.contactClassification.includes(item.roleId))
              .map((item) => ({ label: item.roleName, value: item.roleId }))
          : [],
      });
      toast.success(t('toast.update-user.success'));
    },
  });

  const contactClassificationOptions = useMemo(() => {
    if (userData?.userType === 'Agency') {
      return agencyContactClassifications?.map((item) => ({
        label: item.roleName,
        value: item.roleId,
      }));
    }
    return providerContactClassifications?.map((item) => ({
      label: item.roleName,
      value: item.roleId,
    }));
  }, [agencyContactClassifications, providerContactClassifications, userData?.userType]);

  // INFO: checking for unsaved changes to disable action buttons
  useEffect(() => {
    if (defaultValues && isEdit) {
      const initialValues: MyProfile = {
        firstName: defaultValues.firstName || '',
        lastName: defaultValues.lastName || '',
        email: defaultValues.email || '',
        phone: {
          number: defaultValues.phone?.number || '',
          countryCode: defaultValues.phone?.countryCode || '',
          ext: defaultValues.phone?.ext || '',
          isValid: true,
        },
        title: defaultValues.title || '',
        contactClassification: (defaultValues.contactClassification as SelectItem<number>[])?.sort(
          (a, b) => a?.value - b?.value,
        ),
      };
      const currentValues: MyProfile = {
        firstName: formValues.firstName || '',
        lastName: formValues.lastName || '',
        email: formValues.email || '',
        phone: formValues.phone,
        title: formValues.title || '',
        contactClassification: (formValues.contactClassification || [])?.sort(
          (a, b) => a.value - b.value,
        ),
      };

      const isValuesEqual = isEqual(initialValues, currentValues);

      setIsUnsavedData(!isValuesEqual);
      setIsSaveDisabled(isValid ? isValuesEqual === isValid : true);
    }
  }, [
    defaultValues,
    formValues.contactClassification,
    formValues.email,
    formValues.firstName,
    formValues.lastName,
    formValues.phone,
    formValues.title,
    isEdit,
    isValid,
    userData?.phoneNumber,
    userData?.phoneNumberCountryCode,
    userData?.phoneNumberExt,
  ]);

  const onSubmit = (data: MyProfile) => {
    const payload: Pick<
      UserInfo,
      | 'firstName'
      | 'lastName'
      | 'contactClassification'
      | 'email'
      | 'title'
      | 'phoneNumber'
      | 'phoneNumberCountryCode'
      | 'phoneNumberExt'
    > = {
      contactClassification: data.contactClassification.map((item) => item.value),
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      title: data.title || '',
      ...getPhonePayload(data.phone),
    };

    updateMyProfile(payload);
  };

  const onReset = useCallback(() => {
    reset();
    window.scrollTo(0, 0);
  }, [reset]);

  const onDiscardChanges = useCallback(() => {
    setIsEdit(false);
    setCancelPopUp(false);
    formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
  }, [formRef, setIsEdit]);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, [formRef]);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, []);

  useDataloss(isUnsavedData && isEdit, onDiscardChanges, onSaveChanges, onExit);

  return (
    <>
      <PageHeaderContainer>
        <PageHeaderContentHost name="My Profile" />
        {!isEdit && (
          <AppEditButton
            onClick={() => {
              setIsEdit(true);
            }}
          />
        )}
        {isEdit && (
          <FlexBox>
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setCancelPopUp(true);
                } else {
                  setIsEdit(false);
                }
              }}
            />
            <AppResetButton
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('reset', { cancelable: true, bubbles: true }),
                );
              }}
            />
            <AppSaveButton
              disabled={isSaveDisabled}
              onClick={() => {
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }}
            />
          </FlexBox>
        )}
      </PageHeaderContainer>
      <Grid2 container spacing={isSmall ? 1 : 2}>
        <Grid2 size={{ xs: 1.5 }}>
          <CardStyle>
            {userData?.firstName} {userData?.lastName}
          </CardStyle>
        </Grid2>
        <Grid2 size={{ xs: 10.5 }}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} onReset={onReset} ref={formRef}>
              <Grid2 container spacing={isSmall ? 1 : 2}>
                <Grid2 size={{ xs: 4 }}>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <AppTextField
                        {...field}
                        label={t('formField.firstName')}
                        disabled={!isEdit}
                        required={isEdit}
                        error={!!errors.firstName?.message}
                        errorMessage={errors.firstName?.message}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                  <Controller
                    control={control}
                    name="lastName"
                    render={({ field }) => (
                      <AppTextField
                        {...field}
                        label={t('formField.lastName')}
                        disabled={!isEdit}
                        required={isEdit}
                        error={!!errors.lastName?.message}
                        errorMessage={errors.lastName?.message}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <AppTextField
                        {...field}
                        label={t('formField.email')}
                        disabled
                        error={!!errors.email?.message}
                        errorMessage={errors.email?.message}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                  <AppPhoneField required={false} disabled={!isEdit} />
                </Grid2>
                <Grid2 size={{ xs: 8 }}></Grid2>
                <Grid2 size={{ xs: 4 }}>
                  <Controller
                    control={control}
                    name="title"
                    render={({ field }) => (
                      <AppTextField
                        {...field}
                        label={'Title'}
                        disabled={!isEdit}
                        error={!!errors.title?.message}
                        errorMessage={errors.title?.message}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                  <Controller
                    control={control}
                    name={'contactClassification'}
                    render={({ field }) => (
                      <AppAutocomplete
                        {...field}
                        disablePortal
                        options={contactClassificationOptions || []}
                        disabled={!isEdit}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderInput={(params) => (
                          <AppTextField {...params} label={t('formField.contactClassification')} />
                        )}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        multiple
                        disableCloseOnSelect
                        renderOption={(optionProps, option, { selected }) => {
                          return (
                            <li {...optionProps} key={option.value}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                color="success"
                                checked={selected}
                              />
                              <span
                                dangerouslySetInnerHTML={{ __html: option?.roleName || '-' }}
                              ></span>
                            </li>
                          );
                        }}
                        renderTags={(data) => (
                          <TypographySpan>
                            {t('multiple.select.label', {
                              selectedCount: data.length,
                              allCount: contactClassificationOptions?.length || 0,
                            })}
                          </TypographySpan>
                        )}
                      />
                    )}
                  />
                </Grid2>
              </Grid2>
            </form>
          </FormProvider>
        </Grid2>
      </Grid2>

      <CancelModal
        isOpened={cancelPopUp}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardChanges}
        onSaveChanges={onSaveChanges}
        onExit={onExit}
      />
    </>
  );
}
