import { Box } from '@mui/material';
import styled from 'styled-components';
import { CenterCenterBox } from '../../../components/shared/FlexBox';

export const HoverContainerTableCell = styled(Box)`
  visibility: hidden;
  position: absolute;
  right: 0;
`;

export const ActionIconWrapper = styled(CenterCenterBox)`
  width: 30px;
  height: 30px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: silver;
    border-radius: 4px;
  }
`;
